
import absagetermin from '~/mixins/kalender/absagetermin';
export default {
  mixins: [absagetermin],
  props: ['mitarbeiter_id'],
  data() {
    return {
      freizeitausgleich: null,
      bisEdit: false,
      freizeitausgleichstage: 0,
    }
  },
  computed: {
    zeitraumValid() {
      let valid = true;
      if (!this.$moment(this.freizeitausgleich.von).isSameOrBefore(this.freizeitausgleich.bis)) valid = false;
      if (!this.$can('Herr der Zeit') && !this.$moment(this.freizeitausgleich.von).isSameOrAfter(this.$moment().subtract(1, 'days'))) valid = false;
      return valid;
    },
    empty() {
      return {
        mitarbeiter_id: this.mitarbeiter_id,
        von: this.$moment().format('YYYY-MM-DD'),
        bis: this.$moment().format('YYYY-MM-DD'),
        kommentar: null,
      };
    },
  },
  methods: {
    speichern() {
      if (!this.zeitraumValid) return null;
      this.$$store('freizeitausgleiche', this.freizeitausgleich, "Erfolgreich gespeichert").then((response) => {
        this.$bvModal.hide('freizeitausgleich-formular')
        this.$nuxt.$emit('store-freizeitausgleich');
      });
    },
    updateBis() {
      if (this.bisEdit) return;
      this.$nextTick(() => {
        this.freizeitausgleich.bis = this.freizeitausgleich.von;
      })
    },
    show() {
      this.freizeitausgleich = this.$copy(this.empty);
      this.neuAbrufen();
    },
    neuAbrufen() {
      setTimeout(() => {
        if (this.$moment(this.freizeitausgleich.von) > this.$moment().add(14, 'days')) {
          this.absagestatus = false;
          return;
        }
        else this.absagestatus = true;

        // Nicht beliebig zurück freizeitausgleich checken, das erlaubt das Feiertagescript nicht
        if (!this.freizeitausgleich.von || !this.freizeitausgleich.bis || this.freizeitausgleich.von < '2000-01-01' || this.freizeitausgleich.bis < '2000-01-01') return;
        this.$$abrufen('freizeitausgleichPruefen', null, '/api/freizeitausgleiche/pruefen?von='+this.freizeitausgleich.von+'&bis='+this.freizeitausgleich.bis+'&mitarbeiter_id='+this.mitarbeiter_id).then(data => {
          this.freizeitausgleichstage = data.freizeitausgleichstage;
          this.termine = data.termine;
        });
      }, 500)
    }
  }
}
