import { render, staticRenderFns } from "./DokuFormular.vue?vue&type=template&id=1820ef4b"
import script from "./DokuFormular.vue?vue&type=script&lang=js"
export * from "./DokuFormular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsDokuPatient: require('/builds/logo-yes/portal2022/frontend/components/modals/details/DokuPatient.vue').default,ModalsDetailsDokuPatientHinzufuegen: require('/builds/logo-yes/portal2022/frontend/components/modals/details/DokuPatientHinzufuegen.vue').default,GlobalFormulareAnfahrt: require('/builds/logo-yes/portal2022/frontend/components/global/formulare/Anfahrt.vue').default})
