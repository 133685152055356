
export default {
  props: ['spalte'],
  data() {
    return {
      elementid: 'kalender-spaltentitel-'+this._uid,
    }
  },
  computed: {},
  methods: {

  },
  mounted() {}
}
