export default {
  data() {
    return {
      channelname: 'online.',
    }
  },
  methods: {
    joinOnlineChannel() {
      this.$echo.join(this.channelname)
      .here(users => this.$store.commit('online/here', users))
      .joining(users => this.$store.commit('online/joining', users))
      .leaving(users => this.$store.commit('online/leaving', users))
      .error(error => this.$error('Socketverbindung nicht möglich', '', error));
    },
    startOnlineSocket() {
      if (this.$auth.user) {
        this.channelname+= this.$auth.user.praxis_id;
        this.joinOnlineChannel();
        this.$nuxt.$off('login', this.startOnlineSocket);
      }
      else {
        this.$nuxt.$on('login', this.startOnlineSocket);

      }
    }
  },
  mounted() {
    this.startOnlineSocket();
  }
}
