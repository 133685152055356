export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    ansehen(datei) {
      let url = '/api/dateiversionen/'+datei.aktuelle_version.id+'/download';
      this.$nuxt.$emit('view-pdf', {titel: datei.aktuelle_version.dateiname, url: url});
    },
  },
  mounted() {},

}
