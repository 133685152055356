
export default {
  mixins: [],
  props: {
    patient_id: {},
    label: {default: true},
    edit: {default: true},
    value: null,
    therapiestunde: {},
    fachbereich: {},
  },
  data() {
    return {
      ziele: this.value,
      therapieziele: null,
      modalid: 'modal-'+this._uid,
    }
  },
  computed: {},
  watch: {},
  methods: {
    emit() {
      this.$emit('input', this.ziele);
    },
    abrufen() {
      this.$$abrufen('therapieziele').then(therapieziele => {
        this.therapieziele = therapieziele;
        // Nur bei neuen Stunden das Ziel setzen
        if (!this.therapiestunde.created_at && therapieziele && therapieziele[0]) this.ziele = therapieziele[0].therapieziel;
      })
    }
  },
  mounted() {
    this.$$fill('therapieziele', {filter: [{where: ['patient_id', this.patient_id]}, {whereFachbereich: [this.fachbereich]}]});
    this.abrufen();
  },

}
