
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'verfuegbarkeitKopieren',
      modaltitel: 'Verfügbarkeit Kopieren',
      wochentag: null,
      vorlage_wochentag: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(vorlage_wochentag) {
      this.wochentag = null;
      this.vorlage_wochentag = vorlage_wochentag;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      this.$emit('copy', {von: this.vorlage_wochentag, an: this.wochentag});
      this.$bvModal.hide(this.modalid);
    },
  },
  mounted() {
    this.$nuxt.$on('verfuegbarkeit-kopieren', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('verfuegbarkeit-kopieren', this.start);
  },

}
