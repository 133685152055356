
import Formularfeld from 'p1ng-quickstart/src/components/formulare/Formularfeld';
let props = Formularfeld.props;
props['feldname'] = {};
props['fehlend'] = {};
props['form'] = {};
export default {
  mixins: [],
  props: props,
  data() {
    return {
      fehlendVal: this.fehlend,
      touched: false,
    }
  },
  computed: {
    spaltengroesse() {
      if (this.col) return this.col;
      return 'col-md-4';
    },
    valueMissing() {
      if (this.type == 'radio') return this.value === null;
      return !this.value;
    }
  },
  watch: {
    fehlend(neu) {
      this.fehlendVal = neu;
    }
  },
  methods: {
    fehlt() {
      this.touched = true;
      this.$emit('update:fehlend', this.fehlendVal);
      this.$emit('input', null);
    },
    touch() {
      this.touched = true;
    }
  },
  mounted() {
    if (this.form) {
      this.$nuxt.$on('formsubmit-'+this.form, this.touch);
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('formsubmit-'+this.form, this.touch);
  }

}
