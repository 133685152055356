
export default {
  props: ['patientprop'],
  data() {
    return {
      modalid: 'patientverfuegbarkeit',
      patient: null,
      emptyVerfuegbarkeit: {wochentag: '', von: '', bis: '', standorte: []},
      verfuegbarkeiten: [],
    }
  },
  computed: {},
  watched:{},
  methods: {
    start() {
      this.patient = this.patientprop;
      if (this.patient.verfuegbarkeiten) {
        this.verfuegbarkeiten = this.patient.verfuegbarkeiten;
      } else this.neueVerfuegbarkeit();
    },
    neueVerfuegbarkeit() {
      let neu = this.$copy(this.emptyVerfuegbarkeit);
      this.verfuegbarkeiten.push(neu);
    },
    speichern() {
      for (let v of this.verfuegbarkeiten) {
        if (v.von === '') v.von = "00:00";
        if (v.bis === '') v.bis = "23:59";
      }
      let data = {
        verfuegbarkeiten: this.verfuegbarkeiten,
      }
      this.$axios.put('/api/patienten/'+this.patient.id+'/verfuegbarkeiten', data).then(response => {
        this.$bvModal.hide(this.modalid);
        this.$success('Erfolgreich aktualisiert.');
      });
    },
    loeschen(index) {
      this.verfuegbarkeiten.splice(index, 1);
    }
  },
  mounted() {
    this.start();
  }
}
