
export default {
  props: ['mitarbeiter_id', 'verwaltung'],
  data() {
    return {
      formulartitel: 'Urlaub beantragen',
      jahr: Number(this.$moment().format('Y')),
    }
  },
  methods: {
    neuerUrlaub() {
      this.formulartitel = 'Urlaub beantragen';
      this.$bvModal.show('urlaub-formular');
    },
    abrufen() {
      this.$$fill('urlaube', {filter: [{where: ['mitarbeiter_id', this.mitarbeiter_id]}, {whereYear: ['von', '=', this.jahr]}]});
      this.$$abrufen('urlaube');
      this.$$abrufen('urlaubsanspruch', null, '/api/mitarbeiter/'+this.mitarbeiter_id+'/urlaubsanspruch');
    },
    updateJahr(summand) {
      this.jahr += summand;
      this.abrufen();
    },
    loeschen(urlaub) {
      this.$confirm('Möchtest du den Urlaub wirklich zurückziehen?').then((response) => {
        if (response) {
          this.$$delete('urlaube', urlaub.id).then(response => {
            this.abrufen();
            this.$$success('Urlaub erfolgreich zurückgezogen');
          })
        }
      });
    }
  },
  mounted() {
    this.abrufen();
    this.$nuxt.$on('store-urlaub', this.abrufen);
  },
  beforeDestory() {
    this.$nuxt.$off('store-urlaub', this.abrufen);
  }
}
