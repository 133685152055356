
import {VueSignaturePad} from 'vue-signature-pad';
export default {
  components: {VueSignaturePad},
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    reset() {
      this.$refs.signaturePad.clearSignature();
    },
    speichern() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) this.$error('Unterschrift ist leer');
      this.$emit('done', data);
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.signaturePad.resizeCanvas();
      }, 500)
    })
  },

}
