
export default {
  mixins: [],
  props: [],
  data() {
    return {
      termin: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(termin) {
      this.termin = termin;
      this.abrufen();
      this.$bvModal.show('termindetails-modal');
    },
    reload() {
      if (!this.termin) return;
      return this.start(this.termin);
    },
    abrufen() {
    if (!this.termin || !this.termin.id) return;
      this.$$find('termine', this.termin.id).then(element => {
        this.termin = element;
      });
    },
    bearbeiten() {
      this.$nuxt.$emit('edit-kalender-termin', this.termin);
      this.showDetails = false;
    },
    absagen() {
      this.$nuxt.$emit('kalender-termin-absage', this.termin);
      this.showDetails = false;
    },
    absageZurueckziehen() {
      let termin = this.$copy(this.termin);
      this.$$delete('termine', termin.id+'/absage').then(el => {
        this.$nuxt.$emit('store-termin', this.termin)
      })
      this.showDetails = false;
    },
  },
  mounted() {
    this.$nuxt.$on('display-termindetails', this.start);
    this.$nuxt.$on('store-termin', this.reload);
    this.$nuxt.$on('store-ausfallstunde', this.reload);
  },
  beforeDestroy() {
    this.$nuxt.$off('display-termindetails', this.start);
    this.$nuxt.$off('store-termin', this.reload);
    this.$nuxt.$off('store-ausfallstunde', this.reload);
  }

}
