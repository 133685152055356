
export default {
  mixins: [],
  props: ['patientenkontakt', 'kontaktnummer', 'index'],
  data() {
    return {
      kontaktpersonAuswahl: 'alt',
      auswahlAnzeigen: true,
    }
  },
  computed: {},
  watch: {},
  methods: {
    selectPatientenkontakt() {
      this.kontaktpersonAuswahl = 'neu';
      this.auswahlAnzeigen = false;
    },
  },
  mounted() {
    if (this.patientenkontakt.id) {
      this.kontaktpersonAuswahl = 'neu';
      this.auswahlAnzeigen = false;
    }
  },

}
