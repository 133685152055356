
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'krankenkassenart-verguetung-formular',
      modaltitel: 'Krankenkassenart',
      event: 'krankenkassenart-verguetung',
      krankenkassenart: null,
      kostenpunkte: null,
      neuesDatum: null,
    }
  },
  computed: {
    kostenpunkteGrupiert() {
      let kostenpunkte = this.krankenkassenart.kostenpunkte;
      let send = {};
      kostenpunkte.map(el => {
        if (!send[el.pivot.gueltig_ab]) send[el.pivot.gueltig_ab] = []
        send[el.pivot.gueltig_ab].push(el);
      })
      return send;
    }
  },
  watch: {},
  methods: {
    async start(krankenkassenart = null) {
      krankenkassenart = this.$copy(krankenkassenart);
      this.$$fill('kostenpunkte', {filter: [{where: ['fachbereich', krankenkassenart.fachbereich]}]});
      this.kostenpunkte = await this.$$abrufen('kostenpunkte');
      this.krankenkassenart = krankenkassenart;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      let send = {id: this.krankenkassenart.id, kostenpunkte: this.krankenkassenart.kostenpunkte};
      this.$$update('krankenkassenarten', send, 'Krankenkassenart gespeichert').then(() => this.done());
    },
    done() {
      this.$bvModal.hide(this.modalid)
      this.$nuxt.$emit('update-krankenkassenart');
    },
    updateGueltigAb(neu, alt, counter) {
      if (counter != 0) return this.krankenkassenart.kostenpunkte[counter].pivot.gueltig_ab = neu;
      this.krankenkassenart.kostenpunkte = this.krankenkassenart.kostenpunkte.map(el => {
        if (el.pivot.gueltig_ab == null || el.pivot.gueltig_ab == alt) el.pivot.gueltig_ab = neu;
        return el;
      });
    },
    neuesDatumHinzufuegen() {
      let neueKostenpunkte = this.$copy(this.kostenpunkte);
      neueKostenpunkte = neueKostenpunkte.map(el => {
        el.pivot = {gueltig_ab: this.neuesDatum, gueltig_bis: null, wert: null}
        return el;
      });
      this.krankenkassenart.kostenpunkte = this.krankenkassenart.kostenpunkte.concat(neueKostenpunkte);
      this.neuesDatum = null;
    },
    kostenpunkteHinzufuegenOptions(gruppe) {
      if (!this.kostenpunkte) return null;
      let kostenpunkteIds = this.$copy(gruppe).map(el => {
        return el.id;
      })
      return this.kostenpunkte.filter(el => {
        return !kostenpunkteIds.includes(el.id);
      }).map(el => {
        el.labelOption = el.bezeichnung + ((el.dauer) ? (' - '+el.dauer+'Min') : '') + (el.teilnehmerzahl ? (' - ' + el.teilnehmerzahl+' TN') : '');
        return el;
      })
    },
    kostenpunktHinzufuegen(neu, gruppe) {
      neu.pivot = {gueltig_ab: gruppe[0].pivot.gueltig_ab, wert: ''};
      this.krankenkassenart.kostenpunkte.push(neu)
    }
  },
  mounted() {
    this.$nuxt.$on('krankenkassenart-verguetung', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('krankenkassenart-verguetung', this.start);
  },

}
