
import termine from '~/mixins/kalender/termine';
export default {
  props: ['kalender', 'spalte', 'spaltenData'],
  mixins: [termine],
  data() {
    return {
      abgeschlossen: false,
    }
  }
}
