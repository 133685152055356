
export default {
  mixins: [],
  props: [],
  data() {
    return {
      patient_id: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(patient_id) {
      this.patient_id = patient_id;
      this.abrufen();
      this.$bvModal.show('anrufe-modal');
    },
    abrufen() {
      this.api['anrufe'].params.filter = [{whereHasId: ['patienten', this.patient_id]}];
      this.$$abrufen('anrufe');
    }
  },
  mounted() {
    this.$$fill('anrufe', {with: ['faelle', 'sipAccount']});
    this.$nuxt.$on('display-anrufe', this.start);
  },
  beforeDestroy() {
    this.$nuxt.$off('display-anrufe', this.start);
  }

}
