
export default {
  mixins: [],
  props: ['mitarbeiter_id'],
  data() {
    return {
      modalid: 'arbeitszeitkonto-korrekturbuchung',
      modaltitel: 'Korrekturbuchung',
      korrekturbuchung: {
        veraenderungStunden: null,
        veraenderung: null,
        kommentar: null,
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      this.$$store('arbeitszeitkonto', this.korrekturbuchung, null, '/api/mitarbeiter/'+this.mitarbeiter_id+'/arbeitszeitkonto').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$emit('refresh');
      })
    },
    updateVeraenderung() {
      this.korrekturbuchung.veraenderung = this.$round(this.korrekturbuchung.veraenderungStunden * 60, 0);
    }
  },
  mounted() {},
  beforeDestory() {},

}
