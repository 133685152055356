
export default {
  mixins: [],
  props: ['kontakt', 'anzahlElemente'],
  data() {
    return {
      displayDetails: false
    }
  },
  computed: {},
  watch: {
    anzahlElemente(neu) {
      if (neu == 1) this.displayDetails = true;
      else this.displayDetails = false;
    }
  },
  methods: {
    impersonate(mitarbeiter_id) {
      this.$nuxt.$loading.start('Mitarbeiter impersonate');
      this.$axios.put('/api/mitarbeiter/'+mitarbeiter_id+'/impersonate').then(response => {
        this.$auth.fetchUser().then(() => {
          this.$router.push('/');
          this.$nuxt.$loading.finish();
        });
      })
    },
    archivieren(mitarbeiter_id) {
      this.$confirm('Soll der Mitarbeiter wirklich archiviert werden?').then(response => {
        if (response) {
          this.$$delete('mitarbeiter', mitarbeiter_id).then(response => {
            this.$success('Mitarbeiter erfolgreich archiviert');
            this.$emit('refresh');
          })
        }
      })
    },
    tagesabschluss(mitarbeiter_id) {
      this.$nuxt.$emit('display-mitarbeiter-tagesabschluss', {mitarbeiter_id: mitarbeiter_id});
    },
    sendLoginLink(kontakt) {
      this.$$store('sendLoginMail', {email: kontakt.email}, 'Login E-Mail versendet', '/api/mitarbeiter/'+kontakt.model_id+'/sendLoginMail');
    },
    sendVerificationMail(kontakt) {
      this.$$store('sendLoginMail', {email: kontakt.email}, 'E-Mail Verification versendet', '/api/mitarbeiter/'+kontakt.model_id+'/sendVerificationMail');
    },
    mitarbeiterKalender(kontakt) {
      this.$router.push('/intern/kalender');
      setTimeout(() => {
        this.$nuxt.$emit('display-kontakt-kalender', kontakt);
      }, 200)
    }
  },
  mounted() {
    if (this.anzahlElemente == 1) this.displayDetails = true;
  },

}
