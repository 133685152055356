
import termin from '~/mixins/kalender/termin';
import topabstand from '~/mixins/kalender/topabstand';
export default {
  mixins: [termin, topabstand],
  props: ['termin', 'kalender', 'spaltenData', 'terminHeightFix', 'displayDatum'],
  data() {
    return {
      showTooltip: false,
      terminDetails: this.termin,
    }
  },
  computed: {
    terminHeight() {
      return (this.topAbstand(this.termin.bis) - this.topAbstand(this.termin.von))
    }
  },
  methods: {},
  mounted() {}
}
