export default {
  mixins: [],
  props: [],
  data() {
    return {
      config: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    configAbrufen() {
      this.$$abrufen('config').then(config => {
        this.$store.commit('setConfig', config);
      })
    },
  },
  mounted() {
    this.configAbrufen();
  },

}
