
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'krankenkassenart-formular',
      modaltitel: 'Krankenkassenart',
      event: 'krankenkassenart',
      krankenkassenart: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(krankenkassenart = null) {
      if (krankenkassenart) this.krankenkassenart = this.$copy(krankenkassenart);
      else this.krankenkassenart = {bezeichnung: '', fachbereich: 0};
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.krankenkassenart.id) this.$$update('krankenkassenarten', this.krankenkassenart, 'Krankenkassenart gespeichert').then(() => this.done('update-'+this.event));
      else this.$$store('krankenkassenarten', this.krankenkassenart, 'Krankenkassenart gespeichert').then(() => this.done('store-'+this.event));
    },
    done(event = null) {
      this.$bvModal.hide(this.modalid)
      if (event) this.$nuxt.$emit(event)
    }
  },
  mounted() {
    this.$nuxt.$on('create-'+this.event, this.start);
    this.$nuxt.$on('edit-'+this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-'+this.event, this.start);
    this.$nuxt.$off('edit-'+this.event, this.start);
  },

}
