
export default {
  mixins: [],
  props: ['arbeitsstunde', 'show', 'kalender'],
  data() {
    return {
      showTooltip: this.show,
    }
  },
  computed: {},
  watch: {
    show(neu) {
      this.showTooltip = neu;
    }
  },
  methods: {
    emitChange(val) {
      this.$emit('update:show', val)
    },
    showModal() {
      this.emitChange(true)
      // AddBox
      if (this.arbeitsstunde.trigger_type == 'addbox') return;
      this.$$find('arbeitsstunden', this.arbeitsstunde.id).then(arbeitsstunde => {
        arbeitsstunde.details_abgerufen = true;
        this.$emit('update:arbeitsstunde', arbeitsstunde);
      })
    },
    bearbeiten() {
      // Sonstige Arbeitszeiten bearbeiten
      if (this.arbeitsstunde.trigger_type.includes('Ausfallstunde')) this.$nuxt.$emit('create-ausfallstunde-arbeitszeit', this.arbeitsstunde.ausfallstunden[0]);
      if (this.arbeitsstunde.trigger_type.includes('Sonstiges')) this.$nuxt.$emit('edit-sonstige-arbeitsstunde', this.arbeitsstunde.sonstiges);
      if (this.arbeitsstunde.trigger_type.includes('Orga')) this.$nuxt.$emit('edit-sonstige-arbeitsstunde', this.arbeitsstunde.orga);
      // Therapiestundenparam für Einzeln bearbeten von Parallelbehandlung
      if (this.arbeitsstunde.trigger_type.includes('Therapiestunde')) this.$nuxt.$emit('edit-dokumentation', this.arbeitsstunde.therapiestunden);
      this.emitChange(false);
    },
    loeschen() {
      this.$loeschen('Soll die Arbeitszeit wirklich gelöscht werden?').then(done => {
        if (done) {
          this.$$delete('sonstige', this.arbeitsstunde.sonstiges.id).then((response) => {
            this.$nuxt.$emit('refresh-termine-'+this.$moment(response.von).format('YYYY-MM-DD'));
          })
        }
      })
    },
    loeschenArbeitszeit() {
      this.$loeschen('Soll die Arbeitszeit wirklich gelöscht werden?').then(done => {
        if (done) {
          this.$$delete('arbeitsstunden', this.arbeitsstunde.id).then((response) => {
            this.$nuxt.$emit('refresh-termine-'+this.$moment(response.von).format('YYYY-MM-DD'));
          })
        }
      })
    }
  },
  mounted() {
    this.$$fill('arbeitsstunden', {with: ['anfahrten', 'sonstiges.projekt', 'therapiestunden.patient', 'therapiestunden.verordnung', 'therapiestunden.mitarbeiter', 'ausfallstunden', 'orga.patienten', 'therapiestunden.kostenpunkte']})
  },

}
