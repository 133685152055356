
export default {
  mixins: [],
  props: ['datum', 'mitarbeiter'],
  data() {
    return {
      arbeitsstundenSummen: null,
    }
  },
  computed: {},
  watch: {
    datum() {
      this.arbeitsstundenSummen = null;
      this.abrufen();
    }
  },
  methods: {
    abrufen() {
      this.$$abrufen('feiertage', null, '/api/kalender/arbeitsstundenSummen/'+this.datum+'/'+this.mitarbeiter.id).then(response => {
        this.arbeitsstundenSummen = response;
      })
    },
  },
  mounted() {
    this.abrufen();
  },

}
