
import absagetermin from '~/mixins/kalender/absagetermin';
export default {
  mixins: [absagetermin],
  props: ['titel', 'mitarbeiter_id'],
  data() {
    return {
      formulartitel: this.titel,
      urlaub: null,
      bisEdit: false,
      urlaubstage: 0,
    }
  },
  computed: {
    zeitraumValid() {
      let valid = true;
      if (!this.$moment(this.urlaub.von).isSameOrBefore(this.urlaub.bis)) valid = false;
      if (!this.$can('Herr der Zeit') && !this.$moment(this.urlaub.von).isSameOrAfter(this.$moment().subtract(1, 'days'))) valid = false;
      return valid;
    },
    empty() {
      return {
        mitarbeiter_id: this.mitarbeiter_id,
        von: this.$moment().format('YYYY-MM-DD'),
        bis: this.$moment().format('YYYY-MM-DD'),
        kommentar: null,
      };
    },
  },
  methods: {
    speichern() {
      if (!this.zeitraumValid) return null;
      this.$$store('urlaube', this.urlaub, "Erfolgreich gespeichert").then((response) => {
        this.$bvModal.hide('urlaub-formular')
        this.$nuxt.$emit('store-urlaub');
      });
    },
    updateBis() {
      if (this.bisEdit) return;
      this.$nextTick(() => {
        this.urlaub.bis = this.urlaub.von;
      })
    },
    show() {
      this.urlaub = this.$copy(this.empty);
      this.neuAbrufen();
    },
    neuAbrufen() {
      setTimeout(() => {
        if (this.$moment(this.urlaub.von) > this.$moment().add(14, 'days')) {
          this.absagestatus = false;
          return;
        }
        else this.absagestatus = true;

        // Nicht beliebig zurück Urlaub checken, das erlaubt das Feiertagescript nicht
        if (!this.urlaub.von || !this.urlaub.bis || this.urlaub.von < '2000-01-01' || this.urlaub.bis < '2000-01-01') return;
        this.$$abrufen('urlaubPruefen', null, '/api/urlaube/pruefen?von='+this.urlaub.von+'&bis='+this.urlaub.bis+'&mitarbeiter_id='+this.mitarbeiter_id).then(data => {
          this.urlaubstage = data.urlaubstage;
          this.termine = data.termine;
        });
      }, 500)
    }
  }
}
