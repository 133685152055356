
export default {
  mixins: [],
  props: ['verordnung', 'options'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    verordnungsaenderungModel(aenderung) {
      let send = this.$copy(aenderung);
      send.verordnung = this.verordnung;
      return send;
    },
    loeschen(verordnung) {
      this.$loeschen('Möchtest du die Verordnung wirklich löschen?').then(response => {
        if (response) {
          this.$$delete('verordnungen', verordnung.id, null, 'Verordnung erfolgreich gelöscht').then(() => {
            this.abrufen();
          })
        }
      })
    },
    display(buttontype) {
      return Object.values(this.options).includes(buttontype)
    },
    titel(buttontype, fallback) {
      let index = Object.keys(this.options).find(key => this.options[key] === buttontype)
      if (isNaN(parseInt(index))) return index;
      return fallback;
    },
    schreibenErstellen(verordnungsaenderung) {
      this.$nuxt.$emit('create-schreiben', {model_id: verordnungsaenderung.id, model_type: 'App\\Models\\Verordnungsaenderung', titel: 'Verordnungsänderung', anhang: verordnungsaenderung.dateiversion_id})
    },
    verordnungsaenderungErledigt(verordnungsaenderung) {
      this.$confirm('Soll die Verordnungsänderung als erledigt markiert werden?').then(response => {
        if (response) {
          verordnungsaenderung.erledigt = this.$moment().format('YYYY-MM-DD');
          this.$$update('verordnungsaenderungen', verordnungsaenderung)
        }
      })
    },
    inTherapieSchieben() {
      this.$confirm('Bist du sicher, dass die Verordnung nicht geändert werden muss?').then(response => {
        if (response) {
          let verordnung = this.$copy(this.verordnung);
          verordnung.aenderungen_erledigt = 1;
          this.$$update('verordnungen', verordnung)
        }
      })
    },
  },
  mounted() {},

}
