
export default {
  mixins: [],
  props: ['termin', 'show', 'kalender'],
  data() {
    return {
      showTooltip: this.show,
      falllistener: false,
    }
  },
  computed: {},
  watch: {
    show(neu) {
      this.showTooltip = neu;
    }
  },
  methods: {
    emitChange(val) {
      this.$emit('update:show', val)
    },
    showModal() {
      this.emitChange(true)
      this.$$find('termine', this.termin.id).then(termin => {
        termin.details_abgerufen = true;
        this.$emit('update:termin', termin);
        this.$nuxt.$emit('update::termin', termin);
      })
    },
    addFalllistener() {
      this.$nuxt.$on('update-fall', this.showModal);
      this.falllistener = true;
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$nuxt.$off('update-fall', this.showModal);
  }


}
