
import formular from '~/mixins/formular';
export default {
  mixins: [formular],
  props: ['value', 'formulartitel', 'mitarbeiter', 'summeStundenzahl', 'wochenstunden'],
  data() {
    return {
      verfuegbarkeit: null,
      standortAuswahl: null,
      dauerAngepasst: false,
      minutenzahlAlt: 0,
      modalid: 'verfuegbarkeit-formular'
    }
  },
  computed: {
    checkVonBis() {
      if (!this.verfuegbarkeit || !this.verfuegbarkeit.von || !this.verfuegbarkeit.bis) return null;
      if (this.verfuegbarkeit.bis <= this.verfuegbarkeit.von) return "Dein Zeitraum passt nicht!";
    },
    dauerMinuten() {
      if (this.checkVonBis) return;
      let dauer = this.dauerMinutenBerechnen(this.verfuegbarkeit.von, this.verfuegbarkeit.bis);
      if (!this.dauerAngepasst) {
        this.verfuegbarkeit.minutenzahl = dauer;
        this.updateMinuten();
      }
      return dauer;
    },
    dauerStunden() {
      if (!this.dauerMinuten) return;
      let dauer = this.$round(this.dauerMinuten / 60);
      this.verfuegbarkeit.geplante_stundenzahl = dauer;
      return dauer;
    },
    uebrigeStunden() {
      return this.$round(this.wochenstunden - this.summeStundenzahl + (this.minutenzahlAlt / 60));
    },
    minutenzahlError() {
      if (!this.dauerMinuten) return;
      if (this.dauerMinuten < this.verfuegbarkeit.minutenzahl) return "Du kannst nicht mehr Arbeitszeit als Verfügbarkeit planen"
      return null;
    },
  },
  watch: {
    value(neu) {
      this.empty();
      if (neu && neu.id) {
        if (neu.standort_id) this.standortAuswahl = 'praxis';
        else if (neu.ort) this.standortAuswahl = 'extern';
      }
      this.error = null;
    },
    wochentag(neu) {
      if (this.verfuegbarkeit.wochentag) this.verfuegbarkeit.wochentag = neu;
    },
  },
  methods: {
    empty() {
      this.dauerAngepasst = false;
      if (!this.value) return;
      this.minutenzahlAlt = this.value.minutenzahl;
      // Arbeitszeit schon anders geplant als Gesamtzeit
      if (this.value.minutenzahl !== null) {
        if (this.dauerMinutenBerechnen(this.value.von, this.value.bis) != this.value.minutenzahl) {
          this.dauerAngepasst = true;
        }
      }
      this.verfuegbarkeit = this.value;

      this.standortAuswahl = 'praxis';
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    },
    speichern() {
      this.$bvModal.hide(this.modalid);
      this.verfuegbarkeit.geplante_stundenzahl = Number(this.verfuegbarkeit.geplante_stundenzahl);
      this.verfuegbarkeit.stundenzahl = Number(this.verfuegbarkeit.stundenzahl);
      this.verfuegbarkeit.minutenzahl = Number(this.verfuegbarkeit.minutenzahl);
      if (this.verfuegbarkeit.standort) this.verfuegbarkeit.tatsaechlicher_ort = this.verfuegbarkeit.standort.name;
      else if (this.verfuegbarkeit.ort) this.verfuegbarkeit.tatsaechlicher_ort = this.verfuegbarkeit.ort;
      this.$emit('input', this.verfuegbarkeit)
    },
    updateStunden() {
      this.dauerAngepasst = true;
      this.$nextTick(() => {
        this.verfuegbarkeit.minutenzahl = this.$round(this.verfuegbarkeit.stundenzahl * 60, 0);
      })
    },
    updateMinuten() {
      this.dauerAngepasst = true;
      this.$nextTick(() => {
        this.verfuegbarkeit.stundenzahl = this.$round(this.verfuegbarkeit.minutenzahl / 60);
      })
    },
    dauerMinutenBerechnen(von, bis) {
      return this.$moment(bis, 'HH:mm').diff(this.$moment(von, 'HH:mm'), 'minutes');
    },
  },
  mounted() {
    this.empty();
  }
}
