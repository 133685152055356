export default function ({$axios}) {
  // $axios.onRequest(config => {
  //   let service = Helpers.getVar(config.url);
  //   if (!service.includes('http')) {
  //     let newhost = store.getters['services/find'](service);
  //     if (!newhost) {
  //       newhost = store.getters['services/find'](service);
  //     }

  //     config.url = config.url.replace('{'+service+'}', newhost);
  //   }
  // })
  // $axios.onRequest(config => {
  //   if (config.headers) config.headers['app-version'] = $nuxt.$appVersion;
  // })
  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      $nuxt.$loading.start();
      $nuxt.$error('Du bist nicht mehr eingeloggt.');
      $nuxt.$auth.logout().then(() => {
        $nuxt.$loading.finish();
      });
    }
  })
}
