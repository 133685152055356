

export default {
  mixins: [],
  props: ['value', 'params'],
  data() {
    return {
      kontakt: null,
    }
  },
  computed: {},
  watch: {
    value(neu) {
      this.fill();
    }
  },
  methods: {
    fill() {
      if (!this.value) return;
      this.kontakt = this.value;
    },
    emit() {
      this.$nextTick(() => {
        this.$emit('input', this.kontakt);
      })
    }
  },
  mounted() {
    if (this.params && this.params.filter) this.$$fill('kontakte', {filter: this.params.filter});
    this.fill();
  },

}
