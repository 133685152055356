export const state = () => ({
  debugMode: false,
  initsetdebug: false,
  config: null,
});

const mutations = {
  debugMode(state, val) {
    state.debugMode = val;
  },
  initSetDebug(state, val) {
    if (state.initsetdebug) return;
    state.debugMode = val;
    state.initsetdebug = true;
  },
  setConfig(state, val) {
    state.config = val;
  }
};

const getters = {
  debugMode(state) {
    return state.debugMode;
  },
  config: (state) => (key) => {
    if (!state.config) return null;
    let conf = state.config.find(el => el.key == key);
    if (conf) return conf.value;
    return conf;
  }
};

export default {
  state,
  mutations,
  getters
}
