
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'therapiestunde-loeschen',
      modaltitel: 'Therapiestunde löschen',
      event: 'therapiestunde-loeschen',
      therapiestunde: null,
      sendenButton: "Löschen",
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(therapiestunde) {
      this.therapiestunde = therapiestunde;
      this.$bvModal.show(this.modalid);
    },
    loeschen() {
      this.$$delete('therapiestunden', this.therapiestunde.id).then(() => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('delete-therapiestunde');
      })
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
