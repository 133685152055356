
export default {
  mixins: [],
  props: ['mitarbeiter'],
  data() {
    return {
      rollen: null,
      permissions: null,
      user: null,
    }
  },
  computed: {},
  watch: {
    mitarbeiter(neu) {
      this.start();
    }
  },
  methods: {
    abrufen() {
      this.$$abrufen('roles').then(response => {
        this.rollen = response.rollen;
        this.permissions = response.permissions;
      })
    },
    speichern() {
      this.$$update('users', this.user, 'Permissions aktualisiert').then(() => this.$emit('done'));
    },
    start() {
      this.user = null;
      this.abrufen();
      let user = this.mitarbeiter.user;
      user.roles = user.roles.map(el => el.id);
      user.permissions = user.permissions.map(el => el.id);
      this.user = user;
    }
  },
  mounted() {
    this.start();
  },

}
