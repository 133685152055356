
export default {
  mixins: [],
  props: ['model', 'required'],
  data() {
    return {
      id: this._uid,
      ortkey: 0,
      raumbelegungDetailsAnzeigen: false,
    }
  },
  computed: {
    fachbereich() {
      if (this.model.fachbereich !== null) return this.model.fachbereich;
      if (this.model.mitarbeiter && this.model.mitarbeiter[0]) return this.model.mitarbeiter[0].fachbereich;
      return null;
    }
  },
  watch: {},
  methods: {
    updateStandort(event) {
      if (!event || !event.id) this.model.standort_id = this.model.standort = null;
      else if (event) this.model.standort_id = event.id;
      this.model.raum_id = null;
      this.model.raum = null;
      this.model.materialien = null;
      this.model.fahrzeuge = null;
      this.ortkey++
    },
    updateRaum(event) {
      if (!event || !event.id) this.model.raum_id = this.model.standort = null;
      else if (event) this.model.raum_id = event.id;
    },
  },
  mounted() {},

}
