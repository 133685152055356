
export default {
  mixins: [],
  props: ['urlaubsanspruch'],
  data() {
    return {
      modalid: 'urlaubsdetails',
      modaltitel: 'Urlaubsanspruch Detail',
    }
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  beforeDestory() {},

}
