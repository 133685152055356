
import therapiestunde from '~/mixins/models/therapiestunde';
import fahrten from '~/mixins/fahrten';
export default {
  mixins: [therapiestunde, fahrten],
  props: [],
  data() {
    return {
      termin: null,
      invalid: null,
      zeitDisabled: true,
      stunde: null,
      dauerAlt: null,
      therapieort: 0,
    }
  },
  computed: {
    therapieorte() {
      let options = {1: 'Hausbesuch'};
      if (this.termin && this.termin.fachbereich == 1) options[2] = 'Heimbesuch';
      options[3] = 'In einer Einrichtung';
      return options;
    }
  },
  watch: {},
  methods: {
    start(termin) {
      this.termin = termin;
      this.zeitDisabled = true;
      this.invalid = null;
      this.dauerAlt = null;
      this.stunde = this.emptyStunde();
      this.stunde.mitarbeiter = termin.mitarbeiter;
      if (termin.parallelbehandlung) this.stunde.parallelbehandlung = true;
      if (termin.erstbefundung) this.stunde.erstbefundung = true;
      if (termin.bedarfsdiagnostik) this.stunde.bedarfsdiagnostik = true;
      if (this.termin.hausbesuch) this.therapieort = 1;
      this.$bvModal.show('doku-modal');
    },
    bearbeiten(therapiestunden) {
      let stunde = this.$copy(therapiestunden[0]);
      stunde.therapiestunden = therapiestunden;
      this.stunde = this.$copy(stunde);
      this.resyncAnfahrt(this.stunde);

      this.zeitDisabled = true;
      this.invalid = null;
      if (this.stunde.hausbesuch) this.therapieort = 1;
      this.$bvModal.show('doku-modal');
    },
    patientEntfernen(index) {
      this.stunde.therapiestunden.splice(index, 1);
    },
    patientHinzufuegen(event) {
      this.stunde.therapiestunden.push({
        patient: event.patient,
        patient_id: event.patient.id,
        verordnung: event.verordnung,
        verordnung_id: event.verordnung.id,
        dokumentation: '',
        naechster_termin: '',
        therapieziel: '',
        erstbefundung: false,
        teilnehmerzahl: null,
      })
    },
    enableTime() {
      this.$confirm('Sollen Datum und Uhrzeit wirklich geändert werden?').then(response => {
        this.zeitDisabled = !response;
      })
    },
    speichern() {
      if (this.stunde.mitarbeiter) this.stunde.mitarbeiter_id = this.stunde.mitarbeiter.id;
      // Gruppe oder nicht
      this.stunde.therapiestunden.map(therapiestunde => {
        therapiestunde.teilnehmerzahl = this.stunde.therapiestunden.length;
      })
      this.$$store('therapiestunden', this.stunde, 'Doku erfolgreich erfasst').then(therapiestunden => {
        this.$bvModal.hide('doku-modal');
        this.$nuxt.$emit('store-doku', therapiestunden);
        this.$nuxt.$emit('refresh-termine-'+this.$moment(therapiestunden[0].von).format('YYYY-MM-DD'));
      })
    },
    updateDauerKostenpunkt(neueDauer) {
      if (!this.dauerAlt) {
        if (this.termin) this.dauerAlt = this.termin.dauer;
        else this.dauerAlt = this.stunde.dauer;
      }
      this.stunde.dauer = neueDauer;
    },
  },
  mounted() {
    this.$$leeren('therapiestunden');
    this.$nuxt.$on('create-dokumentation', this.start);
    this.$nuxt.$on('edit-dokumentation', this.bearbeiten);
  },
  beforeDestroy() {
    this.$nuxt.$off('create-dokumentation', this.start);
    this.$nuxt.$off('edit-dokumentation', this.bearbeiten);
  }

}
