import { render, staticRenderFns } from "./AnrufFallZuweisen.vue?vue&type=template&id=175d76e2"
import script from "./AnrufFallZuweisen.vue?vue&type=script&lang=js"
export * from "./AnrufFallZuweisen.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternTelefonieAnrufZeile: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/AnrufZeile.vue').default,InternTelefonieFallliste: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/Fallliste.vue').default})
