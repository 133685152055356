export default {
  data() {
    return {}
  },
  methods: {
    topAbstand(uhrzeit) {
      if (this.kalender.topabstand.meta) return this.topAbstandRechner(uhrzeit, this.kalender.topabstand.meta.uhrzeiten, this.kalender.topabstand.meta.vonTimestamp, this.kalender.topabstand.meta.bisTimestamp, this.kalender.topabstand.meta.kalenderHoehe);
      return this.topAbstandRechner(uhrzeit, this.kalenderUhrzeiten, this.kalender.meta.vonTimestamp, this.kalender.meta.bisTimestamp, this.kalender.meta.hoehe);
    },
    topAbstandRechner(uhrzeit, uhrzeiten, vonTimestamp, bisTimestamp, kalenderHoehe) {
      if (!vonTimestamp || !bisTimestamp) return 0;
      if (!kalenderHoehe) return 0;
      uhrzeiten // Nur um zu refreshen bei einer Änderung
      // UhrzeitTop wird immer auf Heute gesetzt
      let uhrzeitTop = this.$moment('1970/01/01 '+ this.$moment(uhrzeit).format('HH:mm:ss'), 'YYYY/MM/DD HH:mm:ss').unix();
      // Termin Uhrzeit, Abstand nach oben
      let secondsTop = uhrzeitTop - vonTimestamp;
      // Spanne der Sekunden die angezeigt wird
      let maxSeconds = bisTimestamp - vonTimestamp;
      // Px Abstand nach oben pro Sekunde
      let pxPerSecond = kalenderHoehe / maxSeconds;
      // Abstand nach oben in Pixeln
      return secondsTop * pxPerSecond;
    }
  }
}
