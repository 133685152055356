
export default {
  mixins: [],
  props: ['mitarbeiter_id'],
  data() {
    return {
      modalid: 'urlaubskonto-korrekturbuchung',
      modaltitel: 'Korrekturbuchung',
      korrekturbuchung: {
        veraenderungStunden: null,
        minuten_veraenderung: null,
        tage_veraenderung: null,
        kommentar: null,
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if ((!this.korrekturbuchung.tage_veraenderung && !this.korrekturbuchung.minuten_veraenderung) || !this.korrekturbuchung.kommentar) return;
      this.$$store('urlaubskonto', this.korrekturbuchung, null, '/api/mitarbeiter/'+this.mitarbeiter_id+'/urlaubskonto').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$emit('refresh');
      })
    },
    updateVeraenderungStunden() {
      this.korrekturbuchung.minuten_veraenderung = this.$round(this.korrekturbuchung.veraenderungStunden * 60, 0);
    }
  },
  mounted() {},
  beforeDestory() {},

}
