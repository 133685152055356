
export default {
  mixins: [],
  props: [],
  data() {
    return {
      loading: false,
      foto: null,
      width: null,
      height: null,
      kameras: [],
      kameraAuswahl: 0,
      kamerasErkennenCounter: 0
    }
  },
  computed: {

    constraints() {
      return (window.constraints = {
				audio: false,
				video: {
          deviceId: this.kameras[this.kameraAuswahl].deviceId,
          width: {ideal: 4096},
          height: {ideal: 2160}
        }
			});
    }
  },
  watch: {},
  methods: {
    createCameraElement() {
      this.kameras;
      this.loading = true;
      navigator.mediaDevices
				.getUserMedia(this.constraints)
				.then(stream => {
          this.$refs.camera.srcObject = stream;
          // Kameras im Zweifel 2 mal erkennen, falls beim ersten Mal nur 1 erkannt wird, bei iPads scheint das ein Problem zu sein
          if (this.kameras.length == 1 && this.kamerasErkennenCounter == 1) return this.kamerasErkennen();
          this.loading = false;
				})
				.catch(error => {
          this.loading = false;
					alert("Der Zugriff auf die Kamera hat nicht funktioniert.");
				});
    },
    stopCameraStream() {
      if (!this.$refs.camera) return;
      let tracks = this.$refs.camera.srcObject.getTracks();
			tracks.forEach(track => {
				track.stop();
			});
    },
    fotoMachen() {
      this.width = this.$refs.camera.videoWidth;
      this.height = this.$refs.camera.videoHeight;
      const canvas = this.$refs.canvas;
      canvas.width = this.width;
      canvas.height = this.height;

      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, this.width, this.height);
      // Kompression der Fotos hat nichts gebracht, also auskommentiert, ggf. braucht man das nochmal :D
      // canvas.toBlob(
      //   blob => {
      //     if (blob) {
      //       compressedImageBlob = blob;
      //       compressedImage.src = URL.createObjectURL(compressedImageBlob);
      //     }
      //   }, "image/jpeg", 0.8
      // )
      this.foto = canvas.toDataURL("image/jpeg");
    },
    kamerasErkennen() {
      this.kamerasErkennenCounter++;
      this.kameras = [];
      this.kameraAuswahl = 0;
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach(device => {
          if (device.kind == 'videoinput') this.kameras.push(device);
        })
        setTimeout(() => {
          if (this.kameras.length == 2) this.kameraAuswahl = 1;
          this.createCameraElement();
        }, 200)
      })
    },
    kameraWechseln(value = null) {
      if (value != null) this.kameraAuswahl = value;
      else {
        if (this.kameraAuswahl >= (this.kameras.length - 1)) this.kameraAuswahl = 0;
        else this.kameraAuswahl = this.kameraAuswahl+1;
      }
      this.createCameraElement();
    }
  },
  mounted() {
    this.kamerasErkennen();
  },
  beforeDestroy() {
    this.stopCameraStream();
  }

}
