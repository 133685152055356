
export default {
  mixins: [],
  props: [],
  data() {
    return {
      params: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.params = params;
      this.$bvModal.show('dateien-modal');
    },
  },
  mounted() {
    this.$nuxt.$on('display-dateien', this.start);
  },

}
