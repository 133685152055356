
export default {
  mixins: [],
  props: ['content'],
  data() {
    return {
      kopiert: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    kopieren() {
      this.$copyToClipboard(this.content).then(() => {
        this.kopiert = true;
        setTimeout(() => {
          this.kopiert = false;
        }, 3000)
      })
    }
  },
  mounted() {},

}
