
export default {
  mixins: [],
  props: ['termin'],
  data() {
    return {
      mitarbeiter: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    abrufen() {
      this.$axios.get('/api/termine/'+this.termin.id+'/vertretungen').then(response => {
        this.mitarbeiter = response.data;
      })
    }
  },
  mounted() {
    this.abrufen();
  },

}
