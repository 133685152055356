
export default {
  mixins: [],
  props: [],
  data() {
    return {
      sidebarRightShow: false,
      laufendeAnrufe: null,
      anrufSelected: null,
    }
  },
  computed: {
    sidebarSmallShow() {
      if (this.laufendeAnrufe && this.laufendeAnrufe.length) {
        this.$emit('sidebarRightSmallShow', true);
        return true;
      }
      this.$emit('sidebarRightSmallShow', false);
      return false;
    }
  },
  watch: {},
  methods: {
    laufendeAnrufeAktualisieren(data) {
      this.laufendeAnrufe = data;
    },
    anrufDokumentieren(anruf) {
      this.anrufSelected = anruf;
      if (anruf) this.sidebarRightShow = true;
      else this.sidebarRightShow = false;
    },
    abrufen() {
      this.$$abrufen('laufendeAnrufe').then(anrufe => {
        this.laufendeAnrufe = anrufe;
        // TODO: rausnehmen, nur für Debugging
        // if (this.laufendeAnrufe.length && !this.anrufSelected) this.anrufDokumentieren(this.laufendeAnrufe[0]);
      })
    }
  },
  mounted() {
    // Im Template wird das Padding entsprechend gesetzt
    this.$emit('sidebarRightShow', this.sidebarRightShow);
    this.abrufen();
    this.$nuxt.$on('socket.LaufendeAnrufe', this.laufendeAnrufeAktualisieren);
    this.$nuxt.$on('create-laufenderanruf-fall', this.anrufDokumentieren);
  },
  beetrforeDestroy() {
    this.$nuxt.$off('socket.LaufendeAnrufe', this.laufendeAnrufeAktualisieren);
    this.$nuxt.$off('create-laufenderanruf-fall', this.anrufDokumentieren);
  }

}
