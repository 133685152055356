
export default {
  mixins: [],
  props: ['stunde'],
  data() {
    return {
      patient: null,
      verordnung: null,
      formkey: 0,
      visible: false,
    }
  },
  computed: {
    disabled() {
      if (!this.patient || !this.verordnung) return true;
      return false;
    },
    verordnungVorlage() {
      return this.stunde.therapiestunden[0].verordnung
    }

  },
  watch: {},
  methods: {
    hinzufuegen() {
      if (this.disabled) return;
      this.$emit('hinzufuegen', this.$copy({patient: this.patient, verordnung: this.verordnung}))
      this.reset();
    },
    reset() {
      this.patient = null;
      this.verordnung = null;
      this.formkey++;
      this.visible = false;
    }
  },
  mounted() {

  },

}
