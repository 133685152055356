
export default {
  props: {
    'id': {}, 'type': {},
    titel: {default: 'Kommentare'},
  },
  data() {
    return {
      kommentare: [],
      kommentieren: false
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    kommentarAbsenden(content) {
      let payload = {
        id: 'neu',
        model_id: this.id,
        model_type: this.type,
        text: content.text,
        titel: content.titel,
        user_id: this.$auth.user.model_id
      };
      this.$axios.post('/api/kommentare/'+this.type+'/'+this.id, payload).then(response => {
        this.kommentieren = false;
        this.kommentare.push(response.data);
      });
    },
    load() {
      this.$axios.get('/api/kommentare/'+this.type+'/'+this.id).then(response => {
        this.kommentare = response.data;
      })
    }
  },
  computed: {
        portalKommentare: function() {
            return this.kommentare.filter(kommentar => {
                return (!kommentar.user_id);
            })
        },
        userKommentare: function() {
            return this.kommentare.filter(kommentar => {
                return (kommentar.user_id);
            })
        }
    },
}
