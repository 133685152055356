
export default {
  mixins: [],
  props: ['url'],
  data() {
    return {
      loading: false,
      file: '',
      showFile: false,
      source: null,
      type: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    loadPdf(url) {
      this.source = this.$axios.CancelToken.source();
      this.$axios({url: url, method: 'GET', responseType: 'blob', cancelToken: this.source.token}).then(response => {
        this.type = response.data.type;

        const url = window.URL.createObjectURL(response.data);
        this.file = url;
        this.$nextTick(function() {
          this.showFile = true;
        })
      }, error => {
        this.$error('Datei nicht gefunden!');
        this.$emit('error');
      });
    },
    drehen(richtung) {
      this.$axios.put(this.url+'/drehen/'+richtung).then(el => {
        this.loadPdf(this.url);
      })
    }
  },
  mounted() {
    this.loadPdf(this.url);
  },
  beforeDestroy() {
    this.showFile = null;
    this.source.cancel('Abruf abgebrochen');
  }

}
