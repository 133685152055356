import WebAuthn from '~/plugins/webauthn.js';
window.WebAuthn = WebAuthn;
let prefix = process.env.API_URL;
if (process.env.NODE_ENV == 'development') {
  prefix = '';
}
window.webAuthnRoutes = {
  registerOptions: prefix+"/api/webauthn/register/options",
  register: prefix+"/api/webauthn/register",
  loginOptions: prefix+"/api/webauthn/login/options",
  login: prefix+"/api/webauthn/login",
};
