
export default {
  mixins: [],
  props: ['verordnung', 'verordnungen'],
  data() {
    return {
      verordnungDetails: null,
      selectedModel: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    select(type, model) {
      this.verordnungDetails = type;
      this.selectedModel = model;
      this.$emit('size', 'lg');
    },
    verordnungAbbrechen() {
      this.$nuxt.$emit('display-verordnung-abbrechen-modal', this.verordnung);
    },
    therapiestundenAbrufen() {
      this.$$abrufen('therapiestunden');
    }
  },
  mounted() {
    this.$$fill('therapiestunden', {filter: [{where: ['verordnung_id','=', this.verordnung.id]}]});
    this.therapiestundenAbrufen();
  },

}
