
export default {
  mixins: [],
  props: ['therapiestunde'],
  data() {
    return {}
  },
  computed: {
    hatEditListener(){
      if (!this.$listeners) return false;
      return this.$listeners && this.$listeners.edit;
    },
  },
  watch: {},
  methods: {},
  mounted() {},

}
