
export default {
  mixins: [],
  props: ['fall'],
  data() {
    return {}
  },
  computed: {
    status() {
      if (this.fall.status == 0) return 'offen';
      if (this.fall.status == 1) return 'Warten auf Rückmeldung';
      if (this.fall.status == 2) return 'geschlossen';
      if (this.fall.status == 3) return 'Warten auf Mitarbeiter';
      if (this.fall.status == 4) return 'Verpasster Anruf';
    },
    badge() {
      if (this.fall.status == 0) return 'badge-danger';
      if (this.fall.status == 1) return 'badge-info';
      if (this.fall.status == 2) return 'badge-success';
      if (this.fall.status == 3) return 'badge-cyan';
      if (this.fall.status == 4) return 'badge-danger';
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
