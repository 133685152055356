
import kontakte from '~/mixins/models/kontakte';
export default {
  mixins: [kontakte],
  props: ['arzt'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if (this.arzt.id) {
        this.$$update('aerzte', this.arzt, 'Arzt aktualisiert').then(response => {
          this.$nuxt.$emit('update-arzt', response);
          this.$nuxt.$emit('update-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
      else {
        this.$$store('aerzte', this.arzt, 'Neuer Arzt gespeichert').then(response => {
          this.$nuxt.$emit('store-arzt', response);
          this.$nuxt.$emit('store-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
    },
  },
  mounted() {},

}
