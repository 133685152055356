export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    resyncAnfahrt(model) {
      if (!model || !model.anfahrt || !model.rueckfahrt) return;
      model.anfahrt.bis = model.von;
      model.anfahrt.von = this.$moment(model.anfahrt.bis).subtract(model.anfahrt.dauer, 'minutes').format('YYYY-MM-DDTHH:mm');
      model.rueckfahrt.von = model.bis;
      model.rueckfahrt.bis = this.$moment(model.rueckfahrt.von).add(model.rueckfahrt.dauer, 'minutes').format('YYYY-MM-DDTHH:mm');

    },
  },
  mounted() {},
}
