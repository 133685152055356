
export default {
  mixins: [],
  props: ['model'],
  data() {
    return {
      zuordnung: this.model,
      modeltypes: ['Verordnung', 'Patient', 'Mitarbeiter'],
      patient: null,
      id: this._uid,
    }
  },
  computed: {},
  watch: {},
  methods: {
    emit() {
      this.$nextTick(() => {
        if (this.zuordnung && this.zuordnung.model) this.zuordnung.model_id = this.zuordnung.model.id;
        this.$emit('update:zuordnung', this.zuordnung);
      })
    },
  },
  mounted() {
    if (this.model && this.model.model_type && this.model.model_type.includes('Verordnung') && this.model.model) {
      this.$$find('patienten', this.model.model.patient_id).then(patient => {
        this.patient = patient;
      })
    }
  },

}
