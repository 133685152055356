
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeitergruppeformular',
      modaltitel: 'mitarbeitergruppe',
      mitarbeitergruppe: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    emptymitarbeitergruppe() {
      this.mitarbeitergruppe = {bezeichnung: '',mitarbeiter: []};
    },
    start(mitarbeitergruppe) {
      this.emptymitarbeitergruppe();
      if (mitarbeitergruppe) this.mitarbeitergruppe = mitarbeitergruppe;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.mitarbeitergruppe.id) this.$$update('mitarbeitergruppen', this.mitarbeitergruppe).then(() => this.done());
      else this.$$store('mitarbeitergruppen', this.mitarbeitergruppe).then(() => this.done())
    },
    done() {
      this.$bvModal.hide(this.modalid);
      this.$nuxt.$emit('store-mitarbeitergruppe');
      this.emptymitarbeitergruppe();
    },
    updateMitarbeiter() {
      this.mitarbeitergruppe.mitarbeiter = this.mitarbeitergruppe.mitarbeiter.map(el => {
        if (!el.pivot) el.pivot = {gruppenleitung: false};
        return el;
      });
    }
  },
  mounted() {
    this.$nuxt.$on('create-mitarbeitergruppe', this.start);
    this.$nuxt.$on('edit-mitarbeitergruppe', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-mitarbeitergruppe', this.start);
    this.$nuxt.$off('edit-mitarbeitergruppe', this.start);
  },

}
