
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'projektformular',
      modaltitel: 'Projekt',
      projekt: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    emptyProjekt() {
      this.projekt = {bezeichnung: '',mitarbeiter: []};
    },
    start(projekt) {
      this.emptyProjekt();
      if (projekt) this.projekt = projekt;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.projekt.id) this.$$update('projekte', this.projekt).then(() => this.done());
      else this.$$store('projekte', this.projekt).then(() => this.done())
    },
    done() {
      this.$bvModal.hide(this.modalid);
      this.$nuxt.$emit('store-projekt');
      this.emptyProjekt();
    },
    updateMitarbeiter() {
      this.projekt.mitarbeiter = this.projekt.mitarbeiter.map(el => {
        if (!el.pivot) el.pivot = {fuer_andere_erfassen: false, kontingent: null, zeitraum: 'gesamt'};
        return el;
      });
    }
  },
  mounted() {
    this.$nuxt.$on('create-projekt', this.start);
    this.$nuxt.$on('edit-projekt', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-projekt', this.start);
    this.$nuxt.$off('edit-projekt', this.start);
  },

}
