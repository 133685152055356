
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'cloudtag-formular',
      modaltitel: 'Cloudtag',
      event: 'cloudtag',
      cloudtag: null,
      modelTypeOptions: {'App\\Models\\Verordnung': 'Verordnung', 'App\\Models\\Patient': 'Patient', 'App\\Models\\Mitarbeiter': 'Mitarbeiter', 'App\\Models\\Krankmeldung': 'Krankmeldung', 'App\\Models\\Cloudtag': 'Cloudtag', 'App\\Models\\Abrechnung': 'Abrechnung'}
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(cloudtag = null) {
      if (cloudtag) this.cloudtag = this.$copy(cloudtag);
      else this.cloudtag = {name: '', scanbar: 1, ordner: 0, dateien: 1, model_types: []};
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.cloudtag.id) this.$$update('cloudtags', this.cloudtag, 'Cloudtag gespeichert').then(() => this.done('update-'+this.event));
      else this.$$store('cloudtags', this.cloudtag, 'Cloudtag gespeichert').then(() => this.done('store-'+this.event));
    },
    done(event = null) {
      this.$bvModal.hide(this.modalid)
      if (event) this.$nuxt.$emit(event)
    }
  },
  mounted() {
    this.$nuxt.$on('create-'+this.event, this.start);
    this.$nuxt.$on('edit-'+this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-'+this.event, this.start);
    this.$nuxt.$off('edit-'+this.event, this.start);
  },

}
