
export default {
  props: ['value'],
  data() {
    return {
      text: this.value,
      telefonBezeichnungen: []
    }
  },
  mounted() {
    this.$axios.get('/api/prefills/telefonbezeichnungen').then(response => {
      this.telefonBezeichnungen = response.data;
    })
  }
}
