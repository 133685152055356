import { render, staticRenderFns } from "./profil.vue?vue&type=template&id=0e27c7c6"
import script from "./profil.vue?vue&type=script&lang=js"
export * from "./profil.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternProfilNav: require('/builds/logo-yes/portal2022/frontend/components/intern/profil/nav.vue').default,InternProfilSubnav: require('/builds/logo-yes/portal2022/frontend/components/intern/profil/subnav.vue').default})
