
import formularmodal from '~/mixins/formulare/formularmodal';
import absagetermin from '~/mixins/kalender/absagetermin';
export default {
  mixins: [formularmodal, absagetermin],
  props: [],
  data() {
    return {
      modal: {
        id: 'urlaub-termine',
        event: 'check::urlaub-termine',
        titel: null,
        modelname: 'Urlaub',
        model: 'urlaube'
      },
      urlaubstage: null,
      termine: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.params = params;
      if (params.model) {
        this.fillModel(params.model);
        this.neuAbrufen();
      }
      else if (params.model_id) this.abrufen(params.model_id);
      if (params.titel) this.modal.titel = params.titel;
      this.oeffnen();
    },
    abrufen(id) {
      this.$$find('urlaube', id).then(response => {
        this.model = response;
        this.neuAbrufen();
      })
    },
    neuAbrufen() {
      this.$$abrufen('urlaubPruefen', null, '/api/urlaube/pruefen?von='+this.model.von+'&bis='+this.model.bis+'&mitarbeiter_id='+this.model.mitarbeiter_id).then(data => {
          this.urlaubstage = data.urlaubstage;
          this.termine = data.termine;
        });
    }
  },

}
