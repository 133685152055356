
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'sms-formular',
      modaltitel: 'SMS versenden',
      sendenButton: "SMS senden",
      model: null
    }
  },
  computed: {
    empty() {
      return {
        model_id: null, model_type: null, text: null, to: null, kontakt_id: null, kontakt: null
      };
    },
  },
  watch: {},
  methods: {
    start(model) {
      this.model = this.$fillMissing(this.$copy(this.empty), model)
      this.$bvModal.show(this.modalid);
    },
    senden() {
      this.$$store('sms', this.model, 'SMS erfolgreich versendet').then(sms => {
        this.$bvModal.hide(this.modalid);
        this.model = null;
      });
    },
    loeschen() {
      this.model = null;
    }
  },
  mounted() {
    this.$nuxt.$on('sms-senden', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('sms-senden', this.start);
  },

}
