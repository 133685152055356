
export default {
  props: [],
  data() {
    return {
      url: null,
      titel: 'PDF',
    }
  },
  methods: {
    viewPdf(meta) {
      this.url = meta.url;
      this.titel = meta.titel;
      this.$bvModal.show('pdf-modal');
    },
    hide() {
      this.url = null;
    }
  },
  mounted() {
    this.$nuxt.$on('view-pdf', this.viewPdf)
  },
  beforeDestroy() {
    this.$nuxt.$off('view-pdf', this.viewPdf)
  }
}
