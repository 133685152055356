import { render, staticRenderFns } from "./Faelle.vue?vue&type=template&id=f7cdac00"
import script from "./Faelle.vue?vue&type=script&lang=js"
export * from "./Faelle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternTelefonieFalluebersicht: require('/builds/logo-yes/portal2022/frontend/components/intern/telefonie/Falluebersicht.vue').default})
