
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arbeitsvertrag-beenden-formular',
      modaltitel: 'Arbeitsvertrag beenden',
      event: 'arbeitsvertrag-beenden',
      arbeitsvertrag: null,
      sendenButton: "Arbeitsvertrag beenden"
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(arbeitsvertrag) {
      this.arbeitsvertrag = arbeitsvertrag;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.arbeitsvertrag.gueltig_bis < this.$moment().format('YYYY-MM-DD')) return;
      this.$$update('arbeitsvertraege', this.arbeitsvertrag, 'Arbeitsvertrag beendet').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('update-arbeitsvertrag', response);
      });
    },
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
