
import kalenderdata from '~/mixins/kalender/kalenderdata';
import termine from '~/mixins/models/termine';
export default {
  mixins: [kalenderdata, termine],
  props: ['termin'],
  data() {
    return {
      terminDetails: this.termin
    }
  },
  computed: {},
  watch: {},
  methods: {
    checkUpdate(termin) {
      if (termin.id == this.terminDetails.id) {
        this.terminDetails = termin;
        this.$emit('update:termin', termin);
        this.$emit('refresh');
      }
    }
  },
  mounted() {
    this.$nuxt.$on('update::termin', this.checkUpdate);
  },
  beforeDestroy() {
    this.$nuxt.$off('update::termin', this.checkUpdate);
  }

}
