
export default {
  mixins: [],
  props: {'kontakt': null, 'betreffender': null, startcall: {default: true}, sms: {default: false}},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},

}
