
// import formular from "@/mixins/formular";

export default {
  // mixins: [formular],
  props: ['titel', 'mitarbeiter_id'],
  data() {
    return {
      formulartitel: this.titel,
      krankmeldung: null,
      bisEdit: false,
      patienten: null,
    }
  },
  computed: {
    zeitraumValid() {
      return this.$moment(this.krankmeldung.von).isSameOrAfter(this.$moment().subtract(7, 'weeks')) && this.$moment(this.krankmeldung.von).isSameOrBefore(this.krankmeldung.bis);
    },
    empty() {
      return {
        mitarbeiter_id: this.mitarbeiter_id,
        von: this.$moment().format('YYYY-MM-DD'),
        bis: this.$moment().format('YYYY-MM-DD'),
        kindkrank: false,
      };
    }
  },
  methods: {
    updateKannVertretenWerden(patient) {
      let send = {id: patient.id, kann_vertreten_werden: patient.kann_vertreten_werden};
      this.$$update('patienten', send, 'Patientenpräferenz aktualisiert', '/api/patienten/'+patient.id+'/details')
    },
    speichern() {
      if (!this.zeitraumValid) return null;
      this.$$store('krankmeldungen', this.krankmeldung, "Erfolgreich gespeichert").then((response) => {
        this.$bvModal.hide('krankmeldung-formular')
        this.$nuxt.$emit('store-krankmeldung', response);
      });
    },
    updateBis() {
      if (this.bisEdit) return;
      this.$nextTick(() => {
        this.krankmeldung.bis = this.krankmeldung.von;
      })
    },
    show() {
      this.krankmeldung = this.$copy(this.empty);
      this.$nextTick(() => {
        this.abrufenPatienten();
      })
    },
    abrufenPatienten() {
      this.$$abrufen('krankmeldungenPruefen', null, '/api/krankmeldungen/pruefen?von='+this.krankmeldung.von+'&bis='+this.krankmeldung.bis+'&mitarbeiter_id='+this.mitarbeiter_id).then(data => {
          this.patienten = data.patienten;
        });
    }
  },
  mounted() {}
}
