
export default {
  mixins: [],
  props: {
    multiple: Boolean,
    'value': '',
    'placeholder': {
      default: 'Standort wählen'
    },
    params: {},
  },
  data() {
    return {
      standort: null,
    }
  },
  computed: {
    metadata() {
      let metadata = {};
      let filter = [];
      if (this.params) {
        if (this.params.fachbereich !== null) filter.push({where: ['fachbereich','LIKE', '%'+this.params.fachbereich+'%']});
      }
      metadata.filter = filter;
      return metadata;
    }
  },
  watch: {
    value(neu) {
      this.fill();
    },
    params(neu, alt) {
      if(neu && (!alt || neu.fachbereich != alt.fachbereich)) {
        this.$$fill('standorte', this.metadata)
        this.abrufen();
      }
    }
  },
  methods: {
    fill() {
      this.standort = this.value;
    },
    abrufen() {
      this.$$abrufen('standorte');
    },
    emit() {
      this.$nextTick(() => {
        this.$emit('input', this.standort);
      })
    },
  },
  mounted() {
    this.fill();
    this.$$fill('standorte', this.metadata);
    this.abrufen();
  },

}
