
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'einrichtung-formular',
        event: ['create::einrichtung', 'edit::einrichtung'],
        titel: null,
        modelname: 'Einrichtung',
        model: 'einrichtungen'
      },
      emptyKontaktperson: {
        kontakt: null,
        position: '',
      }
    }
  },
  computed: {
    einrichtungsarten() {
      return ['Kinderbetreuung', 'Schule', 'Pflegeeinrichtung', 'Behinderteneinrichtung', 'WG', 'Sonstige Einrichtung'];
    }
  },
  watch: {},
  methods: {
    fillModel(model = {}) {
      let empty = {bewohnt: false, heim: false, klassifikation: '', kontakt: null, ansprechpersonen: []};
      this.model = this.$fillMissing(model, empty);
    },
    ansprechpersonHinzufuegen() {
      this.model.ansprechpersonen.push(this.$copy(this.emptyKontaktperson));
    },
    beforeClose() {
      this.$nuxt.$emit('refresh::kontakt', this.model.kontakt);
    }
  },

}
