
export default {
  mixins: [],
  props: {
    kontakt: null,
    loadSize: {default: 200},
    size: {default: null},
    variant: {default: 'secondary'},
  },
  data() {
    return {
      vergroesserbar: true,
    }
  },
  computed: {},
  watch: {},
  methods: {
    bildgross() {
      if (!this.kontakt.image || !this.vergroesserbar) return;
      this.$nuxt.$emit('display-avatar', this.kontakt.image);
    }
  },
  mounted() {},

}
