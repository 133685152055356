
export default {
  mixins: [],
  props: ['datei'],
  data() {
    return {
      dateiAbgerufen: null,
      patient: null,
      fachbereich: null,
      sendenButton: "Verschieben",
    }
  },
  computed: {},
  watch: {},
  methods: {
    start() {
      this.dateiAbgerufen = null;
      this.$$find('dateien', this.datei.id).then(el => {
        this.dateiAbgerufen = el;
      })
    },
    addZuordnung() {
      this.dateiAbgerufen.models.push({model_type: 'App\\Models\\Verordnung', model: null, model_id: null});
    },
    update() {
      this.dateiAbgerufen.models = this.dateiAbgerufen.models.filter(el => (el && el.model));
      if (!this.dateiAbgerufen.models.length) return;
      this.$$update('dateien', this.dateiAbgerufen, 'Zuordnungen aktualisiert').then(response => {
        this.$nuxt.$emit('update-datei', response);
      });
    },
    updateTag(event) {
      this.dateiAbgerufen.tags = [event];
    }
  },
  mounted() {
    this.start();
  },

}
