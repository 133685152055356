
export default {
  mixins: [],
  props: ['datum', 'mitarbeiter'],
  data() {
    return {
      tagesabschluss: null,
    }
  },
  computed: {},
  watch: {
    datum() {
      this.tagesabschluss = null;
      this.abrufen();
    }
  },
  methods: {
    abrufen() {
      this.$$find('tagesabschluesse', this.datum+'/'+this.mitarbeiter.id).then(response => this.tagesabschluss = response, error => {
        this.tagesabschluss = false
        this.$$leeren('tagesabschluesse');
      });
    },
    tagAbschliessen() {
      this.$confirm('Soll der Tag wirklich abgeschlossen werden? Danach kann keine Zeit mehr gebucht oder bearbeitet werden.').then(response => {
        if (response) {
          this.$$store('tagesabschluesse', {datum: this.datum, mitarbeiter_id: this.mitarbeiter.id}).then(response => {
            this.abrufen();
            this.$emit('refresh');
          });
        }
      })
    },
    tagesabschlussRueckgaengig() {
      this.$loeschen('Soll der Tagesabschluss wirklich zurückgesetzt werden?').then(response => {
        if (response) {
          this.$$delete('tagesabschluesse', this.tagesabschluss.id).then(response => {
            this.abrufen();
            this.$emit('refresh');
          });
        }
      })
    },
  },
  mounted() {
    this.abrufen();
  },

}
