export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {
    kalenderUhrzeiten() {
      const uhrzeiten = [];
      if (!this.kalender.meta.von) return uhrzeiten;
      let uhrzeit = this.$moment('1970/01/01 '+this.kalender.meta.von+':00:00', 'YYYY/MM/DD HH:mm:ss');
      let counter = 1;

      this.kalender.meta.vonTimestamp = uhrzeit.unix();

      for (let index = this.kalender.meta.von; index <= this.kalender.meta.bis; index = index + this.kalender.meta.uhrzeitenAbstand) {
        const uhrzeitobj = {
          // Moment.JS Object
          uhrzeit: uhrzeit.clone(),
          // Anzeigeuhrzeit
          displaytime: uhrzeit.format('HH:mm'),
          // Minuten seit heute 00:00:00
          tagesMinuten: index * 60,
          // Minuten seit Von
          nullMinuten: (index*60 - this.kalender.meta.von*60),
          stunde: uhrzeit.format('H'),
          minuten: uhrzeit.format('m'),
          mathematisch: index,
          // Durchgezählt
          counter: counter,
        };
        uhrzeiten.push(uhrzeitobj);
        uhrzeit = uhrzeit.add(this.kalender.meta.uhrzeitenAbstand, 'hours');
        counter++;
      }
      this.kalender.meta.bisTimestamp = uhrzeit.unix();
      return uhrzeiten;
    },
  },
  watch: {},
  methods: {
    aktuelleUhrzeitErneuern() {
      this.kalender.uhrzeit.aktuelleUhrzeitMoment = this.$moment().year(1970).dayOfYear(1);
      this.kalender.uhrzeit.erneuernInterval = setInterval(() => {
        this.aktuelleUhrzeitErneuern()
      }, 300000);
    },
    kalenderUhrzeitenErneuern() {
      this.kalender.uhrzeiten = this.kalenderUhrzeiten;
      this.kalenderHoeheSetzen();
    },
    setMinUhrzeit(uhrzeit) {
      if (uhrzeit < this.kalender.meta.von) {
        this.kalender.meta.von = uhrzeit;
        this.kalenderUhrzeitenErneuern();
      }
    },
    setMaxUhrzeit(uhrzeit) {
      if (uhrzeit > this.kalender.meta.bis) {
        this.kalender.meta.bis = uhrzeit;
        this.kalenderUhrzeitenErneuern();
      }
    },
    resetMinMax() {
      this.kalender.meta.von = 10;
      this.kalender.meta.bis = 14;
      this.kalenderUhrzeitenErneuern();
    }
  },
  mounted() {
    this.aktuelleUhrzeitErneuern();
    this.kalenderUhrzeitenErneuern();
  },
  beforeDestroy() {
    clearInterval(this.kalender.uhrzeit.erneuernInterval);
  }
}
