
export default {
  props: {
    type: {
      default: 'primary'
    }
  },
  data() {
    return {
      displayTooltip: false,
      id: 'tooltip'+this._uid,
      einblenden: false,
    }
  },
  mounted() {}
}
