
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'stoerungsbilderformular',
      modaltitel: 'Störungsbilder',
      therapeut: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(model) {
      this.therapeut = null;
      if (model) {
        this.$$fill('mitarbeiter', {with: ['stoerungsbilder']});
        this.$$find('mitarbeiter', model).then(response => this.therapeut = response);
        this.$bvModal.show(this.modalid);
      }
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
  },
  mounted() {
    this.$nuxt.$on('edit-mitarbeiter-stoerungsbilder', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('edit-mitarbeiter-stoerungsbilder', this.start);
  },
}
