
import dateienParallelAnzeigen from '~/mixins/dateienParallelAnzeigen';
export default {
  mixins: [dateienParallelAnzeigen],
  props: ['model_id', 'model_type', 'dateiversion_id', 'datei', 'spaltenklasse'],
  data() {
    return {}
  },
  computed: {
    spalteLinksClass() {
      if (this.spaltenklasse) return this.spaltenklasse;
      return 'col-4';
    }
  },
  watch: {},
  methods: {},
  mounted() {
    if (this.model_id && this.model_type) this.abrufen(this.model_type, this.model_id);
    else if (this.datei) this.auswaehlen(this.datei);
  },
}
