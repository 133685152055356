
export default {
  mixins: [],
  props: ['krankmeldung'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    switchStatus(krankmeldung, status) {
      krankmeldung.status = status;
      this.$$update('krankmeldungen', krankmeldung, 'Status der Krankmeldung aktualisiert').then(() => {
        this.$emit('update');
      })
    },
    loeschen(krankmeldung) {
      this.$loeschen('Bist du dir sicher, dass die Krankmeldung gelöscht werden soll?').then(response => {
        if (!response) return;
        this.$$delete('krankmeldungen', krankmeldung.id).then(response => {
          this.$emit('update');
        })
      })
    },
  },
  mounted() {},

}
