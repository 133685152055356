export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    bearbeiten() {
      if (this.termin.termintyp == 8 || this.termin.termintyp == 9) this.$nuxt.$emit('edit::freier-slot', {model: this.termin, titel: 'Freien Slot bearbeiten'});
      else this.$nuxt.$emit('edit-kalender-termin', this.termin);
      this.showDetails = false;
    },
    absagen(termin) {
      this.$nuxt.$emit('kalender-termin-absage', termin);
      this.showDetails = false;
    },
    absageZurueckziehen(termin) {
      let terminCopy = this.$copy(termin);
      this.$$delete('termine', terminCopy.id+'/absage').then(el => {
        this.$nuxt.$emit('store-termin', terminCopy)
      })
      this.showDetails = false;
    },
    addFallListener() {
      this.$emit('addFalllistener');
    },
  },
  mounted() {},

}
