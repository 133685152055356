import { render, staticRenderFns } from "./VerfuegbarkeitFormular.vue?vue&type=template&id=22d95994"
import script from "./VerfuegbarkeitFormular.vue?vue&type=script&lang=js"
export * from "./VerfuegbarkeitFormular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalFormulareAuswahlWochentag: require('/builds/logo-yes/portal2022/frontend/components/global/formulare/auswahl/Wochentag.vue').default,GlobalFormulareAuswahlStandort: require('/builds/logo-yes/portal2022/frontend/components/global/formulare/auswahl/Standort.vue').default})
