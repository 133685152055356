
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'verordnung-pruefen-modal',
      event: 'display-verordnung-pruefen',
      verordnung: null,
      ausgewaehlteDatei: null,
      error: null,
    }
  },
  computed: {
    vorschauUrl() {
      return '/api/dateiversionen/'+this.ausgewaehlteDatei.aktuelle_version.id+'/download';
    }
  },
  watch: {},
  methods: {
    start(verordnung) {
      this.ausgewaehlteDatei = null;
      this.verordnung = null;
      this.abrufen(verordnung);
      this.$bvModal.show(this.modalid);
    },
    abrufen(verordnung) {
      this.$$find('verordnungen', verordnung.id).then(response => {
        if (response.leitsymptomatik == null) {
          response.leitsymptomatik = {
            "a": false, "b": false, "c": false, "individuell": false,
          }
        }
        if (response.heilmittel == null) {
          response.heilmittel = [{
              "heilmittel": "", "menge": 0 , "dauer": 0,
            }];
        }
        this.verordnung = response;
      });
      this.$$abrufen('dateien', null, '/api/modeldateien?model_type=App\\Models\\Verordnung&model_id='+verordnung.id);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
    ansehen(datei) {
      this.url = '/api/dateiversionen/'+datei.aktuelle_version.id+'/download';
      this.$nuxt.$emit('view-pdf', {titel: datei.aktuelle_version.dateiname, url: this.url});
    },
  },
  mounted() {
    this.$$fill('verordnungen', {with: ['patient', 'arzt', 'krankenkasse', 'icdcode', 'icdcode_2', 'diagnosegruppe', 'heilmittel', 'ergaenzendes_heilmittel'], append: ['vorgaenger']});

    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },
}
