
export default {
  mixins: [],
  props: ['ansprechperson', 'kontaktnummer', 'index'],
  data() {
    return {
      auswahlAnzeigen: true,
    }
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    if (this.ansprechperson.id) {
      this.auswahlAnzeigen = false;
    }
  },

}
