
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'kommentare-modal',
        event: 'display-kommentare',
        titel: null,
        modelname: 'Kommentare',
        model: 'kommentare'
      },
      model_id: null,
      model_type: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.model_id = params.model_id;
      this.model_type = params.model_type;
      this.$bvModal.show(this.modalid);
    }
  },

}
