export default {
  mixins: [],
  props: ['spalteAbgerufen', 'datum', 'model_type', 'model_id'],
  data() {
    return {
      abwesendheiten: {
        urlaube: [],
        krankheiten: [],
        geburtstage: [],
      },
    }
  },
  computed: {},
  watch: {
    spalteAbgerufen(neu) {
      this.abrufen();
    },
    datum(neu, alt) {
      if (neu != alt) {
        this.$$abbrechen('kalender-abwesendheiten');
        this.abwesendheiten =  {urlaube: [],krankheiten: [],geburtstage: []};
        this.abrufen();
      }
    }
  },
  methods: {
    abrufen() {
      if (!this.spalteAbgerufen) return;
      this.$emit('abwesend', false);
      let url = '/api/kalender/abwesendheiten/'+this.datum;
      if (this.model_type.includes('Mitarbeiter')) url += '/'+this.model_id;
      this.$$abrufen('kalender-abwesendheiten', null, url).then(response => {
        this.abwesendheiten.urlaube = response.urlaube;
        this.abwesendheiten.krankheiten = response.krankheiten;
        this.abwesendheiten.geburtstage = response.geburtstage;
      })
    },
  },
  mounted() {},
}
