
export default {
  mixins: [],
  props: ['termin', 'heilmittel', 'patientenzahl'],
  data() {
    return {
      patientenkey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    removePatient(index) {
      this.termin.patienten.splice(index, 1)
      this.patientenkey++;
    }
  },
  mounted() {},

}
