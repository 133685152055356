
export default {
  methods: {
    checkVersionApi() {
      if (process.env.STAGE == 'demo') return;
      let stagingUrl = 'https://staging.theralink.de/api/version.php';
      let liveUrl = 'https://mein.theralink.de/api/version.php';
      let url = (this.$staging) ? stagingUrl : liveUrl;
      this.$axios.get(url).then(response => {
        if (response.data == `<?php
        $data = readfile('../../metadata.json') . " #". readfile('../../buildnr.json');
?>`) {
  // Keine Daten ausgeliefert, nur Lokal oder so
        console.log('Versionsabruf nicht möglich!');
        return;
        }
        else if (response.data.buildMajor > process.env.buildMajor ||
            response.data.buildMinor > process.env.buildMinor ||
            response.data.buildRevision > process.env.buildRevision || response.data.buildnr > process.env.buildnumber) {

              this.$bvModal.msgBoxConfirm("Es gibt eine neuere Version des Portals. Bitte speichere woran du gerade arbeitest und lade dann neu!", {
                size: 'sm',
                buttonSize: 'sm',
                cancelTitle: 'Später Neuladen',
                okTitle: 'Jetzt Neuladen',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }).then(confirm => {
                if (confirm) {
                  location.reload(true);
                }
                else {
                  setTimeout(this.checkVersionApi, 300000);
                }
              })
        }
        else {
          setTimeout(this.checkVersionApi, 300000);
        }
      })
    }
  },
  mounted() {
    if (!this.$dev) this.checkVersionApi();
  }
}
