import topabstand from "./topabstand";
import hoehe from "./hoehe";
import uhrzeit from "./uhrzeit";
export default {
  mixins: [uhrzeit, hoehe, topabstand],
  data() {
    return {
      kalender: null,
    }
  },
  computed: {},
  methods: {
    reset() {
      this.kalender = {
        id: null,
        display: {
          tagesabschluss: true,
          abwesendheiten: true,
          presets: true,
          kopfzeile: true,
        },
        meta: {
          vonTimestamp: 0,
          von: 10,
          bisTimestamp: 0,
          bis: 14,
          uhrzeitenAbstand: 0.25,
          hoehe: 0,
          loading: false,
        },
        spalten: [],
        topabstand: {
          meta: null,
        },
        uhrzeit: {
          erneuernInterval: null,
          aktuelleUhrzeitMoment: null,
          aktuelleUhrzeitUnix: 0,
        },
        uhrzeiten: [],
        leereSpalte: {
            titel: '',
            filter: {
              datum: null,
              mitarbeiter_id: [],
              termintyp: [],
              fachbereich: [],
              raum_id: [],
              standort_id: [],
              materialien: [],
              fahrzeuge: [],
              patient_id: [],
              hausbesuch: null,
              teletherapie: null,
              abgesagt: null,
              stattgefunden: null,
              trashed: false,
              arbeitszeiten: false,
              termine: true,
            },
            key: 0,
            show: false,
            model_type: null,
            model_id: null,
            model: null,
        },
      };
    }
  },
  beforeMount() {
    this.reset();
  }
}
