
export default {
  mixins: [],
  props: ['verordnung', 'addTherapie'],
  data() {
    return {
    }
  },
  computed: {
    summeTherapieeinheiten() {
      if (this.addTherapie) return this.verordnung.summe_therapiestunden + this.addTherapie;
      else return this.verordnung.summe_therapiestunden;
    }
  },
  watch: {},
  methods: {

  },
  mounted() {},

}
