export default {
  data() {
    return {}
  },
  methods: {
    joinFaelleSocket() {
      let channelname = 'MitarbeiterFaelle.'+this.$model.id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        if (event == 'FallAktualisiert' && data.user != this.$auth.user.id) this.$nuxt.$emit('socket.FallAktualisiert', data.fall);
      })
    },
    joinAlleFaelleSocket() {
      if (!this.$can('Telefonie Dashboard')) return;
      let channelname = 'FallAktualisiert';
      this.$echo.private(channelname).listenToAll((event, data) => {
        // Nur wenn jemand anderes das gemacht hat, sonst direkt ohne Socket
        if (data.user == this.$auth.user.id) return;
        if (event == 'FallAktualisiert') this.$nuxt.$emit('socket.FallAktualisiertAlle', data.fall);
      })
    },
    joinLaufendeAnrufeSocket() {
      if (!this.$can('Placetel Agent')) return;
      let channelname = 'anrufe.'+this.$model.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        if (event == 'LaufendeAnrufe') this.$nuxt.$emit('socket.LaufendeAnrufe', data.anrufe);
      })
    },
  },
  mounted() {
    this.joinFaelleSocket();
    this.joinAlleFaelleSocket();
    this.joinLaufendeAnrufeSocket();
  }
}
