
import icons from 'bootstrap-icons/bootstrap-icons.svg';
export default {
  props: {
    name: {},
    width: {
      default: 20
    },
    height: {
      default: 20
    },
    size: {
      default: 'normal',
    },
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  },
  data() {
    return {
      icons: icons
    }
  }
}
