
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'patientverfuegbarkeit',
      modaltitel: 'Patientverfügbarkeit',
      event: 'display-patient-verfuegbarkeiten',
      patient_id: null,
      patient: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.patient_id = params;
      this.abrufen();
      this.$bvModal.show(this.modalid);
    },
    async abrufen() {
      this.$$find('patienten', this.patient_id).then(patient => {
        this.patient = patient;
      })
    },
    changePatient() {
      this.patient = null;
    },
  },
  mounted() {
    this.$$fill('patienten', {with: ['verfuegbarkeiten']});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },
}
