import { render, staticRenderFns } from "./Unterschriftpad.vue?vue&type=template&id=f10e741a"
import script from "./Unterschriftpad.vue?vue&type=script&lang=js"
export * from "./Unterschriftpad.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports