
export default {
  mixins: [],
  props: ['mitarbeiter_id', 'arbeitsvertraege', 'verwaltung'],
  data() {
    return {}
  },
  computed: {
    vertrage() {
      if (this.arbeitsvertraege) return this.arbeitsvertraege;
      return this.$$data('arbeitsvertraege');
    }
  },
  watch: {},
  methods: {},
  mounted() {
    if (!this.arbeitsvertraege)  {
      this.$$fill('arbeitsvertraege', {filter: [{where: ['mitarbeiter_id', this.mitarbeiter_id]}]});
      this.$$abrufen('arbeitsvertraege');
    }
  },

}
