export default {
  mixins: [],
  props: ['patient_id'],
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  methods: {
    datenAbrufParams(status, perPage = 10) {
      let orderBy = {'order': 'desc'};
      let filter = [];
      let withModels = [];

      // 0 Meine Aufgaben
      if (status == 0) {
        filter = [{whereHasId: ['bearbeiter', this.$model.id]},{offen: []}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 1 Verpasste Anrufe
      if (status == 1) {
        filter = [{where: ['status', '4']},{offen: []}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 2 Terminaufgaben
      if (status == 2) {
        // TODO: Und hat Termin
        filter = [{offen: []}, {where: ['termin_id', '!=', null]}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 3 Sonstige Aufgaben
      if (status == 3) {
        filter = [{where: ['status', '0']}, {where: ['termin_id', null]}];
        withModels = ['letzteAenderungModel', 'betreffender'];
      }
      // 4 Warten auf interne Rückmeldung
      if (status == 4) {
        filter = [{where: ['status', '3']}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 5 Warten auf externe Rückmeldung
      if (status == 5) {
        filter = [{where: ['status', 1]}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      // 6 Erledigt
      if (status == 6) {
        filter = [{geschlossen: []}];
        withModels = ['letzteAenderungModel', 'betreffender', 'termin'];
      }
      if (this.patient_id) filter.push({where: ['betreffender_id', this.patient_id]});
      return {filter: filter, with: withModels, perPage: perPage, orderBy: orderBy};
    }
  },
  mounted() {},
}
