
import fahrten from '~/mixins/fahrten';
export default {
  mixins: [fahrten],
  props: [],
  data() {
    return {
      modalid: 'sonstige-arbeitsstunde-formular',
      modaltitel: 'Weitere Arbeitszeit buchen',
      sendenButton: "Arbeitszeit speichern",
      sonstiges: null,
      invalid: false,
      termintyp: 'orga',
      formkey: 0,
      mitarbeiter: null,
      terminVorlage: null,
    }
  },
  computed: {
    mitarbeiterOptions() {
      if (this.terminVorlage && this.terminVorlage.mitarbeiter) {
        let mitarbeiter = this.terminVorlage.mitarbeiter.map(el => { return {id: el.id, name: el.kontakt.name} });
        if (mitarbeiter.length == 1) {
          this.sonstiges.mitarbeiter = mitarbeiter[0];
          this.sonstiges.mitarbeiter_id = this.sonstiges.mitarbeiter.id;
        }
        return mitarbeiter;
      }
      return null;
    },
    mitarbeiterModel() {
      if (this.mitarbeiter) return this.mitarbeiter;
      return this.$model;
    },
    mitarbeiterModelId() {
      if (this.mitarbeiter) return this.mitarbeiter.id;
      return this.$model.id;
    },
    kannOrgaErfassen() {
      if (this.$can('Orgazeit erfassen')) return true;
      if (this.mitarbeiterModel.ist_therapeut === false) return false;
      return !this.mitarbeiterModel.orga_pauschal;
    }
  },
  watch: {},
  methods: {
    start(sonstiges) {
      if (sonstiges.termin) this.terminVorlage = sonstiges.termin
      if (sonstiges.mitarbeiter_id) {
        this.$$fill('mitarbeiter', {append: ['orga_pauschal']});
        this.$$find('mitarbeiter', sonstiges.mitarbeiter_id).then(mitarbeiter => {
          this.mitarbeiter = mitarbeiter
        });
      }
      this.termintyp = 'orga';
      if (sonstiges.termintyp) {
        if (sonstiges.termintyp == 2) this.termintyp = 'sonstiges';
        if (sonstiges.termintyp == 3) this.termintyp = 'projekt';
      }
      else if (sonstiges.projekt_id) this.termintyp = 'projekt';
      else if (sonstiges.model_type && sonstiges.model_type.includes('Sonstiges')) this.termintyp = 'sonstiges';
      else if (!this.kannOrgaErfassen) this.termintyp = 'projekt';


      this.empty();

      this.sonstiges = this.$fillMissing(sonstiges, this.sonstiges);
      // if (!this.sonstiges.anfahrt && !this.sonstiges.rueckfahrt)
      this.resyncAnfahrt(this.sonstiges);
      this.$bvModal.show(this.modalid);
    },
    empty() {
      this.sonstiges = {
        taetigkeit: '',
        kommentar: '',
        von: null,
        bis: null,
        dauer: null,
        termin_id: null,
        projekt: null,
        patienten: null,
        mitarbeiter: null,
        mitarbeiter_id: null,
      };
    },
    setTermintyp(typ) {
      this.termintyp = typ;
      this.$nextTick(() => {
        this.formkey++;
      });
    },
    updatePatienten(patienten) {
      this.sonstiges.patienten = patienten.map(patient => {
        if (!patient.pivot) patient.pivot = {verordnung: null};
        return patient;
      })
    },
    erstellen() {
      if (this.invalid || (this.termintyp == 'projekt' && !this.sonstiges.projekt)) return;
      if (!this.sonstiges.von || !this.sonstiges.bis || this.sonstiges.von >= this.sonstiges.bis) {
        return;
      }
      if (this.termintyp != 'projekt') this.sonstiges.projekt = null;

      if (this.sonstiges.id) this.update()
      else this.store();
    },
    update() {
      if (this.mitarbeiterModel) this.sonstiges.mitarbeiter_id = this.mitarbeiterModel.id;
      if (this.termintyp == 'orga') {
        this.$$update('orgas', this.sonstiges, 'Orga gespeichert').then(response => {
            this.$bvModal.hide(this.modalid);
            this.$nuxt.$emit('store-sonstiges', response);
            this.sendEvent(response);
        });
      }
      else {
        this.$$update('sonstige', this.sonstiges, 'Arbeit gespeichert').then(response => {
            this.$bvModal.hide(this.modalid);
            this.$nuxt.$emit('store-sonstiges', response);
            this.sendEvent(response);
        });
      }

    },
    store() {
      if (!this.sonstiges.mitarbeiter) this.sonstiges.mitarbeiter_id = this.mitarbeiterModel.id;
      if (this.termintyp == 'orga') {
        this.$$store('orgas', this.sonstiges, 'Orga gespeichert').then(response => {
          this.$bvModal.hide(this.modalid);
          this.$nuxt.$emit('store-sonstiges', response);
          this.sendEvent(response);
        });
      }
      else {
        this.$$store('sonstige', this.sonstiges, 'Arbeitszeit gespeichert').then(response => {
          this.$bvModal.hide(this.modalid);
          this.$nuxt.$emit('store-sonstiges', response);
          this.sendEvent(response);
        });
      }
    },
    inputMitarbeiter(event) {
      if (event && event.id) this.sonstiges.mitarbeiter_id = event.id
    },
    sendEvent(stunde) {
      this.$nuxt.$emit('refresh-termine-'+this.$moment(stunde.von).format('YYYY-MM-DD'));
    }
  },
  mounted() {
    this.$nuxt.$on('create-sonstige-arbeitsstunde', this.start);
    this.$nuxt.$on('edit-sonstige-arbeitsstunde', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-sonstige-arbeitsstunde', this.start);
    this.$nuxt.$off('edit-sonstige-arbeitsstunde', this.start);
  },

}
