
import termin from '~/mixins/kalender/termin';
import topabstand from '~/mixins/kalender/topabstand';
export default {
  mixins: [termin, topabstand],
  props: ['arbeitsstunde', 'kalender', 'spaltenData'],
  data() {
    return {
      showTooltip: false,
      arbeitsstundeDetails: this.arbeitsstunde,
    }
  },
  computed: {
    terminHeight() {
      return (this.topAbstand(this.arbeitsstunde.bis) - this.topAbstand(this.arbeitsstunde.von))
    }
  },
  methods: {
    clickArbeitsstunde() {
      if (this.arbeitsstunde.trigger_type == 'addbox') {
        let neu = this.$copy(this.arbeitsstunde);
        neu.id = null;
        this.$nuxt.$emit('create-sonstige-arbeitsstunde', neu);
        return;
      }
      this.showTooltip = !this.showTooltip;
      this.$nuxt.$emit('bv::hide::tooltip')
    }
  },
  mounted() {}
}
