
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'scanmodel',
      modaltitel: 'Dateien ablegen',
      event: 'scan',
      scan: null,
      model_id: null,
      model_type: null,
      model: null,
      tag_id: null,
      cloudtag: null,
      dateiAuswahl: null,
      fotos: [],
    }
  },
  computed: {
    vorschauUrl() {
      if (!this.cloudtag || !this.cloudtag.vorschau.length) return null;
      let datei = this.cloudtag.vorschau[0];
      return '/api/dateiversionen/'+datei.aktuelle_version.id+'/download';
    },
  },
  watch: {},
  methods: {
    start(params) {
      this.model_id = params.model_id;
      this.model_type = params.model_type;
      this.model = params.model;
      this.tag_id = (params.tag_id ?? null);
      this.fotos = [];
      this.dateiAuswahl = null;
      this.scan = null;

      if (this.tag_id) {
        this.$$find('cloudtags', this.tag_id).then(response => {
          this.cloudtag = response;
        })
      }
      else this.cloudtag = null;
      this.$bvModal.show(this.modalid);
    },

    uploadScan(fotos) {
      let base64 = fotos.map(el => {
        el = el.replace('data:image/jpeg;base64,', '')
        return el;
      });
      this.$$upload('modeldateien', {id: this.model_id}, null, {base64: base64, tag_id: this.tag_id, filename: 'Scan.jpg'}, 'Scan erfolgreich abgelegt', '/api/modeldateien?model_type='+this.model_type+'&model_id='+this.model_id).then(response => {
        this.$nuxt.$emit('store-scan', response);
        this.$bvModal.hide(this.modalid);
      })
    },
    changeFile(file) {
      this.dateiAuswahl = file;
    },
    dokumentHochladen() {
      this.$$upload('modeldateien', {id: this.model_id}, this.dateiAuswahl, {tag_id: this.tag_id}, 'Datei erfolgreich abgelegt', '/api/modeldateien?model_type='+this.model_type+'&model_id='+this.model_id).then((response) => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('store-scan', response);
      });
    },
    updateTag(event) {
      this.cloudtag = event;
      if (event) this.tag_id = event.id;
      else this.tag_id = null;
    },


  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
