
export default {
  mixins: [],
  props: ['value', 'fachbereich', 'verordnungstyp', 'disabled', 'rules'],
  data() {
    return {
      heilmittel: this.value,
    }
  },
  methods: {
    update() {
      this.$emit('input', this.heilmittel);
      this.$emit('change', this.heilmittel);
    },
    addLine() {
      if (typeof(this.heilmittel) != 'object') this.heilmittel = [];
      this.heilmittel.push(null);
    },
    removeLine(index) {
      this.heilmittel.splice(index, 1);
    }
  },
  mounted() {
    if (this.heilmittel == null) this.heilmittel = [];
    else if (typeof(this.heilmittel) != 'object') this.heilmittel = [];
    if (this.heilmittel.length == 0) {
      this.addLine();
    }
    this.update();
  },
}
