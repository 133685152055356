import { render, staticRenderFns } from "./index.vue?vue&type=template&id=630e3531"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternMitarbeiterFreizeitausgleicheStatusAendern: require('/builds/logo-yes/portal2022/frontend/components/intern/mitarbeiter/freizeitausgleiche/StatusAendern.vue').default,InternMitarbeiterUrlaubeStatus: require('/builds/logo-yes/portal2022/frontend/components/intern/mitarbeiter/urlaube/Status.vue').default,InternProfilFreizeitausgleichFormular: require('/builds/logo-yes/portal2022/frontend/components/intern/profil/FreizeitausgleichFormular.vue').default})
