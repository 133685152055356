
export default {
  props: ['kommentar', 'showDate', 'id', 'type', 'antwortenDeaktivieren'],
  data() {
    return {
      antworten: false,
      thisKommentar: {},
      loading: false,
    }
  },
  methods: {
    antwortAbsenden(content) {
      if (this.loading) return;
      let payload = {
        id: 'neu',
        model_id: this.id,
        model_type: this.type,
        text: content.text,
        titel: content.titel,
        parent_id: this.kommentar.id,
        user_id: this.$auth.user.model_id
      };
      this.loading = true;
      this.$axios.post('/api/kommentare/'+this.type+'/'+this.id, payload).then(response => {
        this.loading = false;
        this.antworten = false;
        if (!this.thisKommentar.kinder) this.thisKommentar.kinder = [];
        this.thisKommentar.kinder.push(response.data);
      });
    }
  },
  mounted() {
    this.thisKommentar = this.kommentar;
  },

}
