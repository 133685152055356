
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arbeitsstunde-formular',
      modaltitel: 'Arbeitszeit',
      event: 'arbeitsstunde-erstellen',
      arbeitsstunde: null,
      sendenButton: "Arbeitszeit speichern",
      sonstiges: null,
      invalid: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(arbeitsstunde) {
      this.empty();
      this.arbeitsstunde.von = arbeitsstunde.von;
      this.arbeitsstunde.dauer = arbeitsstunde.dauer;
      this.$bvModal.show(this.modalid);
    },
    empty() {
      this.sonstiges = {
        taetigkeit: '',
        kommentar: ''
      };
      this.arbeitsstunde = {
        trigger_id: null,
        trigger_type: null,
        von: null,
        bis: null,
        dauer: null,
      };
    },
    erstellen() {
      if (this.invalid) {
        this.showError = true;
        return;
      }
      if (!this.arbeitsstunde.von || !this.arbeitsstunde.bis || this.arbeitsstunde.von >= this.arbeitsstunde.bis) {
        return;
      }
      let send = this.arbeitsstunde;
      send.sonstiges = this.sonstiges;
      this.$$store('arbeitsstunden', send, 'Arbeitsstunde erstellt').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('store-arbeitsstunde', response);
      });

    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
