export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    emptyStunde(vorlage = null) {
      if (!vorlage) vorlage = this.termin;
      let therapiestunden = [];
      let hausbesuchCounter = 0;
      _.forEach(vorlage.patienten, patient => {
        let therapiestunde = this.emptyTherapiestunde(patient);
        // Prüfen ob im Zweifel alle Verordnungen Hausbesuch erlauben
        if (therapiestunde.hausbesuch) hausbesuchCounter++;
        therapiestunde.erstbefundung = vorlage.erstbefundung;
        therapiestunde.bedarfsdiagnostik = vorlage.bedarfsdiagnostik;
        therapiestunden.push(therapiestunde);
      });
      return {
        termin_id: vorlage.id,
        von: this.$moment(vorlage.von).format('YYYY-MM-DDTHH:mm'),
        bis: this.$moment(vorlage.bis).format('YYYY-MM-DDTHH:mm'),
        dauer: this.$moment(vorlage.bis).diff(this.$moment(vorlage.von)) / 1000 / 60,
        hausbesuchCounter: hausbesuchCounter,
        // Hausbesuch automatisch auswählen, wenn bei allen Verordnungen Hausbesuch erlaubt ist
        hausbesuch: (hausbesuchCounter == therapiestunden.length || vorlage.hausbesuch),
        anfahrt: {
          erfassen: false,
          von: this.$moment(vorlage.von).subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
          bis: vorlage.von,
          dauer: 15,
          km: null,
        },
        rueckfahrt: {
          erfassen: false,
          von: vorlage.bis,
          bis: this.$moment(vorlage.bis).add(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
          dauer: 15,
          km: null,
        },
        therapiestunden: therapiestunden
      }
    },
    emptyTherapiestunde(patient) {
      let verordnung = patient.pivot.verordnung;
      return {
        patient: patient,
        patient_id: patient.id,
        verordnung: verordnung,
        verordnung_id: verordnung.id,
        dokumentation: '',
        naechster_termin: '',
        erstbefundung: (verordnung.erstbefundung_erlaubt) ? (verordnung.erstbefundung_erlaubt && !verordnung.erstbefundung_stattgefunden) : false,
        bedarfsdiagnostik: false,
        hausbesuch: verordnung.hausbesuch,
        parallelbehandlung: false,
        therapieziele: null,
      }
    },
  },
  mounted() {},
}
