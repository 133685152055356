

export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'unterschrift',
      schreiben: null,
      url: null,
      counter: 1,
      pdfkey: 0,
      unterschreibenFertig: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(schreiben) {
      this.schreiben = schreiben;
      this.url = '/api/schreiben/'+schreiben.id+'/download';
      this.$bvModal.show(this.modalid);
    },
    clear() {
      this.counter = 1;
      this.pdfkey = 0;
      this.unterschreibenFertig = false;
      this.schreiben = null;
    },
    done(unterschrift, data) {
      unterschrift.value = data;
      this.counter++;
      if (this.counter > this.schreiben.unterschriften.length) this.unterschriftenSpeichern();
    },
    unterschriftenSpeichern() {
      let url = '/api/schreiben/'+this.schreiben.id+'/unterschrift';
      this.$$update('schreiben', this.schreiben, null, url).then(response => {
        this.pdfkey++;
        this.unterschreibenFertig = true;
        this.$nuxt.$emit('done::unterschrift')
      });
    }

  },
  mounted() {
    this.$nuxt.$on('unterschrift', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('unterschrift', this.start);
  },

}
