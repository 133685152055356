
import navleft from '~/layouts/template/navleft';
export default {
  components: {navleft},
  data() {
    return {
      menuepunkte: [
        // {'bezeichnung': 'Kontaktdaten', key: 'kontakdaten', route: '/profil', if: true},
        // {'bezeichnung': 'Passwort', key: 'passwort', route: '/profil/passwort', if: true},
        // {'bezeichnung': 'Verfügbarkeiten', key: 'verfuegbarkeiten', route: '/profil/verfuegbarkeiten', if: this.$auth.user.email_verified_at},
        {'bezeichnung': 'Vertragsdaten', key: 'vertragsdaten', route: '/profil/vertragsdaten', if: this.$auth.user.email_verified_at},
        {'bezeichnung': 'Störungsbilder', key: 'stoerungsbilder',route: '/profil/stoerungsbilder', if: this.$auth.user.email_verified_at},
        {'bezeichnung': 'Arbeitszeitkonto', key: 'arbeitszeitkonto', route: '/profil/arbeitszeitkonto', if: this.$auth.user.email_verified_at},
        // {'bezeichnung': 'Nuki', key: 'nuki',route: '/profil/nuki', if: this.$auth.user.email_verified_at},
        // {'bezeichnung': 'Placetel', key: 'placetel',route: '/profil/placetel', if: this.$auth.user.email_verified_at},
        // {'bezeichnung': '2 Faktor Authentifizierung', key: '2fa',route: '/profil/2fa', if: true},

        {'bezeichnung': 'Zugangsdaten', key: 'zugangsdaten',route: '/profil/zugangsdaten', if: true},
      ]
    }
  },
  methods: {
    logout() {
      this.$nuxt.$loading.start();
      this.$auth.logout().then(() => {
        this.$nuxt.$loading.finish();
      });
    },
  }
}
