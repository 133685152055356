
export default {
  mixins: [],
  props: ['termine', 'disabled'],
  data() {
    return {}
  },
  computed: {
    termineNachDatum() {
      if (!this.termine || !this.termine.length) return {daten: [], termine: []};
      let daten = [];
      let nachDatum = {};
      _.forEach(this.termine, (termin) => {
        let datum = this.$moment(termin.von).format('YYYY-MM-DD');
        if (!nachDatum[datum]) {
          nachDatum[datum] = [];
          daten.push(datum);
        }
        nachDatum[datum].push(termin);
      })
      return {daten: daten.sort(), termine: nachDatum};
    },
    colclass() {
      let tagezahl = Object.keys(this.termineNachDatum).length;
      let klasse = 'col-';

      if (tagezahl >= 3) return klasse+'4';
      return klasse+(12 / tagezahl);
    }
  },
  watch: {},
  methods: {
    sortieren(termine) {
      return _.sortBy(termine, ['von'])
    }
  },
  mounted() {},

}
