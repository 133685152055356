
import dateien from '~/mixins/models/dateien';
export default {
  mixins: [dateien],
  props: ['model_type', 'model_id', 'model'],
  data() {
    return {
      tagSelected: null,
      archivEinblenden: false,
    }
  },
  computed: {
    tagsUsed() {
      if (!this.$$data('dateien')) return [];
      let tags = {};
      this.$$data('dateien').map(datei => {
        datei.tags.map(tag => {
          tags[tag.id] = tag;
        });
      })
      return tags;
    },
    /**
     * Dateien Filtern, falls ein Tag ausgewählt ist
     */
    dateienFiltered() {
      if (!this.$$data('dateien')) return null;
      if (!this.tagSelected) return this.$$data('dateien');

      let dateien = this.$$data('dateien').filter(datei => {
        let dateiHatTag = datei.tags.filter(tag => {
          return tag.id == this.tagSelected;
        });
        return dateiHatTag.length;
      })
      return dateien;
    },
  },
  watch: {},
  methods: {
    abrufen() {
      let filter = [];
      if (this.archivEinblenden) filter.push({whereNotNull: ['deleted_at']});
      else filter.push({whereNull: ['deleted_at']});
      this.$$fill('dateien', {filter: filter})
      this.$$abrufen('dateien', null, '/api/modeldateien?model_type='+this.model_type+'&model_id='+this.model_id);
    },
    versenden(datei) {
      this.$nuxt.$emit('display-datei-senden', datei);
    },
    archivieren(datei) {
      this.$confirm('Datei archivieren?').then(response => {
        if (response) this.$$delete('dateien', datei.id).then(response => {
          this.abrufen();
        })
      })
    },
    archiv() {
      this.archivEinblenden = !this.archivEinblenden;
      this.$nextTick(() => {
        this.abrufen();
      })
    }
  },
  mounted() {
    this.abrufen();
    this.$nuxt.$on('update-datei', this.abrufen);
    this.$nuxt.$on('store-scan', this.abrufen);
  },
  beforeDestroy() {
    this.$nuxt.$off('update-datei', this.abrufen);
    this.$nuxt.$off('store-scan', this.abrufen);
  }

}
