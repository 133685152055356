

export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'send-schreiben',
      schreiben: null,
      url: null,
      counter: 1,
      pdfkey: 0,
      done: false,
      faxnummer: "",
      nummerkey: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(schreiben) {
      this.schreiben = schreiben;
      this.url = '/api/schreiben/'+schreiben.id+'/download';
      this.$bvModal.show(this.modalid);
    },
    clear() {
      this.counter = 1;
      this.pdfkey = 0;
      this.done = false;
      this.schreiben = null;
    },
    faxSenden() {
      let url = '/api/schreiben/'+this.schreiben.id+'/faxen/'+this.faxnummer;
      this.$$update('schreiben', this.schreiben, null, url).then(response => {
        this.done = true;
        this.$emit('store-schreiben', response);
      });
    }

  },
  mounted() {
    this.$nuxt.$on('send-schreiben', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('send-schreiben', this.start);
  },

}
