import { render, staticRenderFns } from "./PatientFormular.vue?vue&type=template&id=006fd0ff"
import script from "./PatientFormular.vue?vue&type=script&lang=js"
export * from "./PatientFormular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsPatientFormular: require('/builds/logo-yes/portal2022/frontend/components/modals/details/PatientFormular.vue').default,ModalsDetailsDateiParallelAnzeigen: require('/builds/logo-yes/portal2022/frontend/components/modals/details/DateiParallelAnzeigen.vue').default})
