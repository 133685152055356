
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiter-tagesabschluss',
      modaltitel: 'Tagesabschluss',
      event: 'display-mitarbeiter-tagesabschluss',
      datum: this.$moment().format('YYYY-MM-DD'),
      mitarbeiter: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.mitarbeiter = null;
      if (params.datum) this.datum = params.datum;
      this.$$find('mitarbeiter', params.mitarbeiter_id).then(element => {
        this.mitarbeiter = element;
      });
      this.$bvModal.show(this.modalid);
    },
  },
  mounted() {
    this.$$fill('mitarbeiter', {append: ['orga_pauschal']})
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
