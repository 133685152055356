
export default {
  mixins: [],
  data() {
    return {
      id: 'termin-absage-'+this._uid,
      showError: false,
      termin: null,
      ausfallstunde: null,
      invalid: false,
      event: null,
    }
  },
  computed: {
    therapeutErfasst() {
      if (!this.termin) return false;
      return this.termin.mitarbeiter.map(el => el.id).includes(this.$model.id);
    },
    absagewegOptions() {
      let options = {'E-Mail':'E-Mail', 'Telefon':'Telefon'}
      if (this.$moment(this.termin.von).diff(this.$moment()) < 0) options['Nicht erschienen'] = 'Nicht erschienen';
      return options;
    },
    speichernStundeEintragen() {
      if (!this.therapeutErfasst) return false;
      if (!this.ausfallstunde.kurzfristig) return false;
      if (this.ausfallstunde.berechnen || this.ausfallstunde.nicht_berechnen_begruendung) return true;
    }
  },
  methods: {
    emptyTermin() {
      this.showError = false;
      this.termin = null
      this.ausfallstunde = {
        patient_id: null,
        verordnung_id: null,
        mitarbeiter_id: null,
        begruendung: '',
        kurzfristig: null,
        berechnen: false,
        von: null,
        bis: null,
        dauer: null,
        hausbesuch: 0,
        patientabsage: null,
        absagezeit: null,
        absageweg: null,
        abgesagt_durch: null,
        nicht_berechnen_begruendung: null,
        serie: 0,
      }
    },
    therapeutAbwesend(event) {
      if (event) this.ausfallstunde.begruendung = 'Therapeut abwesend'
      else this.ausfallstunde.begruendung = "";
    },
    patientImKrankenhaus(event) {
      if (event) this.ausfallstunde.nicht_berechnen_begruendung = 'Patient im Krankenhaus'
      else this.ausfallstunde.nicht_berechnen_begruendung = "";
    },
    speichern(stunde_erfassen = false) {
      this.$refs.formular.submitEvent()
      if (!this.ausfallstunde.begruendung) return false;
      if (this.ausfallstunde.patientabsage === null) return false;
      if (this.ausfallstunde.patientabsage && this.$moment(this.termin.von).diff(this.$moment(), 'hours') <= 24 && this.ausfallstunde.kurzfristig === null) return false;
      // Keine Serie kurzfristig absagen
      if (this.ausfallstunde.kurzfristig) this.ausfallstunde.serie = 0;
      this.ausfallstunde.stunde_erfassen = stunde_erfassen;
      let begruendung = (this.ausfallstunde.begruendung != '') ? this.ausfallstunde.begruendung : 'Keine Begründung';
      this.$bvModal.hide(this.id);
      let sendTermin = {id: this.termin.id, abgesagt: begruendung, ausfallstunde: this.ausfallstunde, termin: this.termin};
      this.$$store('terminabsagen', sendTermin, 'Termin abgesagt', '/api/termine/'+sendTermin.id+'/absagen').then(response => {
        this.$nuxt.$emit('refresh-termine-'+this.$moment(response.von).format('YYYY-MM-DD'));
        this.emptyTermin();
        this.$nuxt.$emit('store-ausfallstunde');
      }, error => {
        this.$bvModal.show(this.id);
        this.$error('Termin Absagen fehlgeschlagen', null, error);
        this.ausfallstunde = sendTermin.ausfallstunde;
        this.termin = sendTermin.termin
      })
    },
    show() {
      this.$root.$emit('bv::hide::tooltip');
    },
    terminAbsage(termin) {
      termin = this.$copy(termin);
      this.termin = termin;
      this.ausfallstunde.von = this.termin.von;
      this.ausfallstunde.bis = this.termin.bis;
      this.ausfallstunde.dauer = this.termin.dauer;
      this.ausfallstunde.verordnung_id = this.termin.verordnung_id;
      this.ausfallstunde.mitarbeiter_id = this.$model.id;
      this.ausfallstunde.patient_id = this.termin.patienten[0].id;
      this.$bvModal.show(this.id);
    },
    updateBerechnen() {
      if (this.ausfallstunde.kurzfristig && this.therapeutErfasst) this.ausfallstunde.berechnen = true;
      else this.ausfallstunde.berechnen = false;
    },
    updatePatientabsage() {
      this.ausfallstunde.begruendung = null;
      if (!this.ausfallstunde.patientabsage) {
        this.ausfallstunde.berechnen = null;
        this.ausfallstunde.kurzfristig = null;
      }
    }
  },
  mounted() {
    this.emptyTermin()
    this.event = this.$nuxt.$on('kalender-termin-absage', this.terminAbsage);
  },
  beforeDestroy() {
    this.$nuxt.$off('kalender-termin-absage', this.terminAbsage);
  }
}
