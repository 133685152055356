
export default {
  mixins: [],
  props: ['termin'],
  data() {
    return {}
  },
  computed: {
    fehler() {
      if (this.termin.therapiestunden_frei !== null && this.termin.therapiestunden_frei <= 0) return true;
      return false;
    },
    endeNahe() {
      if (this.termin.therapiestunden_frei !== null && this.termin.therapiestunden_frei <= 2) return true;
      return false;
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
