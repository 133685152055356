
export default {
  mixins: [],
  props: [],
  data() {
    return {
      fotos: [],
      seitenzahl: 1,
      vorschaukey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    foto(zahl, event) {
      if (!this.fotos[zahl]) this.fotos.push(event);
      else this.fotos.splice(zahl, 1, event);
    },
    remove(index) {
      this.fotos.splice(index, 1);
      if (this.seitenzahl != 1) this.seitenzahl--;
    },
    moveLeft(index) {
      let speicher = this.fotos[index-1];
      this.fotos[index-1] = this.fotos[index];
      this.fotos[index] = speicher;
      this.vorschaukey++;
    }
  },
  mounted() {},

}
