
import moment from 'moment';
export default {
  data: () => ({
    loading: false,
    startTime: new Date(),
    // loadingSeconds: 0,
    displayText: ''
  }),
  methods: {
    start(text = '') {
      this.displayText = text;
      this.startTime = new Date();
      this.loading = true
      // this.secondsLoading();
    },
    finish() {
      this.loading = false
    },
    // secondsLoading() {
    //   let diff = moment().local('de').diff(this.startTime, 'seconds', false);
    //   this.loadingSeconds = diff;
    //   setTimeout(() => {
    //     if (!this.loading) return;
    //     return this.secondsLoading();
    //   }, 1000)
    // }
  },
  computed: {
  }
}
