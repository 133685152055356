
export default {
  mixins: [],
  props: ['kalender', 'spalte', 'spalteAbgerufen'],
  data() {
    return {
      verfuegbarkeiten: null,
    }
  },
  computed: {},
  watch: {
    spalteAbgerufen(neu) {
      this.abrufen();
    },
    spalte(neu, alt) {
      if (neu.filter != alt.filter) {
        this.$$abbrechen('verfuegbarkeiten');
        this.verfuegbarkeiten = null;
        this.abrufen();
      }
    }
  },
  methods: {
    anwesend(zeit) {
      if (!this.$$filled('verfuegbarkeiten')) return {anwesend: true};
      if (!this.verfuegbarkeiten || !this.verfuegbarkeiten.length) return {anwesend: false};
      let ret = {anwesend: false};

      this.verfuegbarkeiten.forEach(az => {
        if (zeit.uhrzeit.isBetween('1970-01-01 '+az.von, '1970-01-01 '+az.bis, undefined, '[)')) {
          ret = {anwesend: true, von: az.von, bis: az.bis, ort: az.zwischenspeicher_ort};
          if (az.zwischenspeicher_farbe) ret.farbe = az.zwischenspeicher_farbe;
          else ret.farbe = "#FFF";

          if (az.von == zeit.displaytime) {
            ret.erste_zeile = true;
          }
        }
      });

      return ret;
    },
    anwesendOrt(zeit) {
      if (!this.$$filled('verfuegbarkeiten')) return "&nbsp;";
      let ret = "&nbsp;";
      this.verfuegbarkeiten.forEach(az => {
        if (!az.standort_id || !az.standort || !az.standort.name) return;
        if (this.$moment(az.von).format('HH:mm') == zeit.uhrzeit.format('HH:mm')) ret = az.standort.name;
      });
      return ret;
    },
    abrufen() {
      if (!this.spalteAbgerufen) return;
      if (!this.spalte || !this.spalte.model_type.includes('Mitarbeiter')) return;
      this.$$abrufen('verfuegbarkeiten', null, '/api/kalender/verfuegbarkeiten/'+this.spalte.filter.datum+'/'+this.spalte.model_id).then(response => {
        if (response.min_uhrzeit && response.min_uhrzeit < this.kalender.meta.von) this.$emit('min-uhrzeit', response.min_uhrzeit);
        if (response.max_uhrzeit && response.max_uhrzeit > this.kalender.meta.bis) this.$emit('max-uhrzeit', response.max_uhrzeit);
        this.verfuegbarkeiten = response.verfuegbarkeiten;
      });
    },
  },
  mounted() {
    this.abrufen();
  },
  beforeDestroy() {
    this.$$abbrechen('verfuegbarkeiten');
  }

}
