
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'neuaufnahme-formular',
      modaltitel: 'Neuaufnahme',
      event: 'neuaufnahme',
      anruf:null,
      rufnummerUebernommen: false,
      emptyPatient: {
        patientenkontakte: [],
        kontakt: null,
      },
      patient: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(anruf) {
      this.reset();
      this.anruf = anruf;
      this.$bvModal.show(this.modalid);
    },
    reset() {
      this.anruf = null;
      this.patient = this.$copy(this.emptyPatient);
      this.rufnummerUebernommen = false;
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$$fill('patienten', {with: []});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },
}
