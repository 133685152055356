
export default {
  mixins: [],
  props: ['anruf'],
  data() {
    return {
      sprachnachricht: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    sprachnachrichtAbrufen() {
      this.$axios.get('/api/anrufe/'+this.anruf.id+'/sprachnachricht').then(response => {
        this.sprachnachricht = response.data;
        this.zeilenkey++;
      })
    }
  },
  mounted() {},

}
