
import abwesendheiten from '~/mixins/kalender/abwesendheiten';
export default {
  mixins: [abwesendheiten],
  props: ['spalte', 'spaltenData'],
  data() {
    return {
      id: this._uid,
      showTooltip: false,
    }
  },
  computed: {
    aktuelleSpalteUrlaub() {
      if (this.abwesendheiten.urlaube.length && this.abwesendheiten.urlaube.find(el => el.mitarbeiter_id == this.model_id)) {
        this.$emit('abwesend', true);
        return true;
      }
      return false;
    },
    aktuelleSpalteKrankheit() {
      if (this.abwesendheiten.krankheiten.length && this.abwesendheiten.krankheiten.find(el => el.mitarbeiter_id == this.model_id)) {
        this.$emit('abwesend', true);
        return true;
      }
      return false;
    },
    aktuelleSpalteGeburtstag() {
      if (this.abwesendheiten.geburtstage.length && this.abwesendheiten.geburtstage.find(el => el.model_id == this.model_id)) return true;
      return false;
    },
    aktuelleSpalteCounter() {
      let counter = 0;
      if (this.aktuelleSpalteUrlaub) counter++;
      if (this.aktuelleSpalteKrankheit) counter++;
      if (this.aktuelleSpalteGeburtstag) counter++;
      return counter;
    },
    alleSpaltenCounter() {
      return this.abwesendheiten.urlaube.length + this.abwesendheiten.krankheiten.length + this.abwesendheiten.geburtstage.length;
    },
    detailInfos() {
      if (this.alleSpaltenCounter > this.aktuelleSpalteCounter) return true;
      return false;
    }
  },
  watch: {},
  methods: {
    badgeClass(urlaubstag) {
      if (this.model_id == urlaubstag.mitarbeiter_id || this.model_id == urlaubstag.model_id) return 'badge-danger';
      return 'badge-dark';
    }
  },
  mounted() {
    this.abrufen();
  },

}
