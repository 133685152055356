
export default {
  mixins: [],
  props: ['freizeitausgleich'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    switchStatus(freizeitausgleich, status) {
      freizeitausgleich.status = status;
      this.$$update('freizeitausgleiche', freizeitausgleich, 'Status des Freizeitausgleichs aktualisiert').then(() => {
        this.$emit('update');
      })
    },
  },
  mounted() {},

}
