
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arzt-verordnungen',
      modaltitel: 'Verordnungen des Arztes',
      event: 'display-arzt-verordnungen',
      arzt_id: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(arzt_id) {
      this.arzt_id = arzt_id;
      this.$$fill('verordnungen', {filter: [{where: ['arzt_id', this.arzt_id]}], with: ['patient.kontakt'], perPage: 10});
      this.$$abrufen('verordnungen');
      this.$bvModal.show(this.modalid);
    },
    archivieren() {
      this.$$delete('aerzte', this.arzt_id).then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('delete-arzt', response);
      })
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
