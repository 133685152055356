
import Subnav from '~/layouts/template/subnav';
export default {
  components: {Subnav},
  data() {
    return {}
  },
  computed: {
    menuepunkte() {
      if (this.$route.path.includes('/profil/zugangsdaten')) {
        return  [
          {'bezeichnung': 'Passwort', key: 'passwort', route: '/profil/zugangsdaten', if: true},
          {'bezeichnung': 'Biometrische Authentifizierung', key: 'webauthn',route: '/profil/zugangsdaten/webauthn', if: true},
          {'bezeichnung': '2 Faktor Authentifizierung', key: '2fa',route: '/profil/zugangsdaten/2fa', if: true},
          {'bezeichnung': 'Nuki', key: 'nuki',route: '/profil/zugangsdaten/nuki', if: this.$auth.user.email_verified_at && this.$canLike('Nuki')},
          {'bezeichnung': 'Placetel', key: 'placetel',route: '/profil/zugangsdaten/placetel', if: this.$auth.user.email_verified_at},
        ];
      }
      if (this.$route.path.includes('/profil/vertragsdaten')) {
        return  [
          {'bezeichnung': 'Kontakt', key: 'kontakt', route: '/profil/vertragsdaten', if: true},
          {'bezeichnung': 'Vertrag', key: 'vertrag',route: '/profil/vertragsdaten/vertrag', if: this.$auth.user.email_verified_at},
          // {'bezeichnung': 'Vergütung', key: 'verguetung',route: '/profil/vertragsdaten/verguetung', if: this.$auth.user.email_verified_at},
        ];
      }
      if (this.$route.path.includes('/profil/arbeitszeitkonto')) {
        return  [
          {'bezeichnung': 'Stundenkonto', key: 'stundenkonto', route: '/profil/arbeitszeitkonto', if: true},
          {'bezeichnung': 'Freizeitausgleich', key: 'freizeitausgleich',route: '/profil/arbeitszeitkonto/freizeitausgleich', if: true},
          {'bezeichnung': 'Urlaub', key: 'urlaub', route: '/profil/arbeitszeitkonto/urlaub', if: true},
          {'bezeichnung': 'Krankmeldung', key: 'krankmeldungen', route: '/profil/arbeitszeitkonto/krankmeldungen', if: true},
          {'bezeichnung': 'Verfügbarkeiten', key: 'verfuegbarkeiten', route: '/profil/arbeitszeitkonto/verfuegbarkeiten', if: true},
          // {'bezeichnung': 'Vertrag', key: 'vertrag',route: '/profil/vertragsdaten/vertrag', if: this.$auth.user.email_verified_at},
          // {'bezeichnung': 'Vergütung', key: 'verguetung',route: '/profil/vertragsdaten/verguetung', if: this.$auth.user.email_verified_at},
        ];
      }

    }
  },
  methods: {}
}
