
export default {
  props: ['kalender'],
  data() {
    return {
      // editSpalte: null,
      // editSpalteKey: 0
    }
  },
  methods: {
    // fillEditSpalte() {
    //   this.editSpalte = {id: this.$uuid.v1(), titel: '', datum: this.$moment().format('YYYY-MM-DD'), filter: {
    //     mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    //   }, key: 0, show: false};
    //   this.editSpalteKey++;
    // },
    // show() {
    //   this.editSpalte.show = !this.editSpalte.show
    //   this.editSpalteKey++;
    // }
  },
  mounted() {
    // this.fillEditSpalte();
  }
}
