
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'verordnungsaenderung-formular',
      modaltitel: 'Verordnungsänderung',
      event: 'create-verordnungsaenderung',
      verordnung: null,
      verordnungsaenderung: null,
    }
  },
  computed: {
    disabled() {
      if (!this.verordnungsaenderung) return true;
      if (!this.verordnungsaenderung.dateiversion_id) return true;
      if (!this.verordnungsaenderung.details) return true;
      if (this.verordnungsaenderung.details.filter(el => el.soll).length == 0) return true;
      return false;
    }
  },
  watch: {},
  methods: {
    start(verordnung) {
      this.verordnung = verordnung;
      this.verordnungsaenderung = {
        details: verordnung.aenderung_felder,
        verordnung_id: verordnung.id,
        dateiversion_id: null,
      }
      this.$bvModal.show(this.modalid);
    },
    bearbeiten(verordnungsaenderung) {
      this.verordnung = verordnungsaenderung.verordnung;
      this.verordnungsaenderung = verordnungsaenderung;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.verordnungsaenderung.id) this.update();
      else {
        this.$$store('verordnungsaenderungen', this.verordnungsaenderung).then((verordnungsaenderung) => {
          this.$nuxt.$emit('store-verordnungsaenderung');
          this.$bvModal.hide(this.modalid);
          this.$nuxt.$emit('create-schreiben', {model_id: verordnungsaenderung.id, model_type: 'App\\Models\\Verordnungsaenderung', titel: 'Verordnungsänderung', anhang: verordnungsaenderung.dateiversion_id})
        })
      }
    },
    update() {
      this.$$update('verordnungsaenderungen', this.verordnungsaenderung).then(() => {
        this.$nuxt.$emit('store-verordnungsaenderung');
        this.$bvModal.hide(this.modalid);
      })
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
    this.$nuxt.$on('edit-verordnungsaenderung', this.bearbeiten);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
    this.$nuxt.$off('edit-verordnungsaenderung', this.bearbeiten);
  },

}
