
export default {
  mixins: [],
  props: {
    'value': {},
  },
  data() {
    return {
      vorlage: null,
    }
  },
  computed: {},
  watch: {
    value(neu) {
      this.fill();
    }
  },
  methods: {
    fill() {
      if (!this.value) return;
      this.vorlage = this.value;
    },
    emit() {
      this.$nextTick(() => {
        this.$emit('input', this.vorlage);
      })
    },
  },
  mounted() {
    this.fill();
    this.$$abrufen('vorlagen');
  },

}
