
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'modeldatei-verschieben-formular',
      modaltitel: 'Modeldatei verschieben',
      event: 'modeldatei-verschieben',
      datei: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(datei) {
      this.datei = datei;
      this.$bvModal.show(this.modalid);
    },
    hide() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
    this.$nuxt.$on('update-datei', this.hide);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
    this.$nuxt.$off('update-datei', this.hide);
  },

}
