
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiter-verfuegbarkeiten',
      modaltitel: 'Verfügbarkeiten',
      event: 'display-mitarbeiter-verfuegbarkeiten',
      mitarbeiter: null,
      mitarbeiter_id: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(mitarbeiter_id) {
      this.mitarbeiter_id = mitarbeiter_id;
      this.abrufen()
      this.$bvModal.show(this.modalid);
    },
    abrufen() {
      this.$$find('mitarbeiter', this.mitarbeiter_id).then(element => {
        this.mitarbeiter = element;
      });
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
