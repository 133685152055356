import { render, staticRenderFns } from "./AnrufStarten.vue?vue&type=template&id=891999f0"
import script from "./AnrufStarten.vue?vue&type=script&lang=js"
export * from "./AnrufStarten.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayTelefon: require('/builds/logo-yes/portal2022/frontend/components/global/display/Telefon.vue').default,GlobalKommentarfunktionKommentar: require('/builds/logo-yes/portal2022/frontend/components/global/kommentarfunktion/Kommentar.vue').default,GlobalDisplayMitarbeiter: require('/builds/logo-yes/portal2022/frontend/components/global/display/Mitarbeiter.vue').default})
