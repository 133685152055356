
export default {
  mixins: [],
  props: ['laufenderAnruf'],
  data() {
    return {}
  },
  computed: {
    anruf() {
      return this.laufenderAnruf.anruf;
    }
  },
  watch: {},
  methods: {
    refresh() {
      this.$$find('laufendeAnrufe', this.laufenderAnruf.id).then(laufenderAnruf => {
        this.$emit('update', laufenderAnruf);
      })
    }
  },
  mounted() {
    this.$nuxt.$on('store-patient', this.refresh);
  },
  beforeDestroy() {
    this.$nuxt.$off('store-patient', this.refresh);
  }

}
