
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arbeitsvertrag-formular',
      modaltitel: 'Arbeitsvertrag',
      event: 'arbeitsvertrag-erstellen',
      arbeitsvertrag: null,
      sendenButton: "Arbeitsvertrag speichern"
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.empty();
      this.arbeitsvertrag.mitarbeiter_id = params.mitarbeiter_id;
      if (params.letzter_vertrag) {
        this.arbeitsvertrag = this.$fillMissing(this.arbeitsvertrag, params.letzter_vertrag)
        this.arbeitsvertrag.wochenstunden = this.arbeitsvertrag.wochenminuten / 60;
        this.arbeitsvertrag.nkfaktor = params.letzter_vertrag.nkfaktor;
        this.arbeitsvertrag.urlaubstage = params.letzter_vertrag.urlaubstage;
        this.arbeitsvertrag.pauschale_orgazeit = params.letzter_vertrag.pauschale_orgazeit;
      }
      this.$bvModal.show(this.modalid);
    },
    empty() {
      this.arbeitsvertrag = {
        mitarbeiter_id: null,
        wochenminuten: null,
        wochenstunden: null,
        gueltig_ab: this.$moment().format('YYYY-MM-DD'),
        gueltig_bis: null,
        bruttomonatslohn: null,
        bruttostundenlohn: null,
        nkfaktor: 1,
        urlaubstage: 20,
        pauschale_orgazeit: true,
      };
    },
    erstellen() {
      this.arbeitsvertrag.wochenminuten = Math.round(this.arbeitsvertrag.wochenstunden * 60);
      this.$$store('arbeitsvertraege', this.arbeitsvertrag, 'Arbeitsvertrag erstellen').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('store-arbeitsvertrag', response);
      });
    },
    updateBruttomonatslohn() {
      this.$nextTick(() => {
        if (!this.arbeitsvertrag.bruttostundenlohn || !this.arbeitsvertrag.wochenstunden) return;
        this.arbeitsvertrag.bruttomonatslohn = Math.round(this.arbeitsvertrag.wochenstunden * process.env.wochenProMonat * this.arbeitsvertrag.bruttostundenlohn*100)/100;
      })
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
