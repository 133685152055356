
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiter-vertraege',
      modaltitel: 'Verträge',
      event: 'display-mitarbeiter-vertraege',
      mitarbeiter: null,
      mitarbeiter_id: null,
    }
  },
  computed: {
    letzterVertrag() {
      if (!this.$$data('arbeitsvertraege').length) return null;
      return this.$$data('arbeitsvertraege')[0];
    }
  },
  watch: {},
  methods: {
    start(mitarbeiter_id) {
      this.mitarbeiter_id = mitarbeiter_id;
      this.abrufen()
      this.$bvModal.show(this.modalid);
    },
    abrufen() {
      this.$$find('mitarbeiter', this.mitarbeiter_id).then(element => {
        this.mitarbeiter = element;
      });
      this.$$fill('arbeitsvertraege', {filter: [{where: ['mitarbeiter_id', '=', this.mitarbeiter_id]}]});
      this.$$abrufen('arbeitsvertraege')
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
    this.$nuxt.$on('store-arbeitsvertrag', this.abrufen);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
    this.$nuxt.$off('store-arbeitsvertrag', this.abrufen);
  },

}
