export const state = () => ({
  onlineUsers: []
})

const getters = {
  getOnlineUsers(state) {
    return state.onlineUsers;
  }
}

const mutations = {
  joining(state, newUser) {
    let find = state.onlineUsers.find(user => {
      return user.id == newUser.id
    });
    if (!find) state.onlineUsers.push(newUser);
  },
  here(state, users) {
    state.onlineUsers = users;
  },
  leaving(state, oldUser) {
    state.onlineUsers = state.onlineUsers.filter(user => user.id != oldUser.id);
  }
}
const actions = {

}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
