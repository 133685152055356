
export default {
  mixins: [],
  props: ['therapiestunde', 'therapiestunden', 'navzeile', 'verordnung'],
  data() {
    return {
      edit: null,
      editId: null,
    }
  },
  computed: {
    index() {
      return this.therapiestunden.indexOf(this.therapiestunde);
    },
    hatEditListener(){
      return this.$listeners && this.$listeners.edit
    },
    hatSelectedListener(){
      return this.$listeners && this.$listeners.selected
    },
  },
  watch: {},
  methods: {
    blaettern(param) {
      if (this.therapiestunden[this.index+param]) this.$emit('selected', this.therapiestunden[this.index+param])
    },
    speichern() {
      this.$$update('therapiestunden', this.edit, 'Therapiestunde gespeichert').then(success => {
        console.log(success);

        this.$emit('update', success);
        this.editId = null;
        this.edit = false;
        this.$emit('edit', false);
      });
    },
    emit(therapiestunden) {
      let therapiestunde = therapiestunden.find(el => el.id == this.therapiestunde.id);
      this.$emit('update', therapiestunde);
    },
    bearbeiten() {
      if (!this.editId) this.editId = this.therapiestunde.id
      if (this.$can('Therapie verwalten')) {
        this.$$find('therapiestunden', this.therapiestunde.id).then(therapiestunde => {
          this.$nuxt.$emit('edit-dokumentation', [therapiestunde]);
        });
      }
      else {
        this.edit = this.$copy(this.therapiestunde);
        if (!this.edit) this.$emit('edit', false);
        else this.$emit('edit', true);
      }
    }
  },
  mounted() {
    this.$$fill('therapiestunden', {with: ['patient', 'verordnung', 'mitarbeiter', 'anfahrten']});
    this.$nuxt.$on('store-doku', this.emit);
  },
  beforeDestroy() {
    this.$nuxt.$off('store-doku', this.emit);
  }

}
