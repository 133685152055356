
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiterformular',
      modaltitel: 'Mitarbeiter',
      mitarbeiter: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(id) {
      if (id) {
        this.$$find('mitarbeiter', id).then(mitarbeiter => {
          this.mitarbeiter = mitarbeiter;
        })
      }
      else {
        this.mitarbeiter = {
          nachhilfe_id: null, personalnummer: null, berufsbezeichnung: null, titel: null, akademischer_grad: null, steuer_id: null, sozialversicherungsnummer: null,
          kreditinstitut: null, iban: null, feiertagsdaten: {bundesland: 'NI', katholisch: false, augsburg: false,}, kontakt: null,
        };
      }
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$nuxt.$on('edit-mitarbeiter', this.start);
    this.$nuxt.$on('create-mitarbeiter', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('edit-mitarbeiter', this.start);
    this.$nuxt.$off('create-mitarbeiter', this.start);
  },

}
