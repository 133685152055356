
export default {
  props: ['kommentare', 'showDateSet', 'id', 'type', 'titel'],
  watch: {
    showDateSet: function(neu) {
      this.showDate = neu;
    }
  },
  data() {
    return {
      showDate: false,
      content: {
        text: null,
        titel: ''
      }
    }
  },
  methods: {
    absenden() {
      this.$emit('absenden', this.content);
    }
  }
}
