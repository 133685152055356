
export default {
  mixins: [],
  props: ['mitarbeiter'],
  data() {
    return {
      zeitraumAuswahl: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    abrufen() {
      this.$$abrufen('verfuegbarkeitZeitraeume', null, '/api/mitarbeiter/'+this.mitarbeiter.id+'/verfuegbarkeitZeitraeume').then(zeitraeume => {
        if (zeitraeume.length) this.zeitraumAuswahl = zeitraeume[0];
      });

    }
  },
  mounted() {
    // Nur die aktuelle Verfügbarkeit oder zukünftige Verfügbarkeiten änderbar
    this.$$fill('verfuegbarkeitZeitraeume', {filter: [{where: ['gueltig_bis', null], orWhere: ['gueltig_bis', '>', this.$moment().format('YYYY-MM-DD')]}]})
    this.abrufen()
  },

}
