
export default {
  mixins: [],
  props: ['urlaub'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    switchStatus(urlaub, status) {
      urlaub.status = status;
      this.$$update('urlaube', urlaub, 'Status des Urlaubs aktualisiert').then(() => {
        this.$emit('update');
      })
    },
  },
  mounted() {},

}
