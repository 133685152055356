
import pdf from 'vue-pdf'
export default {
  mixins: [],
  components: {pdf},
  props: ['pdf'],
  data() {
    return {
      pageCount: undefined,
      displayPage: 1,
    }
  },
  computed: {},
  watch: {},
  methods: {
    download() {

    }
  },
  mounted() {},

}
