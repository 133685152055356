
export default {
  props: ['form', 'value'],
  data() {
    return {
      results: [],
      query: "",
      touched: false,
    }
  },
  watch: {
    query(newValue) {
      if (newValue.length > 2) {
        this.fetchDiagnose();
      }
    }
  },
  computed: {},
  methods: {
    async fetchDiagnose() {
      try {
        const response = await this.$axios.get('api/prefills/diagnosen', {
          params: {
            q: this.query
          }
        });
        this.results = response.data;
      } catch (error) {
        console.error("Error Diagnosefetch: " + error);
      }
    },
    emit() {
      this.$emit('input', this.query);
      this.touched = true;
    },
    touch() {
      this.touched = true;
    }
  },
  mounted() {
    if (this.value) this.query = this.value;
    if (this.form) {
      this.$nuxt.$on('formsubmit-'+this.form, this.touch);
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('formsubmit-'+this.form, this.touch);
  }
}
