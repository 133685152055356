
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arbeitszeit-ausfallstunde-formular',
      modaltitel: 'Arbeitszeit für Ausfallstunde buchen',
      event: 'create-ausfallstunde-arbeitszeit',
      ausfallstunde: null,
      patientKrankenhausCheckbox: false,
    }
  },
  computed: {
    formDisabled() {
      if (!this.ausfallstunde) return true;
      if (!this.ausfallstunde.berechnen && this.ausfallstunde.nicht_berechnen_begruendung != 'Patient im Krankenhaus') return true;
      return false;
    }
  },
  watch: {},
  methods: {
    start(ausfallstunde) {
      this.$$leeren('ausfallstunden');
      this.ausfallstunde = this.$copy(ausfallstunde);
      if (!this.ausfallstunde.arbeitsstunde_id) this.ausfallstunde.berechnen = true;
      if (this.ausfallstunde.nicht_berechnen_begruendung == 'Patient im Krankenhaus') this.patientKrankenhausCheckbox = true;
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      this.ausfallstunde.stunde_erfassen = true;
      this.ausfallstunde.mitarbeiter_id = this.$model.id;
      this.$$update('ausfallstunden', this.ausfallstunde).then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('update-arbeitsstunde', response);
      });
    },
    patientImKrankenhaus(event) {
      if (event) this.ausfallstunde.nicht_berechnen_begruendung = 'Patient im Krankenhaus'
      else this.ausfallstunde.nicht_berechnen_begruendung = "";
    },
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
