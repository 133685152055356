
export default {
  mixins: [],
  props: ['menuepunkte', 'activeValue', 'activeState'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    navigate(menuepunkt) {
      this.$router.push(menuepunkt.route);
    }
  },
  mounted() {},

}
