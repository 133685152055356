import { render, staticRenderFns } from "./Verfuegbarkeiten.vue?vue&type=template&id=46688723"
import script from "./Verfuegbarkeiten.vue?vue&type=script&lang=js"
export * from "./Verfuegbarkeiten.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternProfilVerfuegbarkeitFormular: require('/builds/logo-yes/portal2022/frontend/components/intern/profil/VerfuegbarkeitFormular.vue').default,InternProfilVerfuegbarkeitKopierenFormular: require('/builds/logo-yes/portal2022/frontend/components/intern/profil/VerfuegbarkeitKopierenFormular.vue').default})
