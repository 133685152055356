
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'therapiestunde-verschieben-formular',
      modaltitel: 'Therapiestunde verschieben',
      event: 'therapiestunde-verschieben',
      therapiestunde: null,
      patient: null,
      fachbereich: null,
      sendenButton: "Verschieben",
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(therapiestunde) {
      this.therapiestunde = null;
      this.$$find('therapiestunden', therapiestunde.id).then(el => {
        this.patient = el.verordnung.patient;
        this.fachbereich = el.verordnung.fachbereich;
        this.therapiestunde = el;
      })
      this.$bvModal.show(this.modalid);
    },
    update() {
      if (!this.therapiestunde.verordnung) return
      this.therapiestunde.verordnung_id = this.therapiestunde.verordnung.id;
      this.$$update('therapiestunden', this.therapiestunde, 'Therapiestunde verschoben').then(response => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('update-therapiestunde', response);
      });

    }
  },
  mounted() {
    this.$$fill('therapiestunden', {with: ['verordnung.patient']});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
