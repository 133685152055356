export default {
  data() {
    return {
      sending: false,
      error: null,
    }
  },
  methods: {
    // async $$post(url, data, successmessage) {
    //   this.sending = true;
    //   this.error = null;
    //   try {
    //     let response = await this.$axios.post(url, data);
    //     this.sending = false;
    //     this.$success(successmessage);
    //     return response.data;
    //   } catch (error) {
    //     this.error = error;
    //     this.sending = false;
    //   }
    // },
    async $$put(url, data, successmessage) {
      this.sending = true;
      this.error = null;
      return new Promise((resolve, reject) => {
        this.$axios.put(url, data).then(response => {
          this.sending = false;
          this.$success(successmessage);
          resolve(response.data);
        }, error => {
          this.error = error;
          this.sending = false;
          reject(error)
        });
      });
    },
    async $$post(url, data, successmessage) {
      this.sending = true;
      this.error = null;
      return new Promise((resolve, reject) => {
        this.$axios.post(url, data).then(response => {
          this.sending = false;
          this.$success(successmessage);
          resolve(response.data);
        }, error => {
          this.error = error;
          this.sending = false;
          reject(error)
        });
      });
    },
    async $$delete(url, data, successmessage) {
      this.sending = true;
      this.error = null;
      try {
        let response = await this.$axios.delete(url, data);
        this.sending = false;
        this.$success(successmessage);
        return response.data;
      } catch (error) {
        this.error = error;
        this.sending = false;
      }
    },
  }
}
