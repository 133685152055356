
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiter-rollen',
      modaltitel: 'Mitarbeiter Rollen & Permissions',
      event: 'edit-mitarbeiter-rollen',
      mitarbeiter: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(mitarbeiter_id) {
      this.$$find('mitarbeiter', mitarbeiter_id).then(mitarbeiter => {
        this.mitarbeiter = mitarbeiter;
      })
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$$fill('mitarbeiter', {with: ['user.roles', 'user.permissions']})
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
