
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'datei-senden',
      modaltitel: 'Datei senden',
      event: 'display-datei-senden',
      datei: null,
      faxnummer: null,
      nummerkey: 0,
      kontakt: null,
      done: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(datei) {
      this.kontakt = null;
      this.done = null;
      this.datei = datei;
      this.$bvModal.show(this.modalid);
    },
    faxSenden() {
      let url = '/api/dateiversionen/'+this.datei.aktuelle_version_id+'/faxen/'+this.faxnummer;
      this.$$update('modeldateien', this.datei, null, url).then(response => {
        this.done = true;
        this.$emit('store-scan', response);
      });
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
