
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'arztformular',
      modaltitel: 'Arzt',
      arzt: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(id) {
      if (id) {
        this.$$find('aerzte', id).then(arzt => {
          this.arzt = arzt;
        })
      }
      else {
        this.arzt = {fachbereich_id: null, kontakt:null}
      }
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$nuxt.$on('edit-arzt', this.start);
    this.$nuxt.$on('create-arzt', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('edit-arzt', this.start);
    this.$nuxt.$off('create-arzt', this.start);
  },

}
