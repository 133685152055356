
export default {
  props: ['mitarbeiter_id', 'verwaltung'],
  data() {
    return {
      formulartitel: 'Freizeitausgleich beantragen',
      jahr: Number(this.$moment().format('Y')),
      mitarbeiter: null,
    }
  },
  computed: {
    stundenstand() {
      if (!this.mitarbeiter) return 0;
      return this.$round(this.mitarbeiter.arbeitszeitkonto.minutenkontostand / 60)
    },
  },
  methods: {
    neuerFreizeitausgleich() {
      this.formulartitel = 'freizeitausgleich beantragen';
      this.$bvModal.show('freizeitausgleich-formular');
    },
    abrufen() {
      this.$$fill('freizeitausgleiche', {filter: [{where: ['mitarbeiter_id', this.mitarbeiter_id]}, {whereYear: ['von', '=', this.jahr]}]});
      this.$$abrufen('freizeitausgleiche');
      this.$$fill('mitarbeiter', {with: ['arbeitszeitkonto']});
      this.$$find('mitarbeiter', this.mitarbeiter_id).then(mitarbeiter => {
        this.mitarbeiter = mitarbeiter;
      })
    },
    updateJahr(summand) {
      this.jahr += summand;
      this.abrufen();
    },
    loeschen(freizeitausgleich) {
      this.$confirm('Möchtest du den freizeitausgleich wirklich zurückziehen?').then((response) => {
        if (response) {
          this.$$delete('freizeitausgleiche', freizeitausgleich.id).then(response => {
            this.abrufen();
            this.$$success('freizeitausgleich erfolgreich zurückgezogen');
          })
        }
      });
    }
  },
  mounted() {
    this.abrufen();
    this.$nuxt.$on('store-freizeitausgleich', this.abrufen);
  },
  beforeDestory() {
    this.$nuxt.$off('store-freizeitausgleich', this.abrufen);
  }
}
