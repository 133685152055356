
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
export default {
  mixins: [heilmittelkatalog],
  props: [],
  data() {
    return {
      modalid: 'kostenpunkt-formular',
      modaltitel: 'Kostenpunkt',
      event: 'kostenpunkt',
      kostenpunkt: null,
      heilmittel: null,
      heilmittelkey: 0,
      alleVerordnungstypen: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(kostenpunkt = null) {
      this.kostenpunkt = null;
      this.heilmittel = null;
      this.alleVerordnungstypen = null;
      if (kostenpunkt) {
        this.kostenpunkt = this.$copy(kostenpunkt);
        if (!this.kostenpunkt.fuer_verordnungstyp) this.alleVerordnungstypen = true;
      }
      else this.kostenpunkt = {bezeichnung: '', fachbereich: null, teilnehmerzahl: null, dauer: null, heilmittel: null, heilmittel_id: null, type: null, fuer_verordnungstyp: 'null'};
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.kostenpunkt.heilmittel) this.kostenpunkt.heilmittel_id = this.kostenpunkt.heilmittel.id
      else this.kostenpunkt.heilmittel_id;
      if (this.kostenpunkt.id) this.$$update('kostenpunkte', this.kostenpunkt, 'Kostenpunkt gespeichert').then(() => this.done('update-'+this.event));
      else this.$$store('kostenpunkte', this.kostenpunkt, 'Kostenpunkt gespeichert').then(() => this.done('store-'+this.event));
    },
    done(event = null) {
      this.$bvModal.hide(this.modalid)
      if (event) this.$nuxt.$emit(event)
    },
    resetHeilmittel() {
      this.kostenpunkt.bezeichnung = null;
      if (this.kostenpunkt.type != 'Sonstiges') {
        this.kostenpunkt.heilmittel = null;
        this.kostenpunkt.teilnehmerzahl = null;
      }
      this.heilmittelkey++;
    },
    updateTeilnehmerzahl(neu) {
      if (neu > 2 && this.kostenpunkt.teilnehmerzahl <= 2 || neu <= 2 && this.kostenpunkt.teilnehmerzahl > 2) this.resetHeilmittel();
      this.kostenpunkt.teilnehmerzahl = neu;
    },
    updateAlle(input) {
      if (input) {
        this.kostenpunkt.fuer_verordnungstyp = null;
      }
    }
  },
  mounted() {
    this.$nuxt.$on('create-'+this.event, this.start);
    this.$nuxt.$on('edit-'+this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('create-'+this.event, this.start);
    this.$nuxt.$off('edit-'+this.event, this.start);
  },

}
