
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'krankenkasseformular',
      modaltitel: 'Krankenkasse',
      krankenkasse: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(id) {
      if (id) {
        this.$$find('krankenkassen', id).then(krankenkasse => {
          this.krankenkasse = krankenkasse;
        })
      }
      else {
        this.krankenkasse = {fachbereich_id: null, kontakt:null}
      }
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$nuxt.$on('edit-krankenkasse', this.start);
    this.$nuxt.$on('create-krankenkasse', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('edit-krankenkasse', this.start);
    this.$nuxt.$off('create-krankenkasse', this.start);
  },

}
