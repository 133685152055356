
import heilmittelkatalog from '~/mixins/heilmittelkatalog'
import formular from '~/mixins/formular'
export default {
  props: ['therapeut'],
  mixins: [heilmittelkatalog, formular],
  data() {
    return {
      fachbereich: null,
      mitarbeiterStoerungsbilder: [],
    }
  },
  computed: {
    heilmittelkatalogNachFach() {
      if (this.fachbereich == null) return [];
      if (this.fachbereich == 0) return this.heilmittelkatalog.filter(hm => hm.therapie == 'logopaedie');
      if (this.fachbereich == 1) return this.heilmittelkatalog.filter(hm => hm.therapie == 'ergotherapie');
    }
  },
  methods: {
    fachbereichWaehlen(fachbereich) {
      this.fachbereich = fachbereich
      this.mitarbeiterStoerungsbilderBauen();
    },
    mitarbeiterStoerungsbilderBauen() {
      let stoerungsbilder = this.$copy(this.therapeut.stoerungsbilder);
      this.heilmittelkatalogNachFach.forEach(hm => {
        if (!this.mitarbeiterStoerungsbild(hm.indikationsschluessel, stoerungsbilder)) {
          stoerungsbilder.push({mitarbeiter_id: this.therapeut.id, indikationsschluessel: hm.indikationsschluessel, praeferenz: null, alter_von: null, alter_bis: null, fachbereich: this.fachbereich});
        }
      })
      this.mitarbeiterStoerungsbilder = stoerungsbilder;
    },
    mitarbeiterStoerungsbild(indikationsschluessel, stoerungsbilder = null) {
      let stoerungsbilderSearch = (stoerungsbilder) ?? this.mitarbeiterStoerungsbilder;
      return stoerungsbilderSearch.find(stoerungsbild => stoerungsbild.indikationsschluessel == indikationsschluessel);
    },

    stoerungsbilderSpeichern() {
      let send = this.mitarbeiterStoerungsbilder.filter(el => el.praeferenz != null);
      this.$$post('/api/mitarbeiter/'+this.therapeut.id+'/stoerungsbilder', {stoerungsbilder: send}, 'Störungsbilder erfolgreich aktualisiert.').then(() => {
        this.$auth.fetchUser();
        this.$emit('done');
      })
    }
  },
  mounted() {
    if (this.therapeut.ist_therapeut !== false) {
      this.fachbereichWaehlen(this.therapeut.ist_therapeut);
    }
  }
}
