
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'projekt-stundenuebersicht',
      modaltitel: 'Stundenübersicht für Projekt',
      event: 'display-projekt-stundenuebersicht',
      projekt: null,
    }
  },
  computed: {
    url() {
      if (!this.projekt) return null;
      return '/api/projekte/'+this.projekt.id+'/stunden';
    }
  },
  watch: {},
  methods: {
    start(projekt) {
      this.$$fill('projekte', {with: ['sonstige', 'mitarbeiter'], append: ['stundenzahl']});
      this.$$find('projekte', projekt.id).then(projekt => {
        this.projekt = projekt;
        this.$$abrufen('projektstunden', null, this.url);
      })
      this.$bvModal.show(this.modalid);
    },
  },
  mounted() {
    this.$$fill('projektstunden', {with: ['mitarbeiter'], perPage: 10});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
