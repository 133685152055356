
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'mitarbeiter-arbeitszeitkonto',
      modaltitel: 'Mitarbeiter Arbeitszeitkonto',
      event: 'display-mitarbeiter-arbeitszeitkonto',
      mitarbeiter_id: null,
      countingkey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(mitarbeiter_id) {
      this.mitarbeiter_id = mitarbeiter_id;
      this.$bvModal.show(this.modalid);
    },
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
