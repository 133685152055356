
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'diagnosegruppe-formular',
        event: 'edit::diagnosegruppe',
        titel: null,
        modelname: 'Diagnosegruppe',
        model: 'diagnosegruppen'
      },
      leitsymptomatiken: {a: '', b: '', c: ''},
      neuesHeilmittel: null,
      hmkey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    leitsymptomatikenUmbauen() {
      this.leitsymptomatiken = {a: '', b: '', c: ''};
      if (!this.model) return;
      let ls_a = this.model.leitsymptomatiken.find(el => el.buchstabe == 'a');
      if (ls_a) this.leitsymptomatiken.a = ls_a.bezeichnung;
      let ls_b = this.model.leitsymptomatiken.find(el => el.buchstabe == 'b');
      if (ls_b) this.leitsymptomatiken.b = ls_b.bezeichnung;
      let ls_c = this.model.leitsymptomatiken.find(el => el.buchstabe == 'c');
      if (ls_c) this.leitsymptomatiken.c = ls_c.bezeichnung;
    },
    addHeilmittel(heilmittel) {
      let hm = this.$copy(heilmittel);
      hm.pivot = {gruppe: 0, blanko_vo: 0};
      this.model.heilmittel.push(hm);
      this.neuesHeilmittel = null;
      this.hmkey++;
    },
    nachStart() {
      this.leitsymptomatikenUmbauen();
    },
    speichernStart() {
      let ls = [];
      if (this.leitsymptomatiken.a) ls.push({buchstabe: 'a', bezeichnung: this.leitsymptomatiken.a});
      if (this.leitsymptomatiken.b) ls.push({buchstabe: 'b', bezeichnung: this.leitsymptomatiken.b});
      if (this.leitsymptomatiken.c) ls.push({buchstabe: 'c', bezeichnung: this.leitsymptomatiken.c});

      this.model.leitsymptomatiken = ls;
      this.speichern();

      // TODO: Heilmittel auswählbar machen und konfigurieren ob für Blanko VO erlaubt oder nicht (Matrix? )
      /**
       * Heilmittel     ||    Checkbox Gruppe     || Checkbox Blanko VO
       *
       * _______
       * Heilmittel hinzufügen
       */
    }
  }

}
