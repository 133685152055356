
import termine from '~/mixins/models/termine';
export default {
  mixins: [termine],
  props: ['termin'],
  data() {
    return {}
  },
  computed: {
    meinTermin() {
      if (!this.termin) return false;
      return this.termin.mitarbeiter.map(el => el.id).includes(this.$model.id);
    },
  },
  watch: {},
  methods: {},
  mounted() {},

}
