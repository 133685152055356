
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'neuer-fall-formular',
      modaltitel: 'Neuen Fall anlegen',
      event: 'create-fall',
      fall: null,
    }
  },
  computed: {
    disabled() {
      if (!this.fall.betreffender.kontakt) return true;
      return false;
    }
  },
  watch: {},
  methods: {
    start(params) {
      this.fall = {
        status: 0,
        titel: '',
        betreffender_id: null,
        betreffender_type: null,
        betreffender: {
          kontakt: null
        },
      };
      this.$bvModal.show(this.modalid);
    },
    anlegen() {
      if (!this.fall.betreffender.kontakt) return;
      this.fall.betreffender_id = this.fall.betreffender.kontakt.model_id;
      this.fall.betreffender_type = this.fall.betreffender.kontakt.model_type;
      this.$$store('faelle', this.fall).then(fall => {
        this.$nuxt.$emit('display-fall', fall);
        this.$nuxt.$emit('store-fall');
        this.$bvModal.hide(this.modalid);
      });
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
