export default function({$auth, route, redirect}) {
  let perArray = [
    {route: 'verwaltung-verordnungen', permission: 'Verordnungen verwalten'},
    {route: 'verwaltung-ausfallstunden', permission: 'Ausfall verwalten'},
    {route: 'verwaltung-projekte', permission: 'Projekte verwalten'},
    {route: 'verwaltung-mitarbeitergruppen', permission: 'Mitarbeitergruppen verwalten'},
    {route: 'verwaltung-cloud', permission: 'Cloudtags verwalten'},
    {route: 'verwaltung-vertraege', permission: 'Arbeitsverträge verwalten'},
    {route: 'verwaltung-rollen', permission: 'Permissions verwalten'},
    {route: 'verwaltung-mitarbeiter', permission: 'Mitarbeiter verwalten'},
    {route: 'verwaltung-urlaube', permission: 'Urlaube verwalten'},
    {route: 'verwaltung-krankmeldungen', permission: 'Krankmeldungen verwalten'},
    {route: 'verwaltung-verfuegbarkeiten', permission: 'Verfügbarkeiten verwalten'},
    {route: 'verwaltung-verguetungen', permission: 'Vergütung Voreinstellungen verwalten'},
    {route: 'verwaltung-config', permission: 'Config verwalten'},
  ]

  let perm = perArray.find(el => el.route == route.name)
  let neededPermission = (perm) ? perm.permission : '';

  if (!neededPermission) return true;
  let can = $auth.user.allePermissions.find(el => el.name == neededPermission);
  if (can) return true;
  console.log('Zugriff verweigert. Nicht die entsprechende Permission '+neededPermission);
  return redirect('/dashboard');

}
