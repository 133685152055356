
import config from '~/mixins/config';
import online from '~/mixins/online';
import sockets from '~/mixins/sockets';
export default {
  mixins: [config, online, sockets],
  data() {
    return {
      showBackdrop: false,
      sidebarShow: false,
      sidebarRightShow: false,
      sidebarRightSmallShow: false,
    }
  },
  computed: {
    buildenv() {
      return process.env.buildenv
    }
  },
  methods: {
    // logout() {
    //   this.$nuxt.$loading.start();
    //   this.$auth.logout().then(() => {
    //     this.$nuxt.$loading.finish();
    //   });
    // },
    leaveImpersonate() {
      this.$nuxt.$loading.start('Rolle verlassen');
      this.$axios.put('/api/impersonate/leave').then(() => {
        this.$auth.fetchUser().then(() => {
          this.$router.push('/');
          this.$nuxt.$loading.finish();
        });
      })
    },
    registerBackdrop() {
      this.$nuxt.$on('showBackdrop', () => {
        this.showBackdrop = true;
      })
      this.$nuxt.$on('hideBackdrop', () => {
        this.showBackdrop = false;
      })
    },
    backdropClick() {
      this.$nuxt.$emit('backdropClick');
    }
  },
  mounted() {
    if (this.$route.query.verified) this.$success('E-Mail bestätigt', 'Die E-Mail-Verifizierung wurde erfolgreich abgeschlossen.')
    setTimeout(() => {
      if (this.$route.query.password == 0) this.$success('Passwort erstellen', 'Du erhältst eine E-Mail zum Zurücksetzen deines Passworts!');
    }, 1000)
    this.registerBackdrop();

    // Login via E-Mail, dann Passwort setzen
    let passwordResetStatus = this.$store.getters['passwordReset/status'];
    if (passwordResetStatus) {
      setTimeout(() => {
        this.$router.push('/profil/zugangsdaten/webauthn')
        this.$store.commit('passwordReset/updatePasswordReset', false);
        setTimeout(() => {
          this.$success('Du kannst jetzt in deinen Profileinstellungen ein neues Gerät hinzufügen oder ein neues Passwort setzen');
        }, 1000)
      }, 1000)
    }
    if (this.$auth && this.$can('Debugging')) this.$store.commit('initSetDebug', true);
  }
}
