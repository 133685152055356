
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'entfernung-praxis-modal',
      modaltitel: 'Entfernung Praxis',
      event: 'display-entfernung-praxis',
      fachbereich: null,
      fahrzeug: null,
      kontakt: null,
      standorte: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(kontakt) {
      this.kontakt = kontakt;
      this.$bvModal.show(this.modalid);
    },
    abrufen() {
      if (!this.fahrzeug || this.fachbereich===null) return;
      this.$$abrufen('distanzPraxis', null, '/api/kontakte/'+this.kontakt.id+'/distanzPraxis?fachbereich='+this.fachbereich+'&fahrzeug='+this.fahrzeug).then(response => {
        this.standorte = response;
      })
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
