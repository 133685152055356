
export default {
  mixins: [],
  props: ['value', 'label', 'type'],
  data() {
    return {}
  },
  computed: {
    emptyAnfahrt() {
      return {erfassen: false, von: null, bis: null, dauer: 15, km: null};
    }
  },
  watch: {},
  methods: {},
  mounted() {
    if (!this.value) this.$emit('input', this.$copy(this.emptyAnfahrt));
  },

}
