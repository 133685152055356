
export default {
  mixins: [],
  props: ['kontakt'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {

  },
  mounted() {},

}
