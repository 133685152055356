
import navleft from '~/layouts/template/navleft';
export default {
  components: {navleft},
  data() {
    return {
      suchbegriff: '',
      menuepunkte: [
        {'bezeichnung': 'Ausfallstunden', key: 'ausfallstunden', route: '/verwaltung/ausfallstunden', if: this.$can('Ausfall verwalten')},
        {'bezeichnung': 'Projekte', key: 'projekte', route: '/verwaltung/projekte', if: this.$can('Projekte verwalten')},
        {'bezeichnung': 'Mitarbeitergruppen', key: 'mitarbeitergruppen', route: '/verwaltung/mitarbeitergruppen', if: this.$can('Mitarbeitergruppen verwalten')},
        {'bezeichnung': 'Cloud', key: 'cloud', route: '/verwaltung/cloud', if: this.$can('Cloudtags verwalten')},
        {'bezeichnung': 'Verträge', key: 'vertraege', route: '/verwaltung/vertraege', if: this.$can('Arbeitsverträge verwalten')},
        {'bezeichnung': 'Rollen', key: 'rollen', route: '/verwaltung/rollen', if: this.$can('Permissions verwalten')},
        {'bezeichnung': 'Mitarbeiter', key: 'mitarbeiter', route: '/verwaltung/mitarbeiter', if: this.$can('Mitarbeiter verwalten')},
        {'bezeichnung': 'Urlaub', key: 'urlaube', route: '/verwaltung/urlaube', if: this.$can('Urlaube verwalten')},
        {'bezeichnung': 'Freizeitausgleich', key: 'freizeitausgleiche', route: '/verwaltung/freizeitausgleiche', if: this.$can('Freizeitausgleiche verwalten')},
        {'bezeichnung': 'Krankmeldung', key: 'krankmeldungen', route: '/verwaltung/krankmeldungen', if: this.$can('Krankmeldungen verwalten')},
        {'bezeichnung': 'Verfügbarkeiten', key: 'verfuegbarkeiten', route: '/verwaltung/verfuegbarkeiten', if: this.$can('Verfügbarkeiten verwalten')},
        {'bezeichnung': 'Vergütung', key: 'verguetungen', route: '/verwaltung/verguetungen', if: this.$can('Vergütung Voreinstellungen verwalten')},
        {'bezeichnung': 'Ressourcen', key: 'ressourcen', route: '/verwaltung/ressourcen', if: this.$can('Standorte verwalten')},
        {'bezeichnung': 'Config', key: 'config', route: '/verwaltung/config', if: this.$can('Config verwalten')},
        {'bezeichnung': 'Raumauslastung', key: 'raumauslastung', route: '/intern/raumauslastung', if: this.$can('Raumauslastung sehen')},
        {'bezeichnung': 'Listen', key: 'listen', route: '/intern/listen', if: true},
        {'bezeichnung': 'Queue', key: 'queue', route: '/intern/queue', if: this.$can('Queue Monitor')},
        {'bezeichnung': 'Statistik', key: 'statistik', route: '/intern/statistik', if: this.$can('Statistik sehen')},
        {'bezeichnung': 'Postversand', key: 'postversand', route: '/verwaltung/postversand', if: this.$can('Briefe verwalten')},
        {'bezeichnung': 'Ausgangsrechnungen', key: 'ausgangsrechnungen', route: '/verwaltung/ausgangsrechnungen', if: this.$can('Ausgangsrechnungen verwalten')},
        {'bezeichnung': 'Schreiben Vorlagen', key: 'schreibenvorlagen', route: '/verwaltung/schreibenvorlagen', if: this.$can('Schreiben Vorlagen verwalten')},
        {'bezeichnung': 'Abrechnungen', key: 'abrechnungen', route: '/verwaltung/abrechnungen', if: this.$can('Verordnungsabrechnung')},
      ]
    }
  },
  methods: {}
}
