
export default {
  mixins: [],
  props: ['anruf'],
  data() {
    return {
      kontakt: null,
      telefonnummer: {bezeichnung: '', nummer: this.anruf.kontaktnummer}
    }
  },
  computed: {},
  watch: {},
  methods: {
    speichernKontakt() {
      if (!this.kontakt) return;
      this.kontakt.telefonnummern.push(this.telefonnummer);
      this.$$update('kontakte', this.kontakt).then(() => {
        this.$emit('zugeordnet');
      });
    }
  },
  mounted() {},

}
