import { render, staticRenderFns } from "./Arbeitsstunde.vue?vue&type=template&id=78af052f"
import script from "./Arbeitsstunde.vue?vue&type=script&lang=js"
export * from "./Arbeitsstunde.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternKalenderArbeitsstundeContent: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/ArbeitsstundeContent.vue').default,InternKalenderArbeitsstundeTooltip: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/ArbeitsstundeTooltip/index.vue').default})
