
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'anruf-faelle',
      modaltitel: 'Anruf Fälle',
      event: 'display-anruf-faelle',
      anruf: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(anruf) {
      this.$$find('anrufe', anruf.id).then(anruf => {
        this.anruf = anruf;
      })
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
      this.$nuxt.$emit('update-fall');
    }
  },
  mounted() {
    this.$$fill('anrufe', {with: ['faelle', 'kontakt'], append: ['betreffender', 'betreffenderVorschlag']});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
