
import Multiselect from 'vue-multiselect';
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
export default {
  components: {Multiselect},
  mixins: [heilmittelkatalog],
  props: ['value', 'deleteable', 'fachbereich', 'verordnungstyp', 'disabled'],
  data() {
    return {
      heilmittelGewaehlt: this.$copy(this.value),
      gruppe: 0,
      heilmittelKey: 0,
    }
  },
  methods: {
    updateHeilmittel() {
      if (!this.heilmittelGewaehlt || !this.heilmittelGewaehlt.id) return this.emit();
      if (!this.heilmittelGewaehlt.pivot) this.$set(this.heilmittelGewaehlt, 'pivot', {dauer: '', menge: '', doppelbehandlung: 0});
      // Wenn lt Heilmittelkatalog nur eine Dauer erlaubt ist, dann direkt die einblenden
      if (this.heilmittelGewaehlt && this.heilmittelGewaehlt.dauer && this.heilmittelGewaehlt.dauer.length == 1) this.heilmittelGewaehlt.pivot.dauer = this.heilmittelGewaehlt.dauer[0];
      if (this.heilmittelGewaehlt) this.gruppe = this.heilmittelGewaehlt.gruppe;
      this.emit();
    },
    emit() {
      this.$emit('input', this.heilmittelGewaehlt);
    }
  },
  mounted() {
    this.updateHeilmittel();
  }
}
