
import termin from '~/mixins/kalender/termin';
export default {
  mixins: [termin],
  props: [],
  data() {
    return {
      modalid: 'fallmodal',
      modaltitel: 'Fall',
      event: 'display-fall',
      fall: null,
      betreffender_kontakt: null,
      titelbearbeiten: false,
    }
  },
  computed: {
    disabled() {
      if (!this.fall.neuer_kommentar) return true;
      return false;
    },
    disabledMitarbeiterZuweisen() {
      if (this.disabled) return true;
      if (!this.fall.bearbeiter || !this.fall.bearbeiter.length) return true;
      return false;
    },
  },
  watch: {},
  methods: {
    start(fall) {
      this.fall = null;
      this.betreffender_kontakt = null;
      this.$$find('faelle', fall.id).then(fall => {
        this.fall = fall;
      })
      this.$bvModal.show(this.modalid);
    },
    speichern() {
      if (this.disabled) return;
      if (this.betreffender_kontakt) {
        this.fall.betreffender_id = this.betreffender_kontakt.model_id;
        this.fall.betreffender_type = this.betreffender_kontakt.model_type;
      }
      this.$$update('faelle', this.fall).then(fall => {
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('update-fall', fall);
      });
    },
    abschliessen() {
      this.fall.status = 2;
      this.speichern();
    },
    uebergeben() {
      this.fall.bearbeiter = null;
      this.fall.status = 0;
      this.speichern();
    },
    wartenAufRueckmeldung() {
      this.fall.bearbeiter = null;
      this.fall.status = 1;
      this.speichern();
    },
    wartenAufMitarbeiter() {
      if (!this.fall.bearbeiter.length) return;
      this.fall.status = 3;
      this.speichern();
    },
    editBetreffender() {
      this.betreffender_kontakt = this.fall.betreffender.kontakt;
      this.fall.betreffender = null;
    }
  },
  mounted() {
    this.$$fill('faelle', {with: ['anrufe.kontakt', 'anrufe.mitarbeiter', 'anrufe.sipAccount', 'letzteAenderungModel', 'betreffender', 'bearbeiter', 'termin', 'kommentare']});
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
