export default {
  mixins: [],
  props: [],
  data() {
    return {
      termine: null,
      absagestatus: true,
    }
  },
  computed: {
    disabled() {
      if (!this.termine) return true;
      if (this.termine.length == 0) return false;
      if (this.absagestatus == false) return false;
      if (this.termineNichtErledigt.length) return true;
      return false;
    },
    termineNichtErledigt() {
      return this.termine.filter(el => {
        el.updated_at
        // Abgesagt oder an Telefondienst übergeben
        return (!el.abgesagt && !el.hat_fall && !el.deleted_at);
      })
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
