
export default {
  mixins: [],
  props: ['datum', 'model_type', 'model_id', 'spalteAbgerufen'],
  data() {
    return {
      feiertag: false,
      datumAbgerufen: null,
    }
  },
  computed: {},
  watch: {
    spalteAbgerufen(neu) {
      this.abrufen();
    },
    datum(neu, alt) {
      this.feiertag = false;
      this.abrufen();
    }
  },
  methods: {
    abrufen() {
      if (!this.spalteAbgerufen) return;
      if (this.datumAbgerufen == this.datum) return;
      this.datumAbgerufen = this.datum;
      let url = '/api/kalender/feiertag/'+this.datum+'/';
      if (this.model_type.includes('Mitarbeiter')) url += this.model_id;
      this.$$abrufen('feiertage', null, url).then(response => {
        if (response) {
          this.feiertag = response;
          this.$emit('feiertag', true);
        }
      })
    }
  },
  mounted() {
    this.abrufen();
  },

}
