
export default {
  mixins: [],
  props: ['value'],
  data() {
    return {
      verordnung: this.value,
      patient: null,
      verordnungSimulation: null,
      formid: 'verordnung-pruefen-form'
    }
  },
  computed: {
    errorPatient() {
      if (this.verordnung.patient != null) return null;
      if (this.verordnung.patient == null) return "Es muss ein Patient eingetragen werden!";
    }
  },
  watch: {},
  methods: {
    speichern(geprueft) {
      if (this.verordnung.arzt) this.verordnung.arzt_id = this.verordnung.arzt.id;
      else this.verordnung.arzt_id = null;
      if (this.verordnung.krankenkasse) this.verordnung.krankenkasse_id = this.verordnung.krankenkasse.id;
      else this.verordnung.krankenkasse_id = null;
      if (this.verordnung.ergaenzendes_heilmittel) this.verordnung.ergaenzendesheilmittel_id = this.verordnung.ergaenzendes_heilmittel.id;
      else this.verordnung.ergaenzendesheilmittel_id = null;
      if (this.verordnung.icdcode) this.verordnung.icdcode_id = this.verordnung.icdcode.id;
      else this.verordnung.icdcode_id = null;
      if (this.verordnung.icdcode_2) this.verordnung.icdcode_2_id = this.verordnung.icdcode_2.id;
      else this.verordnung.icdcode_2_id = null;

      if (geprueft) {
        this.verordnung.checked_id = this.$model.id;
        this.verordnung.checked_type = this.$model.model_type;
      }
      this.zuzahlungsbefreitKind();

      this.$$update('verordnungen', this.verordnung, 'Verordnung erfolgreich aktualisiert').then((response) => {
        this.$nuxt.$emit('update-verordnung', response)
        this.$emit('done');
      })
    },
    simulieren() {
      this.$$store('verordnungen', this.verordnung, null, '/api/verordnungen/vorschau').then(verordnung => {
        this.verordnungSimulation = verordnung;
      })
    },
    zuzahlungsbefreitKind() {
      if (this.verordnung.zuzahlungsbefreit && this.verordnung.patient && this.verordnung.datum) {
        let a = this.$moment(this.verordnung.datum);
        let b = this.$moment(this.verordnung.patient.kontakt.geburtsdatum);
        let diff = a.diff(b, 'years', true);
        if (diff < 18) {
          this.verordnung.zuzahlungsbefreit = 1
        }
      }
    },
    fehlt(element) {
      if (this.verordnung && this.verordnung.fehlend) {
        if (this.verordnung.fehlend.includes(element)) return true;
        else return false;
      }
    }
  },
  mounted() {
    if (this.verordnung.fehlend === null) this.verordnung.fehlend = [];
    this.simulieren();
  },
}
