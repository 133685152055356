export default {
  mixins: [],
  props: [],
  data() {
    return {
      ausgewaehlteDatei: null,
      error: null,
    }
  },
  computed: {
    vorschauUrl() {
      return '/api/dateiversionen/'+this.ausgewaehlteDatei.aktuelle_version.id+'/download';
    },
  },
  watch: {},
  methods: {
    auswaehlen(datei) {
      this.ausgewaehlteDatei = datei;
      this.$emit('select', datei);
      this.$emit('update:dateiversion_id', (datei) ? datei.aktuelle_version_id : null);
    },
    abrufen(model_type, model_id) {
      this.$$fill('dateien', {with: ['aktuelle_version.user.model']});
      this.$$abrufen('dateien', null, '/api/modeldateien?model_type='+model_type+'&model_id='+model_id).then(response => {
      if (this.dateiversion_id) {
        this.ausgewaehlteDatei = response.find(el => el.aktuelle_version_id == this.dateiversion_id);
      }
      else if (response.length == 1) {
        this.auswaehlen(response[0]);
      }
    });
    },
    versenden(datei) {
      this.$nuxt.$emit('display-datei-senden', datei);
    },
    archivieren(datei) {
      this.$confirm('Datei archivieren?').then(response => {
        if (response) this.$$delete('dateien', datei.id).then(response => {
          this.abrufen();
        })
      })
    },
  },
  mounted() {},

}
