
import kalenderdata from '~/mixins/kalender/kalenderdata';
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale'
export default {
  components: {Datepicker},
  mixins: [kalenderdata],
  props: ['mitarbeiter', 'datum'],
  data() {
    return {
      de: de, // Für Übersetzung vom Datepicker
      datumZwischenspeicher: this.datum,
    }
  },
  computed: {
    tagesabschlussDaten() {
      if (!this.$$filled('tagesabschluesse')) return null;
      return this.$$data('tagesabschluesse').map(el => this.$moment(el.datum).format('YYYY-MM-DD'));
    },
    datePickerHighlighted() {
      return {customPredictor: (date) => {
        if (!this.tagesabschlussDaten) return false;
        return !this.tagesabschlussDaten.includes(this.$moment(date).format('YYYY-MM-DD'));
      }}
    },
  },
  watch: {
    datum(neu, alt) {
      if (neu != alt) this.updateDatum(neu, false);
    },
  },
  methods: {
    kalenderFuellen() {
      this.kalender.display.tagesabschluss = false;
      this.kalender.display.abwesendheiten = false;
      let vorplanung = this.$copy(this.kalender.leereSpalte);
      vorplanung.id = this.$uuid.v1();
      vorplanung.object = this.mitarbeiter;
      vorplanung.model_id = this.mitarbeiter.id;
      vorplanung.model_type = "Mitarbeiter";
      vorplanung.titel = 'Vorplanung'
      vorplanung.filter.mitarbeiter_id.push(this.mitarbeiter.id);
      vorplanung.filter.datum = this.datumZwischenspeicher;
      this.kalender.spalten.push(vorplanung);

      let arbeitsstunden = this.$copy(vorplanung);
      arbeitsstunden.filter.arbeitsstunden = true;
      arbeitsstunden.filter.termine = false;
      arbeitsstunden.titel = "Arbeitszeiten"
      this.kalender.spalten.push(arbeitsstunden);
    },
    updateDatum(datum, event = false) {
      this.datumZwischenspeicher = this.$moment(datum).format('YYYY-MM-DD');
      if (event) this.$emit('datum', this.datumZwischenspeicher);
      this.kalender.spalten.forEach(spalte => {
        spalte.filter.datum = this.datumZwischenspeicher;
        spalte.key++;
      });
    },
    tagesabschlussAbrufen() {
      this.$$abrufen('tagesabschluesse');
    }
  },
  mounted() {
    this.$$fill('tagesabschluesse', {perPage: 365, filter: [{where: ['mitarbeiter_id', this.mitarbeiter.id]}]});
    this.tagesabschlussAbrufen();
    this.datumZwischenspeicher = this.datum;
    this.kalenderFuellen();
  },
}
