
export default {
  mixins: [],
  props: ['fehlend', 'value', 'form'],
  data() {
    return {
      fehlendVal: this.fehlend,
      touched: false,
      verordnung: this.value,
      feldname: 'icdcode',
    }
  },
  computed: {
    leer() {
      if (!this.verordnung.icdcode && !this.verordnung.icdcode_2) return true;
      return false;
    }
  },
  watch: {
    fehlend(neu) {
      this.fehlendVal = neu;
    }
  },
  methods: {
    fehlt() {
      this.verordnung.icdcode = null;
      this.verordnung.icdcode_2 = null;
      this.$emit('update:fehlend', this.fehlendVal);
      this.emit();
    },
    emit() {
      this.touched = true;
      this.$emit('input', this.verordnung);
    },
    touch() {
      this.touched = true;
    }
  },
  mounted() {
    if (this.form) {
      this.$nuxt.$on('formsubmit-'+this.form, this.touch);
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('formsubmit-'+this.form, this.touch);
  }

}
