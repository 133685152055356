export default {
  methods: {
    termineAufbereiten(termineProp) {
      if (!termineProp) return null;
      let termine = _.sortBy(termineProp, 'von');
      termine = termine.map(termin => {termin.ueberschneidung = 0; termin.ueberschneidungen = []; termin.ueberschneidungen_anzahl = 1; return termin;});

      for (let index = 0; index < termine.length; index++) {
        if (index > 0) {
          // Check Überschneidung
          if (
            (termine[index-1].bis > termine[index].von && termine[index-1].von < termine[index].von)
            || (termine[index-1].bis > termine[index].von && termine[index-1].von < termine[index].bis)
            ) {
            // Vorheriger hatte schon eine Überschneidung
            termine = this.vorherigenAnpassen(termine, index);
            termine[index-1].ueberschneidungen_anzahl++;
            termine[index-1].ueberschneidungen.push(termine[index])
            termine[index].ueberschneidungen_anzahl++;

            // Aktueller Termin kriegt Wert vorheriger plus 1
            termine[index].ueberschneidung = termine[index-1].ueberschneidung + 1;
            termine[index].ueberschneidungen_anzahl = termine[index-1].ueberschneidungen_anzahl;
          }
        }
      }
      return termine;
    },
    vorherigenAnpassen(termine, index) {
      if (termine[index-1] && termine[index-1].ueberschneidungen_anzahl > 1 && termine[index-2]) {
        // Prüfen ob sich aktueller Termin auch mit index-1 überschneidet (?)
        if (
          (termine[index-2].bis > termine[index].von && termine[index-2].von < termine[index].von)
          || (termine[index-2].bis > termine[index].von && termine[index-2].von < termine[index].bis)
          ) {
            termine[index-2].ueberschneidungen_anzahl++;
            termine[index-2].ueberschneidungen.push(termine[index])
            this.vorherigenAnpassen(termine, index-1);
          }
        }
        return termine;
    },

    arbeitsstundenAufbereiten(arbeitsstunden, abgeschlossen = false, spalte = null) {
      if (!arbeitsstunden || arbeitsstunden.length == 0 && spalte) {
        let onlybox = {ueberschneidung: 0, ueberschneidungen_anzahl: 1, mitarbeiter_id: (this.mitarbeiter ? this.mitarbeiter.id : null), von: this.$moment(spalte.filter.datum+' 12:00').format(), bis: this.$moment(spalte.filter.datum+' 14:00').format(), id: 'addBox'+this.$uuid.v1(), trigger_type: 'addbox'};
        return[onlybox];
      }
      arbeitsstunden = this.termineAufbereiten(arbeitsstunden);
      if (abgeschlossen) return arbeitsstunden;

      // Wenn noch nicht abgeschlossen, werden Addboxen eingefügt
      // Vorne + Hinten einfügen
      let firstBox = {ueberschneidung: 0, ueberschneidungen_anzahl: 1, mitarbeiter_id: (this.mitarbeiter ? this.mitarbeiter.id : null),
                      von: this.$moment(arbeitsstunden[0].von).subtract(60, 'minutes').format(), bis: arbeitsstunden[0].von, id: 'addBox-first'+this.$uuid.v1(), trigger_type: 'addbox'};
      let lastBox = {ueberschneidung: 0, ueberschneidungen_anzahl: 1, mitarbeiter_id: (this.mitarbeiter ? this.mitarbeiter.id : null),
                      bis: this.$moment(arbeitsstunden[arbeitsstunden.length-1].bis).add(60, 'minutes').format(), von: arbeitsstunden[arbeitsstunden.length-1].bis, id: 'addBox-last'+this.$uuid.v1(), trigger_type: 'addbox'};
      arbeitsstunden.unshift(firstBox);
      arbeitsstunden.push(lastBox);

      // Zwischen alle Termine noch was hinzufügen
      // For Schleifen Länge erst bestimmen, damit die Boxen die dazwischen kommen nicht wieder Boxen dazwischen kriegen...
      let laenge = this.$copy(arbeitsstunden.length);
      for (let index = 0; index < laenge; index++) {
        if (arbeitsstunden[index+1] && arbeitsstunden[index+1].von > arbeitsstunden[index].bis) {
          let addButton = {ueberschneidung: 0, ueberschneidungen_anzahl: 1, mitarbeiter_id: (this.mitarbeiter ? this.mitarbeiter.id : null), von: arbeitsstunden[index].bis, bis: arbeitsstunden[index+1].von, trigger_type: 'addbox', id: 'addpush'+index};
          arbeitsstunden.push(addButton);
        }

      }

      return _.sortBy(arbeitsstunden, 'von');

    }
  },
}
