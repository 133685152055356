
export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    start() {
      this.$$abrufen('standorte');
      this.$bvModal.show('standorte-modal');
    },

  },
  mounted() {
    this.$nuxt.$on('display-standorte', this.start);
  },
  beforeDestroy() {
    this.$nuxt.$off('display-standorte', this.start);
  }

}
