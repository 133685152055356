
import spalteEvent from '~/mixins/kalender/spalteEvent';
export default {
  props: ['kalender', 'spalte', 'spaltenkey'],
  mixins: [spalteEvent],
  data() {
    return {
      spaltenData: {
        termine: null,
        arbeitsstunden: null,
        key: 0,
        spalteAbgerufen: false,
        feiertag: false,
      },
      abwesend: false,
    }
  },
  computed: {},
  watch: {
    spaltenkey(neu) {
      this.spaltenData.termine = null;
      this.spaltenData.spalteAbgerufen = false;
      this.abrufen();
    },
  },
  methods: {
    abrufen() {
      this.$$abbrechen('kalenderspalte-termine');
      this.$$abbrechen('kalenderspalte-arbeitsstunden');
      this.registerEvents(this.spalte.filter.datum);
      if (this.spalte.filter.termine) {
        this.$$update('kalenderspalte-termine', this.spalte, null, '/api/kalender/termine').then(response => {
          if (response.min_uhrzeit && response.min_uhrzeit < this.kalender.meta.von) this.$emit('min-uhrzeit', response.min_uhrzeit);
          if (response.max_uhrzeit && response.max_uhrzeit > this.kalender.meta.bis) this.$emit('max-uhrzeit', response.max_uhrzeit);
          this.spaltenData.termine = response.termine;
          this.spaltenData.key++;
          this.spaltenData.spalteAbgerufen = true;
        });
      }
      if (this.spalte.filter.arbeitsstunden) {
        this.$$update('kalenderspalte-arbeitsstunden', this.spalte, null, '/api/kalender/arbeitsstunden').then(response => {
          if (response.min_uhrzeit && response.min_uhrzeit < this.kalender.meta.von) this.$emit('min-uhrzeit', response.min_uhrzeit);
          if (response.max_uhrzeit && response.max_uhrzeit > this.kalender.meta.bis) this.$emit('max-uhrzeit', response.max_uhrzeit);
          this.spaltenData.arbeitsstunden = response.arbeitsstunden;
          this.spaltenData.key++;
          this.spaltenData.spalteAbgerufen = true;
        });
      }

    },
  },
  mounted() {
    this.abrufen();
  },
  beforeDestroy() {
    this.$$abbrechen('kalenderspalte-termine');
    this.$$abbrechen('kalenderspalte-arbeitsstunden');
  }
}
