export const state = () => ({
  paswordReset: false,
});

const mutations = {
  updatePasswordReset(state, val) {
    state.paswordReset = val;
  }
};

const getters = {
  status(state) {
    return state.paswordReset;
  }
};

export default {
  state,
  mutations,
  getters
}
