
export default {
  mixins: [],
  props: ['mitarbeiter_id'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    this.$$fill('urlaubskonto', {perPage: 10, with: ['urlaubstag']});
    this.$$abrufen('urlaubskonto', null, '/api/mitarbeiter/'+this.mitarbeiter_id+'/urlaubskonto')
  },

}
