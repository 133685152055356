export default {
  mixins: [],
  props: [],
  data() {
    return {
      datumAbruf: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    registerEvents(datum) {
      if (datum != this.datumAbruf) {
        if (this.datumAbruf) this.$nuxt.$off('refresh-termine-'+this.datumAbruf, this.abrufen)
        this.$nuxt.$on('refresh-termine-'+datum, this.abrufen);
      }
      this.datumAbruf = datum;
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$nuxt.$off('refresh-termine-'+this.datumAbruf, this.abrufen);
  }

}
