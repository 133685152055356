
export default {
  mixins: [],
  props: ['schreiben'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    ansehen(schreiben) {
      let url = '/api/schreiben/'+schreiben.id+'/download';
      this.$nuxt.$emit('view-pdf', {titel: schreiben.name, url: url});
    },
    perPostSenden(schreiben) {
      this.$confirm('Soll das Schreiben wirklich per Post versendet werden?').then(response => {
        if (response) {
          this.$$update('schreiben', schreiben, 'Schreiben per Post versendet', '/api/schreiben/'+schreiben.id+'/postversand').then(response => {
            this.$emit('refresh');
          })
        }
      })
    }
  },
  mounted() {},

}
