
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'anruf-fall-zuweisen',
        event: 'anruf-fall-zuweisen',
        titel: 'Anruf Fall zuweisen',
        modelname: 'Anruf',
        model: 'anrufe'
      },
    }
  },
  computed: {
    faelleNichtVerknpueft() {
      let orderBy = {'updated_at': 'asc'};
      // Offene Fälle aufsteigend, den Rest absteigend
      let filter = [
          // Keine geschlossenen Fälle
          {where: ['status', '!=', 2]}
        ];
      return {filter: filter, with: ['letzteAenderungModel', 'betreffender', 'bearbeiter', 'termin'], perPage: 30, orderBy: orderBy};
    },
    faelleVerknpueft() {
      let orderBy = {'updated_at': 'asc'};
      // Offene Fälle aufsteigend, den Rest absteigend
      let filter = [];
      if (this.model) {
        filter.push({whereIn: ['id', this.model.faelle.map(el => el.id)]});
      }
      return {filter: filter, with: ['letzteAenderungModel', 'betreffender', 'bearbeiter', 'termin'], perPage: 30, orderBy: orderBy};
    }
  },
  watch: {},
  methods: {
    start(params) {
      let model = params.model;
      this.$$fill('anrufe', {with: ['faelle']});
      this.$$find('anrufe', model.id).then(model => {
        this.model = model;
      })
      this.$bvModal.show(this.modalid);
    },
    verknuepfen(fall) {
      this.model.faelle.push(fall);
      this.$$update('anrufe', this.model, 'Anruf aktualisiert');
    },
    entknuepfen(fall) {
      this.model.faelle = this.model.faelle.filter(el => el.id != fall.id);
      this.$$update('anrufe', this.model, 'Anruf aktualisiert');
    }
  },

}
