
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'modalid',
      modaltitel: '',
      event: 'display-avatar',
      url: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(url) {
      this.url = url;
      this.$bvModal.show(this.modalid);
    },
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
