export default {
  computed: {
    heilmittelkatalog() {
      return [
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'ST1',
              bezeichnung: 'Organisch bedingte Erkrankungen der Stimme',
              leitsymptomatik: {
                a: 'Schädigung der Stimme mit eingeschränkter stimmlicher Belastbarkeit\nz.B. \n- Lautstärke Ausdauer\n-Tonhöhe und -umfang\n- Druck und Schmerz',
                b: 'Schädigung der Stimme mit Heiserkeit, Beeinträchtigung des Stimmklangs\nz.B.\n- Kombination von Rauigkeit und Behauchtheit\n- Heiserkeit bis zur Aphonie',
                c: 'Schädigung der Stimme mit gestörter Phonationsatmung\nz.B.\n- zu hoher Luftverbrauch beim Sprechen'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Stimmtherapie-Gruppe']},
                einzel: {'heilmittel': ['Stimmtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'G20.2', 'G20.20', 'G20.21',
                'G20.22', 'G20.23', 'G20.24', 'G20.25', 'G20.26', 'G20.27', 'G20.28', 'G20.29',  // nicht mehr drin seit 01.01.2023
                'T20.3', 'T20.7'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0','C70.1','C70.9',
                'C71.0','C71.1','C71.2','C71.3','C71.4','C71.5','C71.6','C71.7','C71.8','C71.9',
                'C72.0','C72.1','C72.2','C72.3','C72.4','C72.5','C72.8','C72.9',
                'G35.0',
                'G35.1', 'G35.10', 'G35.11',
                'G35.12', 'G35.13', 'G35.14', 'G35.15', 'G35.16', 'G35.17', 'G35.18', 'G35.19', // nicht mehr drin seit 01.01.2023
                'G35.2', 'G35.20', 'G35.21',
                'G35.22', 'G35.23', 'G35.24', 'G35.25', 'G35.26', 'G35.27', 'G35.28', 'G35.29', // nicht mehr drin seit 01.01.2023
                'G35.3', 'G35.30', 'G35.31',
                'G35.32', 'G35.33', 'G35.34', 'G35.35', 'G35.36', 'G35.37', 'G35.38', 'G35.39', // nicht mehr drin seit 01.01.2023
                'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',
                'I60.0','I60.1','I60.2','I60.3','I60.4','I60.5','I60.6','I60.7','I60.8','I60.9',
                'I61.0', 'I61.1', 'I61.2', 'I61.3', 'I61.4', 'I61.5', 'I61.6', 'I61.8', 'I61.9',
                'I63.0','I63.1','I63.2','I63.3','I63.4','I63.5','I63.6','I63.8','I63.9',
                'I64',
                'I69.0','I69.1','I69.2','I69.3','I69.4','I69.8',
                ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'ST2',
              bezeichnung: 'Funktionell bedingte Erkrankung der Stimme',
              leitsymptomatik: {
                a: 'Schädigung der Stimme mit eingeschränkter stimmlicher Belastbarkeit\nz.B.\n- Lautstärke, Ausdauer\n- Tonhöhe und -umfang\n- Druck und Schmerz',
                b: 'Schädigung der Stimme mit Heiserkeit, Beeinträchtigung des Stimmklangs\nz.B.\n- Kombination von Rauigkeit und Behauchtheit\n- Heiserkeit bis zur Aphonie',
                c: 'Schädigung der Stimme mit gestörter Phonationsatmung\nz.B.\n- zu hoher Luftverbrauch beim Sprechen'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Stimmtherapie']},
                einzel: {'heilmittel': ['Stimmtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'ST3',
              bezeichnung: 'Psychogene Aphonie',
              leitsymptomatik: {
                a: 'plötzlich eingetretene Stimmlosigkeit mit tonalem Husten/Räuspern\nz.B.\n- infolge akuter oder chronischer psychischer Belastungen'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Stimmtherapie']},
                einzel: {'heilmittel': ['Stimmtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 10,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'ST4',
              bezeichnung: 'Psychogene Dysphonie',
              leitsymptomatik: {
                a: 'Schädigung der Stimme mit langsam progredienter Heiserkeit mit tonalem Husten/Räuspern'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Stimmtherapie']},
                einzel: {'heilmittel': ['Stimmtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP1',
              bezeichnung: 'Störungen der Sprache vor Abschluss der Sprachentwicklung',
              leitsymptomatik: {
                a: 'Schädigung der kognitiv-sprachlichen Funktionen\n- mit nicht altersgemäß entwickeltem Wortschatz\n- mit nicht altersgemäß entwickeltem Satzbau und/oder morphologischer Regelbildung\n- mit nicht altersgemäß entwickeltem Sprach- verständnis',
                b: 'Schädigung der Sprechfunktionen\n- der Artikulation\n- des Redeflusses',
                c: 'Schädigung der Hörfunktionen\n- der auditiven Merkspanne'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprech- und Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 60,
              langfristiger_heilmittelbedarf: [
                'G80.0', 'G80.1', 'G80.2', 'G80.3', 'G80.4', 'G80.8', 'G80.9',
                'Q01.0', 'Q01.1', 'Q01.2', 'Q01.8', 'Q01.9',
                'Q03.0', 'Q03.1', 'Q03.8', 'Q03.9',
                'Q04.0', 'Q04.1', 'Q04.2', 'Q04.3', 'Q04.4', 'Q04.5', 'Q04.6', 'Q04.8', 'Q04.9',
                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'Q06.0', 'Q06.1', 'Q06.2', 'Q06.3', 'Q06.4', 'Q06.8', 'Q06.9',
                'F84.0', 'F84.1', 'F.84.2', 'F84.3', 'F84.4', 'F84.5', 'F84.8',
                'F80.2','F80.21','F80.22','F80.23','F80.24','F80.25','F80.26','F80.27','F80.28','F80.29', // nicht mehr drin seit 01.01.2023
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9',
                'Q91.0', 'Q91.1', 'Q91.2', 'Q91.3', 'Q91.4', 'Q91.5', 'Q91.6', 'Q91.7',
                'Q93.3', 'Q93.5', 'Q93.4',
                'Q96.0', 'Q96.1', 'Q96.2', 'Q96.3', 'Q96.4', 'Q96.8', 'Q96.9',
                'Q99.2',
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',
                'F80.1',
                'F80.2', 'F80.20', 'F80.28',
                'F80.21', 'F80.22', 'F80.23', 'F80.24', 'F80.25', 'F80.26', 'F80.27', 'F80.29', // nicht mehr drin seit 01.01.2023
                'F83'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP2',
              bezeichnung: 'Störungen der auditiven Wahrnehmung',
              leitsymptomatik: {
                a: 'Störungen der zentralen Hörfunktionen in Form von nicht altersgemäßem Sprachverstehen im Störschall',
                b: 'Störungen der zentralen Hörfunktionen in Form von nicht altersgemäßer Sprachlautunterscheidung/ phonologischer Bewusstheit'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Sprachtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'G80.0', 'G80.1', 'G80.2', 'G80.3', 'G80.4', 'G80.8', 'G80.9'
              ],
              besonderer_verordnungsbedarf: [
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',
                'F80.1',
                'F80.2', 'F80.20', 'F80.28',
                'F80.21', 'F80.22', 'F80.23', 'F80.24', 'F80.25', 'F80.26', 'F80.27', 'F80.29', // nicht mehr drin seit 01.01.2023
                'F83'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP3',
              bezeichnung: 'Störungen der Artikulation, Dyslalie',
              leitsymptomatik: {
                a: 'Schädigung der Sprechfunktion mit Störung der altersgemäßen Aussprache einzelner/mehrerer Sprachlaute',
                b: 'Schädigung der Sprechfunktion mit Störung der altersgemäßen Mundmotorik/-sensorik',
                c: 'Schädigung der Sprachdifferenzierung\nz.B.\n- mit Störung der rezeptiven Diskrimination und der zentralen phonologischen und expressiv phonetischen Prozesse'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprech- und Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [
                'Q37.0', 'Q37.1', 'Q37.2', 'Q37.3', 'Q37.4', 'Q37.5', 'Q37.8', 'Q37.9',
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9',
                'Q99.2',
                'G60.0',
                'Q86.80',
                'Q87.0'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',
                'Q86.80', // nicht mehr drin seit 01.01.2023
                'Q87.0', // nicht mehr drin seit 01.01.2023
                'F83'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP4',
              bezeichnung: 'Störungen des Sprechens / der Sprache bei hochgradiger Schwerhörigkeit oder Taubheit',
              leitsymptomatik: {
                a: 'Schädigung der Sprech- und Sprachfunktion mit gestörter/fehlender lautsprachlicher Kommunikation'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprech- und Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie']}
              },
              maximale_verordnungsmenge: 20,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 50,
              langfristiger_heilmittelbedarf: [
                'Q37.0', 'Q37.1', 'Q37.2', 'Q37.3', 'Q37.4', 'Q37.5', 'Q37.8', 'Q37.9', // nicht mehr drin seit 01.01.2023
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9', // nicht mehr drin seit 01.01.2023
                'Q99.2', // nicht mehr drin seit 01.01.2023
                'Q86.80'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'Q86.80' // nicht mehr drin seit 01.01.2023
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP5',
              bezeichnung: 'Störungen der Sprache nach Abschluss der Sprachentwicklung - Aphasie, Dysphasien',
              leitsymptomatik: {
                a: 'Schädigungen der kognitiv-sprachlichen Funktionen\nz.B.\n- des Satzbaus, der Grammatik, der Aussprache und des Sprachverständnisses\n- der Wortfindung\n- des Lesens und Schreibens',
                b: 'Schädigung der Sprechfunktion mit Störung der Artikulation',
                c: 'Schädigung der Sprechfunktion mit Störung des Redeflusses und des Sprechtempos'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Sprachtherapie']}
              },
              maximale_verordnungsmenge: 20,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 60,
              langfristiger_heilmittelbedarf: [
                'Q01.0', 'Q01.1', 'Q01.2', 'Q01.8', 'Q01.9',
                'Q03.0', 'Q03.1', 'Q03.8', 'Q03.9',
                'Q04.0', 'Q04.1', 'Q04.2', 'Q04.3', 'Q04.4', 'Q04.5', 'Q04.6', 'Q04.8', 'Q04.9',
                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'T90.5',
                'Q99.2'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',
                'G10',
                'G35.0',
                'G35.1', 'G35.10', 'G35.11',
                'G35.12', 'G35.13', 'G35.14', 'G35.15', 'G35.16', 'G35.17', 'G35.18', 'G35.19', // nicht mehr drin seit 01.01.2023
                'G35.2', 'G35.20', 'G35.21',
                'G35.22', 'G35.23', 'G35.24', 'G35.25', 'G35.26', 'G35.27', 'G35.28', 'G35.29', // nicht mehr drin seit 01.01.2023
                'G35.3', 'G35.30', 'G35.31',
                'G35.32', 'G35.33', 'G35.34', 'G35.35', 'G35.36', 'G35.37', 'G35.38', 'G35.39', // nicht mehr drin seit 01.01.2023
                'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',
                'I60.0', 'I60.1', 'I60.2', 'I60.3', 'I60.4', 'I60.5', 'I60.6', 'I60.7', 'I60.8', 'I60.9',
                'I61.0', 'I61.1', 'I61.2', 'I61.3', 'I61.4', 'I61.5', 'I61.6', 'I61.8', 'I61.9',
                'I63.0', 'I63.1', 'I63.2', 'I63.3', 'I63.4', 'I63.5', 'I63.6', 'I63.8', 'I63.9',
                'I64',
                'I69.0', 'I69.1', 'I69.2', 'I69.3', 'I69.4', 'I69.8'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SP6',
              bezeichnung: 'Störungen der Sprechmotorik - Dysarthrie / Dysarthrophonie / Sprechapraxie',
              leitsymptomatik: {
                a: 'Schädigung der Sprechfunktion mit Störung der Artikulation',
                b: 'Schädigung der Sprechfunktion mit Störung des Redeflusses und des Sprechtempos',
                c: 'Schädigung der Stimmfunktion\nz.B.\n- prosodische Störungen\n- Heiserkeit und Lautstärkeschwankungen'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Stimm-, Sprech- und Sprachtherapie-Gruppe']},
                einzel: {'heilmittel': ['Stimm-, Sprech- und Sprachtherapie']}
              },
              maximale_verordnungsmenge: 20,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 60,
              langfristiger_heilmittelbedarf: [
                'G12.0', 'G12.1', 'G12.2', 'G12.8', 'G12.9',
                'G14',
                'G20.2', 'G20.20', 'G20.21',
                'G20.22', 'G20.23', 'G20.24', 'G20.25', 'G20.26', 'G20.27', 'G20.28', 'G20.29', // nicht mehr drin seit 01.01.2023
                'G71.0', 'G71.1', 'G71.2', 'G71.3',
                'G80.0', 'G80.1', 'G80.2', 'G80.3', 'G80.4', 'G80.8', 'G80.9',
                'Q01.0', 'Q01.1', 'Q01.2', 'Q01.8', 'Q01.9',
                'Q03.0', 'Q03.1', 'Q03.8', 'Q03.9',
                'Q04.0', 'Q04.1', 'Q04.2', 'Q04.3', 'Q04.4', 'Q04.5', 'Q04.6', 'Q04.8', 'Q04.9',
                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'Q06.0', 'Q06.1', 'Q06.2', 'Q06.3', 'Q06.4', 'Q06.8', 'Q06.9',
                'T90.5',
                'G70.2',
                'G73.6', 'G73.6*',
                'Q86.80',
                'T20.3', 'T20.7', 'T90.5'
              ],
              besonderer_verordnungsbedarf: [
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',
                'G20.1', 'G20.10', 'G20.11',
                'G20.12', 'G20.13', 'G20.14', 'G20.15', 'G20.16', 'G20.17', 'G20.18', 'G20.19', // nicht mehr drin seit 01.01.2023
                'G10',
                'G21.3', 'G21.4', 'G21.8',
                'G35.0',
                'G35.1', 'G35.10', 'G35.11',
                'G35.12', 'G35.13', 'G35.14', 'G35.15', 'G35.16', 'G35.17', 'G35.18', 'G35.19', // nicht mehr drin seit 01.01.2023
                'G35.2', 'G35.20', 'G35.21',
                'G35.22', 'G35.23', 'G35.24', 'G35.25', 'G35.26', 'G35.27', 'G35.28', 'G35.29', // nicht mehr drin seit 01.01.2023
                'G35.3', 'G35.30', 'G35.31',
                'G35.32', 'G35.33', 'G35.34', 'G35.35', 'G35.36', 'G35.37', 'G35.38', 'G35.39', // nicht mehr drin seit 01.01.2023
                'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',
                'G70.0',
                'I60.0', 'I60.1', 'I60.2', 'I60.3', 'I60.4', 'I60.5', 'I60.6', 'I60.7', 'I60.8', 'I60.9',
                'I61.0', 'I61.1', 'I61.2', 'I61.3', 'I61.4', 'I61.5', 'I61.6', 'I61.8', 'I61.9',
                'I63.0', 'I63.1', 'I63.2', 'I63.3', 'I63.4', 'I63.5', 'I63.6', 'I63.8', 'I63.9',
                'I64',
                'I69.0', 'I69.1', 'I69.2', 'I69.3', 'I69.4', 'I69.8',
                'Q86.80', // nicht mehr drin seit 01.01.2023
                'F83'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'RE1',
              bezeichnung: 'Störungen des Redeflusses - Stottern',
              leitsymptomatik: {
                a: 'Störungen des Redeflusses in Form von unfreiwilligen Wiederholungen von Lauten und Silben, Dehnungen und Blockierungen',
                b: 'Störungen des Redeflusses mit ausgeprägter Begleitsymptomatik\n- z.B. negatives Störungsbewusstsein oder Vermeidungsverhalten'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprechtherapie']},
                einzel: {'heilmittel': ['Sprechtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 50,
              langfristiger_heilmittelbedarf: [
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9',
                'Q99.2'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0','C71.1','C71.2','C71.3','C71.4','C71.5','C71.6','C71.7','C71.8','C71.9',
                'C72.0','C72.1','C72.2','C72.3','C72.4','C72.5','C72.8','C72.9'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'RE2',
              bezeichnung: 'Störungen des Redeflusses - Poltern',
              leitsymptomatik: {
                a: 'Störungen des Redeflusses mit überhasteter Sprache/undeutlicher Aussprache',
                b: 'Störungen des Redeflusses mit Temposchwankungen beim Sprechen',
                c: 'Störungen des Redeflusses mit ausgeprägtem Störungsbewusstsein, Vermeidungsverhalten'
              },
              heilmittel: {
                gruppe: {'heilmittel': ['Sprechtherapie']},
                einzel: {'heilmittel': ['Sprechtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9', // nicht mehr drin seit 01.01.2023
                'Q99.2'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0','C71.1','C71.2','C71.3','C71.4','C71.5','C71.6','C71.7','C71.8','C71.9',
                'C72.0','C72.1','C72.2','C72.3','C72.4','C72.5','C72.8','C72.9',
                'F83'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SF',
              bezeichnung: 'Störungen der Stimm- und Sprechfunktion - Rinophonie',
              leitsymptomatik: {
                a: 'Schädigung des Stimmklangs\nz.B.\n- dumpfer farbloser Stimmklang\n- zu starke/zu schwache Nasenresonanz bis hin zur nasalen Regurgitation',
                b: 'Schädigung der Sprechfunktion mit Störung der Artikulation\nz.B.\n- verwaschene Sprache',
                c: 'Schädigung der Sprechfunktion infolge einer Hyperfunktion der Kehlkopf-/Zungenmuskulatur'
              },
              heilmittel: {
                einzel: {'heilmittel': ['Sprech- und Stimmtherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'Q37.0', 'Q37.1', 'Q37.2', 'Q37.3', 'Q37.4', 'Q37.5', 'Q37.8', 'Q37.9',
                'Q87.0',
                'Q99.2'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0','C71.1','C71.2','C71.3','C71.4','C71.5','C71.6','C71.7','C71.8','C71.9',
                'C72.0','C72.1','C72.2','C72.3','C72.4','C72.5','C72.8','C72.9'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SC',
              bezeichnung: 'Krankhafte Störungen des Schluckaktes - Dysphagie (Schluckstörung)',
              leitsymptomatik: {
                a: 'Schädigung des Schluckaktes in der oralen Phase\nz.B.\n- gestörte orale Boluskontrolle, Drooling, Leaking',
                b: 'Schädigung des Schluckaktes in der pharyngealen Phase\nz.B.\n- verzögerte Auslösung des Schluckreflexes',
                c: 'Schädigung des Schluckaktes in der oesophagealen Phase\nz.B.\n- laryngeale Penetration \n- Aspiration'
              },
              heilmittel: {
                einzel: {'heilmittel': ['Schlucktherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 60,
              langfristiger_heilmittelbedarf: [
                'G12.0', 'G12.2', 'G12.8', 'G12.9',
                'G14',
                'G20.2', 'G20.20', 'G20.21',
                'G20.22', 'G20.23', 'G20.24', 'G20.25', 'G20.26', 'G20.27', 'G20.28', 'G20.29', // nicht mehr drin seit 01.01.2023
                'G71.0', 'G71.1', 'G71.2', 'G71.3',
                'G80.0', 'G80.1', 'G80.2', 'G80.3', 'G80.4', 'G80.8', 'G80.9',
                'G93.1', 'G93.80',
                'Q01.0', 'Q01.1', 'Q01.2', 'Q01.8', 'Q01.9',
                'Q03.0', 'Q03.1', 'Q03.8', 'Q03.9',
                'Q04.0', 'Q04.1', 'Q04.2', 'Q04.3', 'Q04.4', 'Q04.5', 'Q04.6', 'Q04.8', 'Q04.9',
                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'Q06.0', 'Q06.1', 'Q06.2', 'Q06.3', 'Q06.4', 'Q06.8', 'Q06.9',
                'Q87.0',
                'T90.5',
                'T20.3', 'T20.7',
                'F84.2',
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9',
                'E74.0', 'E75.0', 'E76.0',
                'G70.2',
                'G73.6', 'G73.6*',
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',
                'C70.0', 'C70.1', 'C70.9',
                'C71.0','C71.1','C71.2','C71.3','C71.4','C71.5','C71.6','C71.7','C71.8','C71.9',
                'C72.0','C72.1','C72.2','C72.3','C72.4','C72.5','C72.8','C72.9',
                'G10',
                'G20.1', 'G20.10', 'G20.11', 'G20.12', 'G20.13', 'G20.14', 'G20.15', 'G20.16', 'G20.17', 'G20.18', 'G20.19',
                'G11.0', 'G11.1', 'G11.2', 'G11.3', 'G11.4', 'G11.8', 'G11.9',
                'G21.3', 'G21.4', 'G21.8',
                'G35.0', 'G35.1', 'G35.2', 'G35.3', 'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',
                'G70.0',
                'I60.0', 'I60.1', 'I60.2', 'I60.2', 'I60.3', 'I60.4', 'I60.5', 'I60.6', 'I60.7', 'I60.8', 'I60.9',
                'I61.0', 'I61.1', 'I61.2', 'I61.3', 'I61.4', 'I61.5', 'I61.6', 'I61.8', 'I61.9',
                'I63.0', 'I63.1', 'I63.2', 'I63.3', 'I63.4', 'I63.5', 'I63.6', 'I63.8', 'I63.9', 'I64',
                'I69.0', 'I69.1', 'I69.2', 'I69.3', 'I69.4', 'I69.8',
                'M30.0', 'M31.3',
                'M33.0', 'M33.1', 'M33.2',
                'Q87.0',
                'E41',
                'R13', 'R13.0', 'R13.1', 'R13.2', 'R13.3', 'R13.4', 'R13.5', 'R13.6', 'R13.7', 'R13.8', 'R13.9',
                'R64',
                ['Z99.0', 'Z43.0'], 'Z99.1'
              ]
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SPZ',
              bezeichnung: 'Störung des Sprechens',
              leitsymptomatik: 'Störungen\n- der Lautbildung im Mund-, Kiefer-, Gesichtsbereich\n- des orofazialen Muskelgleichgewichts',
              heilmittel: {
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie oder Schlucktherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'SCZ',
              bezeichnung: 'Störungen des oralen Schluckackts',
              leitsymptomatik: 'Störungen in der oralen Phase des Schluckakts (motorisch und sensorisch)',
              heilmittel: {
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie oder Schlucktherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'logopaedie',
              indikationsschluessel: 'OFZ',
              bezeichnung: 'orofaziale Funktionsstörungen',
              leitsymptomatik: 'Störungen in der oralen Phase des Schluckakts (motorisch und sensorisch)',
              heilmittel: {
                einzel: {'heilmittel': ['Sprech- und Sprachtherapie oder Schlucktherapie']}
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: []
            },
            /**
             *
             * Ab hier Ergo               *               *               *               *               *               *               *               *               *               *               *               *               *               *               *               *               *               *
             *
             *
             */
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'SB1',
              bezeichnung: 'Erkrankungen der Wirbelsäule, Gelenke und Extremitäten (mit motorisch-funktionellen Schädigungen)',
              leitsymptomatik: {
                a: 'Schädigung der Wirbelsäulen- und Gelenkfunktion\nz.B.\n- Beweglichkeit und Stabilität der Wirbelsäule\n- Haltung und Haltungskontrolle\n- der Gelenkbeweglichkeit und -stabilität',
                b: 'Schädigung der Muskelfunktion\nz.B.\n- der Muskelkraft, -ausdauer, -tonus und -koordination - Schmerz'
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                ]},
                gruppe: {'heilmittel': 'Motorisch-funktionelle Behandlung-Gruppe'},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'M05.0', 'M05.00', 'M05.01', 'M05.02', 'M05.03', 'M05.04', 'M05.05', 'M05.06', 'M05.07', 'M05.08', 'M05.09',
                'M07.1', 'M07.10', 'M07.11', 'M07.12', 'M07.13', 'M07.14', 'M07.15', 'M07.16', 'M07.17', 'M07.18', 'M07.19',
                'M08.1', 'M08.10', 'M08.11', 'M08.12', 'M08.13', 'M08.14', 'M08.15', 'M08.16', 'M08.17', 'M08.18', 'M08.19',
                'M08.2', 'M08.20', 'M08.21', 'M08.22', 'M08.23', 'M08.24', 'M08.25', 'M08.26', 'M08.27', 'M08.28', 'M08.29',
                'M32.1', 'M32.8',
                'M34.0', 'M34.1',
                'M36.2',
                'M41.0', 'M41.00', 'M41.01', 'M41.02', 'M41.03', 'M41.04', 'M41.05', 'M41.06', 'M41.07', 'M41.08', 'M41.09',
                'M41.1', 'M41.10', 'M41.11', 'M41.12', 'M41.13', 'M41.14', 'M41.15', 'M41.16', 'M41.17', 'M41.18', 'M41.19',
                'M45.0', 'M45.00', 'M45.01', 'M45.02', 'M45.03', 'M45.04', 'M45.05', 'M45.06', 'M45.07', 'M45.08', 'M45.09',
                'Q79.6', 'Q87.4',
                'Q74.3', 'Q78.0', 'Q87.2',
                'E74.0', 'E75.0', 'E76.0',
                'F84.2',
                'Q93.3', 'Q93.5',
                'G60.8'
              ],
              besonderer_verordnungsbedarf: [
                'M05.1', 'M05.10', 'M05.11', 'M05.12', 'M05.13', 'M05.14', 'M05.15', 'M05.16', 'M05.17', 'M05.18', 'M05.19',
                'M05.2', 'M05.20', 'M05.21', 'M05.22', 'M05.23', 'M05.24', 'M05.25', 'M05.26', 'M05.27', 'M05.28', 'M05.29',
                'M05.3', 'M05.30', 'M05.31', 'M05.32', 'M05.33', 'M05.34', 'M05.35', 'M05.36', 'M05.37', 'M05.38', 'M05.39',
                'M05.8', 'M05.80', 'M05.81', 'M05.82', 'M05.83', 'M05.84', 'M05.85', 'M05.86', 'M05.87', 'M05.88', 'M05.89',
                'M05.9', 'M05.90', 'M05.91', 'M05.92', 'M05.93', 'M05.94', 'M05.95', 'M05.96', 'M05.97', 'M05.98', 'M05.99',

                'M06.0', 'M06.00', 'M06.01', 'M06.02', 'M06.03', 'M06.04', 'M06.05', 'M06.06', 'M06.07', 'M06.08', 'M06.09',
                'M06.1', 'M06.10', 'M06.11', 'M06.12', 'M06.13', 'M06.14', 'M06.15', 'M06.16', 'M06.17', 'M06.18', 'M06.19',
                'M07.0', 'M07.00', 'M07.04', 'M07.07', 'M07.09',
                'M07.2',
                'M07.3', 'M07.30', 'M07.31', 'M07.32', 'M07.33', 'M07.34', 'M07.35', 'M07.36', 'M07.37', 'M07.38', 'M07.39',
                'M07.4', 'M07.40', 'M07.41', 'M07.42', 'M07.43', 'M07.44', 'M07.45', 'M07.46', 'M07.47', 'M07.48', 'M07.49',
                'M07.5', 'M07.50', 'M07.51', 'M07.52', 'M07.53', 'M07.54', 'M07.55', 'M07.56', 'M07.57', 'M07.58', 'M07.59',
                'M07.6', 'M07.60', 'M07.61', 'M07.62', 'M07.63', 'M07.64', 'M07.65', 'M07.66', 'M07.67', 'M07.68', 'M07.69',
                'M08.0', 'M08.00', 'M08.01', 'M08.02', 'M08.03', 'M08.04', 'M08.05', 'M08.06', 'M08.07', 'M08.08', 'M08.09',
                'M08.3',
                'M08.4', 'M08.40', 'M08.41', 'M08.42', 'M08.43', 'M08.44', 'M08.45', 'M08.46', 'M08.47', 'M08.48', 'M08.49',
                'M08.7', 'M08.70', 'M08.71', 'M08.72', 'M08.73', 'M08.74', 'M08.75', 'M08.76', 'M08.77', 'M08.78', 'M08.79',
                'M08.8', 'M08.80', 'M08.81', 'M08.82', 'M08.83', 'M08.84', 'M08.85', 'M08.86', 'M08.87', 'M08.88', 'M08.89',
                'M08.9', 'M08.90', 'M08.91', 'M08.92', 'M08.93', 'M08.94', 'M08.95', 'M08.96', 'M08.97', 'M08.98', 'M08.99',

                'M30.0', 'M31.3',
                'M33.0', 'M33.1', 'M33.2',
                'M34.2', 'M34.8', 'M34.9',
                'M41.2', 'M41.20', 'M41.21', 'M41.22', 'M41.23', 'M41.24', 'M41.25', 'M41.26', 'M41.27', 'M41.28', 'M41.29',
                'M41.5', 'M41.50', 'M41.51', 'M41.52', 'M41.53', 'M41.54', 'M41.55', 'M41.56', 'M41.57', 'M41.58', 'M41.59',
                'U09.9',
                ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'SB2',
              bezeichnung: 'Erkrankungen der Wirbelsäule, Gelenke und Extremitäten (mit motorisch-funktionellen und sensomotorisch-perzeptiven Schädigungen)',
              leitsymptomatik: {
                a: 'Schädigung der Wirbelsäulen- und Gelenkfunktion\nz.B.\n- der diskoligamentären Strukturen (z.B. Instabilität, Hypermobilität)\n- Haltung und Haltungskontrolle\n- der Gelenkbeweglichkeit und -stabilität',
                b: 'Schädigung der Muskelfunktion\nz.B.\n- der Muskelkraft, -ausdauer, -tonus und -koordination\n- Schmerz',
                c: 'Schädigung der Sinnes- und Bewegungsfunktionen\nz.B.\n- Körperwahrnehmung/Sensibilität\n- Koordination\n- Grob- und Feinmotorik'
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                  'Sensomotorisch-perzeptive Behandlung',
                  'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene',
                ]},
                gruppe: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [
                'Q79.6',
                'Q71.0', 'Q71.1', 'Q71.2', 'Q71.3', 'Q71.4', 'Q71.5', 'Q71.6', 'Q71.8', 'Q71.9',
                'Q72.0', 'Q72.1', 'Q72.2', 'Q72.3', 'Q72.4', 'Q72.5', 'Q72.6', 'Q72.7', 'Q72.8', 'Q72.9',
                'Q73.0', 'Q73.1', 'Q73.8',
                'Q87.0', 'Q87.2',
                'G60.0',
                'G60.8',
                'Z89.3', 'Z89.7', 'Z89.8',
                'Q93.3', 'Q93.5',
                'T20.3', 'T20.7',
                'T21.3', 'T21.30', 'T21.31', 'T21.32', 'T21.33', 'T21.34', 'T21.35', 'T21.39',
                'T21.7', 'T21.70', 'T21.71', 'T21.72', 'T21.73', 'T21.74', 'T21.75', 'T21.79',
                'T22.3', 'T22.30', 'T22.31', 'T22.32', 'T22.33',
                'T22.7', 'T22.70', 'T22.71', 'T22.72', 'T22.73',
                'T23.3', 'T23.7', 'T24.3', 'T24.7', 'T25.3', 'T25.7', 'T29.3', 'T29.7'

              ],
              besonderer_verordnungsbedarf: [
                'M89.0', 'M89.00', 'M89.01', 'M89.02', 'M89.03', 'M89.04', 'M89.05', 'M89.06', 'M89.07', 'M89.08', 'M89.09',
                'G90.5', 'G90.51', 'G90.59',
                'G90.6', 'G90.60', 'G90.61', 'G90.69',
                'G90.7', 'G90.70', 'G90.71', 'G90.79',
                'M23.5', 'M23.50', 'M23.51', 'M23.52', 'M23.53', 'M23.54', 'M23.57', 'M23.59',
                'Q66.0',
                ['M23.5', 'Z98.8'], ['M24.41', 'Z98.8'], ['Z96.60', 'Z98.8'], ['Z96.64', 'Z98.8'], ['Z96.65', 'Z98.8'],
                'Z89.1', 'Z89.2', 'Z89.5', 'Z89.6',
                ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'SB3',
              bezeichnung: 'System- und Autoimmunerkrankungen mit Bindegewebe-, Muskel- und Gefäßbeteiligung (mit motorisch-funktionellen/ sensomotorisch-perzeptiven Schädigungen)',
              leitsymptomatik: {
                a: 'Schädigung der Gelenkfunktionen\nz.B.\n- der Gelenkbeweglichkeit und -stabilität',
                b: 'Schädigung der Muskelfunktion\nz.B.\n- der Muskelkraft, -ausdauer, -tonus und -koordination\n- Schmerz',
                c: 'Schädigung der Sinnes- und Bewegungsfunktionen\nz.B.\n- Körperwahrnehmung/Sensibilität\n- Koordination\n- Grob- und Feinmotorik'
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                  'Sensomotorisch-perzeptive Behandlung',
                  'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene',
                ]},
                gruppe: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 30,
              langfristiger_heilmittelbedarf: [
                'G12.0', 'G12.1', 'G12.2', 'G12.8', 'G12.9',
                'G71.0', 'G71.1', 'G71.2', 'G71.3',
                'M32.1', 'M32.8',
                'M34.0', 'M34.1',
                'Q87.4', 'Q99.2',
                'Q74.0', 'E75.0', 'E76.0',
                'G70.2',
                'G73.6', 'G73.6*',
                'E74.0', 'E75.0', 'E76.0', 'F84.2',
              ],
              besonderer_verordnungsbedarf: [
                'G70.0',
                'M30.0', 'M31.3',
                'M33.0', 'M33.1', 'M33.2',
                'M34.2', 'M34.8', 'M34.9',
                'Q68.0'
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'EN1',
              bezeichnung: 'ZNS-Erkrankungen (Gehirn) Entwicklungsstörungen',
              leitsymptomatik: {
                a: 'Schädigung der Bewegungsfunktionen\n- unwillkürliche Bewegungsreaktion (z.B. Gleichgewicht)\n- Kontrolle von Willkürbewegungen (z.B. Grobund Feinmotorik, Koordination)\n- Funktion der Muskelkraft, -tonus, (z.B.\nHemi-, Tetraparese, Spastik)',
                b: 'Schädigung der Sinnesfunktionen\n- Gesichtsfeld, Körperwahrnehmung\n- Sensibilität, Propiozeption',
                c: 'Schädigung der mentalen Funktionen\n- Aufmerksamkeit, Gedächtnis\n- Psychomotorik, Wahrnehmung\n- Höhere kognitive Funktionen'
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                  'Sensomotorisch-perzeptive Behandlung',
                  'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene',
                  'Hirnleistungstraining/ neuropsychologisch orientierte Behandlung',
                  'Psychisch-funktionelle Behandlung',
                ]},
                gruppe: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe',
                  'Hirnleistungstraining-Gruppe',
                  'Psychisch-funktionelle Behandlung-Gruppe',
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [
                'G14',
                'G20.2', 'G20.20', 'G20.21',
                'G20.22', 'G20.23', 'G20.24', 'G20.25', 'G20.26', 'G20.27', 'G20.28', 'G20.29', // nicht mehr drin seit 01.01.2023
                'G80.0', 'G80.1', 'G80.2', 'G80.3', 'G80.4', 'G80.8', 'G80.9',
                'G82.0', 'G82.00', 'G82.01', 'G82.02', 'G82.03', 'G82.09',
                'G82.04', 'G82.05', 'G82.06', 'G82.07', 'G82.08', // nicht mehr drin seit 01.01.2023
                'G82.1', 'G82.10', 'G82.11', 'G82.12', 'G82.13', 'G82.19',
                'G82.14', 'G82.15', 'G82.16', 'G82.17', 'G82.18', // nicht mehr drin seit 01.01.2023
                'G82.2', 'G82.20', 'G82.21', 'G82.22', 'G82.23', 'G82.29',
                'G82.24', 'G82.25', 'G82.26', 'G82.27', 'G82.28', // nicht mehr drin seit 01.01.2023
                'G82.3', 'G82.30', 'G82.31', 'G82.32', 'G82.33', 'G82.39',
                'G82.34', 'G82.35', 'G82.36', 'G82.37', 'G82.38', // nicht mehr drin seit 01.01.2023
                'G82.4', 'G82.40', 'G82.41', 'G82.42', 'G82.43', 'G82.49',
                'G82.44', 'G82.45', 'G82.46', 'G82.47', 'G82.48', // nicht mehr drin seit 01.01.2023
                'G82.5', 'G82.50', 'G82.51', 'G82.52', 'G82.53', 'G82.59',
                'G82.54', 'G82.55', 'G82.56', 'G82.57', 'G82.58', // nicht mehr drin seit 01.01.2023
                'G91.2', 'G91.20', 'G91.21', 'G91.22', 'G91.29',
                'G91.23', 'G91.24', 'G91.25', 'G91.26', 'G91.27', 'G91.28', // nicht mehr drin seit 01.01.2023
                'G93.1', 'G93.80',
                'G95.0',
                'Q01.0', 'Q01.1', 'Q01.2', 'Q01.8', 'Q01.9',
                'Q03.0', 'Q03.1', 'Q03.8', 'Q03.9',
                'Q04.0', 'Q04.1', 'Q04.2', 'Q04.3', 'Q04.4', 'Q04.5', 'Q04.6', 'Q04.8', 'Q04.9',
                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'Q90.0', 'Q90.1', 'Q90.2', 'Q90.9',
                'Q91.0', 'Q91.1', 'Q91.2', 'Q91.3', 'Q91.4', 'Q91.5', 'Q91.6', 'Q91.7',
                'Q93.4', 'Q93.5',
                'Q96.0', 'Q96.1', 'Q96.2', 'Q96.3', 'Q96.4', 'Q96.8', 'Q96.9',
                'Q99.2',
                'T90.5',
                'F84.0', 'F84.1', 'F84.2', 'F84.3', 'F84.4', 'F84.5', 'F84.8',
                'E74.0', 'E75.0', 'E76.0',
                'G71.3'
              ],
              besonderer_verordnungsbedarf: [
                'B94.1',

                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',

                'G10',
                'G11.0', 'G11.1', 'G11.2', 'G11.3', 'G11.4', 'G11.8', 'G11.9',
                'G20.1', 'G20.10', 'G20.11',
                'G21.3', 'G21.4', 'G21.8',
                'G35.0', 'G35.1', 'G35.10', 'G35.11',
                'G35.12', 'G35.13', 'G35.14', 'G35.15', 'G35.16', 'G35.17', 'G35.18', 'G35.19', // nicht mehr drin seit 01.01.2023
                'G35.2', 'G35.20', 'G35.21',
                'G35.22', 'G35.23', 'G35.24', 'G35.25', 'G35.26', 'G35.27', 'G35.28', 'G35.29', // nicht mehr drin seit 01.01.2023
                'G35.3', 'G35.30', 'G35.31',
                'G35.32', 'G35.33', 'G35.34', 'G35.35', 'G35.36', 'G35.37', 'G35.38', 'G35.39', // nicht mehr drin seit 01.01.2023
                'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',
                'G70.0',
                'G81.0', 'G81.1',

                'I60.0', 'I60.1', 'I60.2', 'I60.3', 'I60.4', 'I60.5', 'I60.6', 'I60.7', 'I60.8', 'I60.9',
                'I61.0', 'I61.1', 'I61.2', 'I61.3', 'I61.4', 'I61.5', 'I61.6', 'I61.8', 'I61.9',
                'I63.0', 'I63.1', 'I63.2', 'I63.3', 'I63.4', 'I63.5', 'I63.6', 'I63.8', 'I63.9', 'I64',
                'I69.0', 'I69.1', 'I69.2', 'I69.3', 'I69.4', 'I69.8',

                'S14.0',
                'S14.1', 'S14.10', 'S14.11', 'S14.12', 'S14.13',
                'S14.14', 'S14.15', 'S14.16', 'S14.17', 'S14.18', 'S14.19', // nicht mehr drin seit 01.01.2023
                'S14.2','S14.3', 'S14.4', 'S14.5', 'S14.6',
                'S24.0',
                'S24.1', 'S24.10', 'S24.11', 'S24.12',
                'S24.13', 'S24.14', 'S24.15', 'S24.16', 'S24.17', 'S24.18', 'S24.19', // nicht mehr drin seit 01.01.2023
                'S24.2', 'S24.3', 'S24.4', 'S24.5', 'S24.6',
                'S34.0',
                'S34.1', 'S34.10', 'S34.11',
                'S34.12', 'S34.13', 'S34.14', 'S34.15', 'S34.16', 'S34.17', 'S34.18', 'S34.19', // nicht mehr drin seit 01.01.2023
                'S34.2',
                'S34.3', 'S34.30', 'S34.31', 'S34.38',
                'S34.32', 'S34.33', 'S34.34', 'S34.35', 'S34.36', 'S34.37', 'S34.39', // nicht mehr drin seit 01.01.2023
                'S34.4', 'S34.5', 'S34.6', 'S34.8',

                'M30.0', 'M31.3',
                'M33.0', 'M33.1', 'M33.2',

                'F83',

                ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
            ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'EN2',
              bezeichnung: 'ZNS-Erkrankungen (Rückenmark)/ Neuromuskuläre Erkrankungen',
              leitsymptomatik: {
                a: 'Schädigung der Bewegungsfunktionen\n- Funktion der Muskelkraft, -tonus (z.B. Para-, Tetraparese, Muskelhypertonie/ -hypotonie)\n- Kontrolle von Willkürbewegungen (z.B. Koordination)',
                b: 'Schädigung der Sinnesfunktionen\n- Sensibilität, Propiozeption (z.B. Temperatur, Tiefensensibilität)',
                c: 'Schädigung der mentalen Funktionen\n- psychosoziale und emotionale Funktionen'
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                  'Sensomotorisch-perzeptive Behandlung',
                  'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene',
                  'Psychisch-funktionelle Behandlung',
                ]},
                gruppe: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe',
                  'Psychisch-funktionelle Behandlung-Gruppe',
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [
                'G12.0', 'G12.1', 'G12.2', 'G12.8', 'G12.9',
                'G14', 'G71.0',
                'G82.0', 'G82.00', 'G82.01', 'G82.02', 'G82.03', 'G82.09',
                'G82.04', 'G82.05', 'G82.06', 'G82.07', 'G82.08', // nicht mehr drin seit 01.01.2023
                'G82.1', 'G82.10', 'G82.11', 'G82.12', 'G82.13', 'G82.19',
                'G82.14', 'G82.15', 'G82.16', 'G82.17', 'G82.18', // nicht mehr drin seit 01.01.2023
                'G82.2', 'G82.20', 'G82.21', 'G82.22', 'G82.23', 'G82.29',
                'G82.24', 'G82.25', 'G82.26', 'G82.27', 'G82.28', // nicht mehr drin seit 01.01.2023
                'G82.3', 'G82.30', 'G82.31', 'G82.32', 'G82.33', 'G82.39',
                'G82.34', 'G82.35', 'G82.36', 'G82.37', 'G82.38', // nicht mehr drin seit 01.01.2023
                'G82.4', 'G82.40', 'G82.41', 'G82.42', 'G82.43', 'G82.49',
                'G82.44', 'G82.45', 'G82.46', 'G82.47', 'G82.48', // nicht mehr drin seit 01.01.2023
                'G82.5', 'G82.50', 'G82.51', 'G82.52', 'G82.53', 'G82.59',
                'G82.54', 'G82.55', 'G82.56', 'G82.57', 'G82.58', // nicht mehr drin seit 01.01.2023
                'G95.0',

                'Q05.0', 'Q05.1', 'Q05.2', 'Q05.3', 'Q05.4', 'Q05.5', 'Q05.6', 'Q05.7', 'Q05.8', 'Q05.9',
                'Q06.0', 'Q06.1', 'Q06.2', 'Q06.3', 'Q06.4', 'Q06.8', 'Q06.9',

              ],
              besonderer_verordnungsbedarf: [
                'C70.0', 'C70.1', 'C70.9',
                'C71.0', 'C71.1', 'C71.2', 'C71.3', 'C71.4', 'C71.5', 'C71.6', 'C71.7', 'C71.8', 'C71.9',
                'C72.0', 'C72.1', 'C72.2', 'C72.3', 'C72.4', 'C72.5', 'C72.8', 'C72.9',

                'G35.0',
                'G35.1', 'G35.10', 'G35.11',
                'G35.2', 'G35.20', 'G35.21',
                'G35.3', 'G35.30', 'G35.31',
                'G35.9',
                'G36.0', 'G36.1', 'G36.8', 'G36.9',
                'G37.0', 'G37.1', 'G37.2', 'G37.3', 'G37.4', 'G37.5', 'G37.8', 'G37.9',

                'S14.0',
                'S14.1', 'S14.10', 'S14.11', 'S14.12', 'S14.13',
                'S14.2', 'S14.3', 'S14.4', 'S14.5', 'S14.6',
                'S24.0',
                'S24.1', 'S24.10', 'S24.11', 'S24.12',
                'S24.2', 'S24.3', 'S24.4', 'S24.5', 'S24.6',
                'S34.0',
                'S34.1', 'S34.10', 'S34.11', 'S34.18',
                'S34.2',
                'S34.3', 'S34.30', 'S34.31', 'S34.38',
                'S34.4', 'S34.5', 'S34.6', 'S34.8',

                'T09.3',

                ['M47.0', 'G99.2'], ['M47.00', 'G99.2'], ['M47.01', 'G99.2'], ['M47.02', 'G99.2'], ['M47.03', 'G99.2'], ['M47.04', 'G99.2'], ['M47.05', 'G99.2'], ['M47.06', 'G99.2'], ['M47.07', 'G99.2'], ['M47.08', 'G99.2'], ['M47.09', 'G99.2'],
                ['M47.1', 'G99.2'], ['M47.10', 'G99.2'], ['M47.11', 'G99.2'], ['M47.12', 'G99.2'], ['M47.13', 'G99.2'], ['M47.14', 'G99.2'], ['M47.15', 'G99.2'], ['M47.16', 'G99.2'], ['M47.17', 'G99.2'], ['M47.18', 'G99.2'], ['M47.19', 'G99.2'],
                ['M47.2', 'G55.2'], ['M47.20', 'G55.2'], ['M47.21', 'G55.2'], ['M47.22', 'G55.2'], ['M47.23', 'G55.2'], ['M47.24', 'G55.2'], ['M47.25', 'G55.2'], ['M47.26', 'G55.2'], ['M47.27', 'G55.2'], ['M47.28', 'G55.2'], ['M47.29', 'G55.2'],
                ['M47.9', 'G99.2'], ['M47.90', 'G99.2'], ['M47.91', 'G99.2'], ['M47.92', 'G99.2'], ['M47.93', 'G99.2'], ['M47.94', 'G99.2'], ['M47.95', 'G99.2'], ['M47.96', 'G99.2'], ['M47.97', 'G99.2'], ['M47.98', 'G99.2'], ['M47.99', 'G99.2'],
                ['M47.9', 'G55.2'], ['M47.90', 'G55.2'], ['M47.91', 'G55.2'], ['M47.92', 'G55.2'], ['M47.93', 'G55.2'], ['M47.94', 'G55.2'], ['M47.95', 'G55.2'], ['M47.96', 'G55.2'], ['M47.97', 'G55.2'], ['M47.98', 'G55.2'], ['M47.99', 'G55.2'],
                ['M48.0', 'G55.3'], ['M48.00', 'G55.3'], ['M48.01', 'G55.3'], ['M48.02', 'G55.3'], ['M48.03', 'G55.3'], ['M48.04', 'G55.3'], ['M48.05', 'G55.3'], ['M48.06', 'G55.3'], ['M48.07', 'G55.3'], ['M48.08', 'G55.3'], ['M48.09', 'G55.3'],
                ['M50.0', 'G99.2'], ['M50.1', 'G55.1'], ['M51.0', 'G99.2'], ['M51.1', 'G55.1'],

                ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'EN3',
              bezeichnung: 'Periphere Nervenläsionen/ Muskelerkrankungen',
              leitsymptomatik: {
                a: 'Schädigung der Bewegungsfunktionen\n- Funktion der Muskelkraft, -tonus (z.B. Para-, Tetraparese, Muskelhypotonie, Muskelatrophie)\n- Kontrolle von Willkürbewegungen (z.B. Grobund Feinmotorik)',
                b: 'Schädigung der Sinnesfunktionen\n- Sensibilität (z.B. Temperatur, Druck)',
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung',
                  'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
                  'Sensomotorisch-perzeptive Behandlung',
                  'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene'
                ]},
                gruppe: {'heilmittel': [
                  'Motorisch-funktionelle Behandlung-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 20,
              langfristiger_heilmittelbedarf: [
                'G60.0', 'G60.8', 'G70.2', 'G71.1', 'G71.2', 'G71.3', 'G73.6', 'G14', 'G61.0', 'G61.8',
              ],
              besonderer_verordnungsbedarf: [
                'S14.3', 'S14.4', ['Z99.0', 'Z43.0'], ['Z99.1', 'Z43.0']
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'PS1',
              bezeichnung: 'Entwicklungs-, Verhaltens- und emotionale Störungen mit Beginn in Kindheit und Jugend',
              leitsymptomatik: {
                a: 'Schädigung der globalen mentalen Funktionen\nz.B.\n- Psychosoziale Funktionen\n- Temperament und Persönlichkeit\n- Antrieb',
                b: 'Schädigung der spezifischen mentalen Funktionen\nz.B.\n- Aufmerksamkeit, Gedächtnis\n- Psychomotorik, Verhalten\n- emotionale Funktionen, Selbstwahrnehmung\n- Denken, höhere kognitive Funktionen',
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung',
                  'Hirnleistungstraining/ neuropsychologisch orientierte Behandlung',
                  'Sensomotorisch-perzeptive Behandlung'
                ]},
                gruppe: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung-Gruppe',
                  'Hirnleistungstraining-Gruppe',
                  'Sensomotorisch-perzeptive Behandlung-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [
                'F84.0', 'F84.1', 'F84.2', 'F84.3', 'F84.4', 'F84.5', 'F84.8',
                'Q99.2',
                'G73.6', 'G73.6*', // nicht mehr drin seit 01.01.2023
                'Q93.5'
              ],
              besonderer_verordnungsbedarf: []
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'PS2',
              bezeichnung: 'Neurotische, Belastungs-, somatoforme und Persönlichkeits-störungen',
              leitsymptomatik: {
                a: 'Schädigung der globalen mentalen Funktionen\nz.B.\n- Psychische Stabilität, Selbstvertrauen, Impulskontrolle\n- Temperament und Persönlichkeit',
                b: 'Schädigung der spezifischen mentalen Funktionen\nz.B.\n- emotionale Funktionen\n- Selbstwahrnehmung\n- Körperschema',
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung'
                ]},
                gruppe: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 20,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [
                'Q99.2'
              ],
              besonderer_verordnungsbedarf: [
                'F41.0', 'F41.1', 'F41.2', 'F41.3', 'F41.8', 'F41.9',
                'F45.40', 'F45.41',
                'G54.6',
                'R52.1', 'R52.2',
                'U09.9',
                ['Z99.0', 'Z43.0'], 'Z99.1' // nicht mehr drin seit 01.01.2023
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'PS3',
              bezeichnung: 'Wahnhafte und affektive Störungen/ Abhängigkeitserkrankungen\nSchizophrenie, schizotype und wahnhafte Störungen\nAffektive Störungen\nPsychische und Verhaltensstörungen durch psychotrope Substanzen',
              leitsymptomatik: {
                a: 'Schädigung der globalen mentalen Funktionen\nz.B.\n- Qualität des Bewusstseins\n- Psychosoziale Funktionen\n- Antrieb\n- Temperament und Persönlichkeit',
                b: 'Schädigung der spezifischen mentalen Funktionen\nz.B.\n- Aufmerksamkeit, Gedächtnis\n- Psychomotorik, Verhalten\n- emotionale Funktionen, Selbstwahrnehmung\n- höhere kognitive Funktionen',
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung',
                  'Hirnleistungstraining/ neuropsychologisch orientierte Behandlung'
                ]},
                gruppe: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung-Gruppe',
                  'Hirnleistungstraining-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 20,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [],
              besonderer_verordnungsbedarf: [
                'U09.9'
              ]
            },
            {
              therapie: 'ergotherapie',
              indikationsschluessel: 'PS4',
              bezeichnung: 'Dementielle Syndrome',
              leitsymptomatik: {
                a: 'Schädigung der globalen mentalen Funktionen\nz.B.\n- Orientierung\n- Antrieb',
                b: 'Schädigung der spezifischen mentalen Funktionen\nz.B.\n- Aufmerksamkeit, Gedächtnis\n- Schlaf\n- Psychomotorik, Verhalten\n- emotionale Funktionen\n- höhere kognitive Funktionen',
              },
              heilmittel: {
                einzel: {'heilmittel': [
                  'Hirnleistungstraining/ neuropsychologisch orientierte Behandlung',
                  'Psychisch-funktionelle Behandlung',
                ]},
                gruppe: {'heilmittel': [
                  'Psychisch-funktionelle Behandlung-Gruppe',
                  'Hirnleistungstraining-Gruppe'
                ]},
              },
              maximale_verordnungsmenge: 10,
              minimale_frequenz: 1,
              maximale_frequenz: 3,
              orientierende_behandlungsmenge: 40,
              langfristiger_heilmittelbedarf: [

              ],
              besonderer_verordnungsbedarf: [
                'F00.0', 'F00.1', 'F00.2', 'F01.0', 'F01.1', 'F01.2', 'F01.3', 'F01.8', 'F02.3', 'F02.8', 'F03'
              ]
            },

      ];
    // icd10codes() {
    //   return [
    //     { icd10code: 'P92.-', indikationsschluessel: ['SC1', 'SC2'] },
    //     { icd10code: 'P98.2', indikationsschluessel: ['SC1'] },
    //     { icd10code: 'F80.-', indikationsschluessel: ['SP1', 'SP3'] },
    //     { icd10code: 'F80.0', indikationsschluessel: ['SP1', 'SP3'] },
    //     { icd10code: 'F80.1', indikationsschluessel: ['SP1'] },
    //     { icd10code: 'F80.2', indikationsschluessel: ['SP1'] },
    //     { icd10code: 'F80.3', indikationsschluessel: ['SP1'] },
    //     { icd10code: 'F80.9', indikationsschluessel: ['SP1'] },
    //     { icd10code: 'F82.2', indikationsschluessel: ['SP3'] },
    //     { icd10code: 'F83.-', indikationsschluessel: ['SP1'] },
    //     { icd10code: 'F83.-', indikationsschluessel: ['SP1'] },
    //   ]
    },
    verfuegbare_heilmittel() {
      return [
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Stimmtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Stimmtherapie-Gruppe',
          dauer: [45, 90],
          typ: 'gruppe'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprech- und Sprachtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprech- und Sprachtherapie-Gruppe',
          dauer: [45, 90],
          typ: 'gruppe'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprachtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprachtherapie-Gruppe',
          dauer: [45, 90],
          typ: 'gruppe'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Stimm-, Sprech- und Sprachtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Stimm-, Sprech- und Sprachtherapie-Gruppe',
          dauer: [45, 90],
          typ: 'gruppe'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprechtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprechtherapie-Gruppe',
          dauer: [45, 90],
          typ: 'gruppe'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprech- und Stimmtherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Schlucktherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },
        {
          fachbereich: 0,
          therapie: 'logopaedie',
          name: 'Sprech- und Sprachtherapie oder Schlucktherapie',
          dauer: [30, 45, 60],
          typ: 'einzel'
        },

        /**
         * Ab hier Ergo
         */

         {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Motorisch-funktionelle Behandlung',
          dauer: [30],
          typ: 'einzel',
          ergaenzendeHeilmittel: [
            'Thermische Anwendungen'
          ]
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Motorisch-funktionelle Behandlung mit ergotherapeutischer Schiene',
          dauer: [30],
          typ: 'einzel',
          ergaenzendeHeilmittel: [
            'Thermische Anwendungen'
          ]
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Sensomotorisch-perzeptive Behandlung',
          dauer: [45],
          typ: 'einzel',
          ergaenzendeHeilmittel: [
            'Thermische Anwendungen'
          ]
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Sensomotorisch-perzeptive Behandlung mit ergotherapeutischer Schiene',
          dauer: [45],
          typ: 'einzel',
          ergaenzendeHeilmittel: [
            'Thermische Anwendungen'
          ]
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Hirnleistungstraining/ neuropsychologisch orientierte Behandlung',
          dauer: [30],
          typ: 'einzel'
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Psychisch-funktionelle Behandlung',
          dauer: [60],
          typ: 'einzel'
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Motorisch-funktionelle Behandlung-Gruppe',
          dauer: [30],
          typ: 'gruppe'
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Sensomotorisch-perzeptive Behandlung-Gruppe',
          dauer: [45],
          typ: 'gruppe'
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Hirnleistungstraining-Gruppe',
          dauer: [45],
          typ: 'gruppe'
        },
        {
          fachbereich: 1,
          therapie: 'ergotherapie',
          name: 'Psychisch-funktionelle Behandlung-Gruppe',
          dauer: [90],
          typ: 'gruppe'
        },

      ]
    },
    indikationsschluesselPrefills() {
      return this.heilmittelkatalog.map(el => {
        return el.indikationsschluessel
      });
    },
    indikationsschluesselPrefillsLogo() {
      return this.heilmittelkatalog.flatMap(el => {
        return (el.therapie == 'logopaedie') ? el.indikationsschluessel : [];
      });
    },
    indikationsschluesselPrefillsErgo() {
      return this.heilmittelkatalog.flatMap(el => {
        return (el.therapie == 'ergotherapie') ? el.indikationsschluessel : [];
      });
    }
  },
  methods: {
    katalog(model) {
      return this.heilmittelkatalog.find(el => el.indikationsschluessel == model.indikationsschluessel)
    },
    findHeilmittelByString(string) {
      return this.verfuegbare_heilmittel.find(hm => {
        return hm.name == string;
      })
    }
  }
}
