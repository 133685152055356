import { render, staticRenderFns } from "./KalenderSpalte.vue?vue&type=template&id=174095be"
import script from "./KalenderSpalte.vue?vue&type=script&lang=js"
export * from "./KalenderSpalte.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternKalenderSpaltentitel: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/Spaltentitel.vue').default,InternKalenderFeiertag: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/Feiertag.vue').default,InternKalenderAbwesendheiten: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/Abwesendheiten.vue').default,InternKalenderTermine: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/KalenderTermine.vue').default,InternKalenderArbeitsstunden: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/KalenderArbeitsstunden.vue').default,InternKalenderBoxen: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/KalenderBoxen.vue').default,InternKalenderSpalteTagesabschlussbutton: require('/builds/logo-yes/portal2022/frontend/components/intern/kalender/SpalteTagesabschlussbutton.vue').default,Errormessage: require('/builds/logo-yes/portal2022/frontend/components/Errormessage.vue').default})
