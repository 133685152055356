
import kontakte from '~/mixins/models/kontakte';
export default {
  mixins: [kontakte],
  props: ['mitarbeiter'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if (this.mitarbeiter.id) {
        this.$$update('mitarbeiter', this.mitarbeiter, 'Mitarbeiter aktualisiert').then(response => {
          this.$nuxt.$emit('update-mitarbeiter', response);
          this.$nuxt.$emit('update-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
      else {
        this.$$store('mitarbeiter', this.mitarbeiter, 'Neuer Mitarbeiter gespeichert').then(response => {
          this.$nuxt.$emit('store-mitarbeiter', response);
          this.$nuxt.$emit('store-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
    },
  },
  mounted() {},

}
