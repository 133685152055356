
export default {
  mixins: [],
  props: ['fehlend', 'form', 'value'],
  data() {
    return {
      feldname: 'leitsymptomatik',
      fehlendVal: this.fehlend,
      touched: false,
      leitsymptomatikVal: null,
    }
  },
  computed: {
    leer() {
      if (!this.leitsymptomatikVal) return true;
      if (!this.leitsymptomatikVal.a && !this.leitsymptomatikVal.b && !this.leitsymptomatikVal.c && !this.leitsymptomatikVal.individuell) return true;
      return false;
    }
  },
  watch: {
    fehlend(neu) {
      this.fehlendVal = neu;
    }
  },
  methods: {
    fehlt() {
      this.touched = true;
      this.$emit('update:fehlend', this.fehlendVal);
      this.reset();
      this.$emit('input', this.leitsymptomatikVal);
    },
    reset() {
      this.leitsymptomatikVal = {a: false, b: false, c: false, individuell: false};
    },
    emit() {
      this.$emit('input', this.leitsymptomatikVal);
      this.touched = true;
    },
    touch() {
      this.touched = true;
    }
  },
  mounted() {
    if (this.value) this.leitsymptomatikVal = this.value;
    if (!this.leitsymptomatikVal) this.reset();
    if (this.form) {
      this.$nuxt.$on('formsubmit-'+this.form, this.touch);
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('formsubmit-'+this.form, this.touch);
  }
}
