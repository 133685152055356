import { render, staticRenderFns } from "./VerordnungStatus.vue?vue&type=template&id=40533c38"
import script from "./VerordnungStatus.vue?vue&type=script&lang=js"
export * from "./VerordnungStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayDebugId: require('/builds/logo-yes/portal2022/frontend/components/global/display/DebugId.vue').default,GlobalDisplayFachbereichBadge: require('/builds/logo-yes/portal2022/frontend/components/global/display/FachbereichBadge.vue').default,ModalsDetailsVerordnungTherapiestundenInformation: require('/builds/logo-yes/portal2022/frontend/components/modals/details/VerordnungTherapiestundenInformation.vue').default,InternVerordnungenButtons: require('/builds/logo-yes/portal2022/frontend/components/intern/verordnungen/Buttons.vue').default})
