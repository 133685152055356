
import kontakte from '~/mixins/models/kontakte';
export default {
  mixins: [kontakte],
  props: ['value', 'default', 'disabled', 'required'],
  data() {
    return {
      hinzugefuegt: [],
      emptyKontakt: {
          id: '',
          firma: '', nachname: '', vorname: '', spitzname: '', kuerzel: '', geburtsdatum: '', strasse: null, plz: null, ort: null,
          email: null, geschlecht: null, telefonnummern: [],
      },
    }
  },
  computed: {
    // Prüft ob alle Felder leer sind oder etwas signifikantes gefüllt ist
    modelEmpty() {
      let empty = true;
      _.forEach(this.emptyKontakt, (el, key) => {
        if (key == 'id') return;
        else if (this.value[key] != null && this.value[key] != '' && this.value[key] != [] && this.value[key] != 0) {
          empty = false;
        }
      });
      this.$emit('empty', empty);
      return empty;
    }
  },
  watch: {
    value(neu, alt) {
      this.start(neu);
    }
  },
  methods: {
    start(value) {
      let neu = this.$fillMissing(value, this.emptyKontakt);
      if (value && value.telefonnummern) {}
      else if (this.display('telefon')) this.addTelefon(neu);
      this.$emit('input', neu);

      if (value) {
        if (value.telefonnummern && value.telefonnummern.length) this.hinzugefuegt.push('telefon');
        if (value.firma) this.hinzugefuegt.push('firma');
        if (value.vorname || value.nachname) this.hinzugefuegt.push('name');
        if (value.geburtsdatum) this.hinzugefuegt.push('geburtsdatum');
        if (value.email) this.hinzugefuegt.push('email');
        if (value.kuerzel) this.hinzugefuegt.push('kuerzel');
        if (value.spitzname) this.hinzugefuegt.push('spitzname');
        if (value.geschlecht) this.hinzugefuegt.push('geschlecht');
        if (value.strasse || value.plz || value.ort) this.hinzugefuegt.push('adresse');
      }
    },
    display(element) {
      if (!this.default) return true;
      if (this.hinzugefuegt.includes(element)) return true;
      return this.default.includes(element);
    },
    hinzufuegenErlaubt(element) {
      if (this.display(element)) return false;
      if (!this.disabled) return true;
      return !this.disabled.includes(element);
    },
    hinzufuegen(element) {
      this.hinzugefuegt.push(element);
    },
    pflichtfeld(element) {
      if (!this.required) return null;
      if (this.required.includes(element)) return 'required';
      return null;
    }
  },
  mounted() {
    this.start(this.value)
  },

}
