
export default {
  mixins: [],
  props: ['model_id', 'spalteAbgerufen', 'datum'],
  data() {
    return {
      tagesabschluss: false,
      datumAbgerufen: null,
    }
  },
  computed: {
    zukunft() {
      return this.datum > this.$moment().format('YYYY-MM-DD')
    }
  },
  watch: {
    spalteAbgerufen(neu) {
      this.abrufen();
    },
    datum(neu, alt) {
      this.abrufen();
    }
  },
  methods: {
    abrufen() {
      if (!this.spalteAbgerufen) return;
      if (this.datumAbgerufen == this.datum) return;
      this.datumAbgerufen = this.datum;
      if (this.zukunft) return;
      this.$$find('tagesabschluesse', this.datum+'/'+this.model_id).then(response => this.tagesabschluss = true, error => this.tagesabschluss = false);
    }
  },
  mounted() {
    this.abrufen();
  },

}
