
import kontakte from '~/mixins/models/kontakte';
export default {
  mixins: [kontakte],
  props: ['krankenkasse'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if (this.krankenkasse.id) {
        this.$$update('krankenkassen', this.krankenkasse, 'Krankenkasse aktualisiert').then(response => {
          this.$nuxt.$emit('update-krankenkasse', response);
          this.$nuxt.$emit('update-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
      else {
        this.$$store('krankenkassen', this.krankenkasse, 'Neue Krankenkasse gespeichert').then(response => {
          this.$nuxt.$emit('store-krankenkasse', response);
          this.$nuxt.$emit('store-kontakt', response.kontakt);
          this.$emit('done');
        });
      }
    },
  },
  mounted() {},

}
