
export default {
  mixins: [],
  props: ['minutenzahl'],
  data() {
    return {}
  },
  computed: {
    stundenMinuten() {
      let stunden = 0;
      let minuten = 0;
      stunden = Math.floor(this.minutenzahl / 60);
      minuten = this.minutenzahl - (stunden*60)
      return this.$pad(stunden)+':'+this.$pad(minuten);
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
