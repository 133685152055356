import { render, staticRenderFns } from "./Kommentare.vue?vue&type=template&id=7dad78c7"
import script from "./Kommentare.vue?vue&type=script&lang=js"
export * from "./Kommentare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalKommentarfunktion: require('/builds/logo-yes/portal2022/frontend/components/global/kommentarfunktion/index.vue').default})
