export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    kalenderHoeheSetzen() {
      this.$nextTick(() => {
        if (!this.$refs.kalenderBody) return 0;
        // 50 Abzug für die Titelzeile
        let hoehe = this.$refs.kalenderBody.clientHeight - 50;
        // 34 Abzug für die Fußzeile "Zum Tagesabschluss"
        if (this.kalender.display.tagesabschluss) hoehe = hoehe - 34
        this.kalender.meta.hoehe = hoehe;
      })
    },
  },
  mounted() {
    this.kalenderHoeheSetzen();
  },

}
